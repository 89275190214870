<template>
	<modal class="openingModel" :isShow="openisShow" @keyup.esc="CloseClicks">
        <div class="header">
            <span>{{opentype==1?'开台操作':'修改抬头' }}</span>
        </div>
        <!--左边基本信息div-->
        <div class="Popup-Basic">
			<div class="tr-li" style="height:60px;">
				<div class="tdTile">
					<i class="iconfont icon-zhuotai"></i>
					<div class="lable-txt">桌号</div>
				</div>
				<div class="Title-5">
					<div class="lable-txt" :title="openDeskName">
						{{openDeskName}}
					</div>
					<div class="union-box" v-if="Eat_UnionDeskList?.length>0">
						<span class="red">共{{Eat_UnionDeskList.length+1}}桌</span>（<span class="name nowrap">{{Eat_UnionDeskList.map(it=>it.Desk_Name).join(",")}}</span>）
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">人数:</div>
					<div class="from-input">
						<input type="text" placeholder="请输入就餐人数" ref="input"  class="key-focus" data-model="DinersNum" v-model="DinersNum" oninput="value=value.replace(/[^\d]/g,'')" @keyup.enter="inputCursor(1)"  @click="selectValue($event,DinersNum)"/>
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">席数:</div>
					<div class="from-input" v-show="RunFeastDeskNum" >
						<input type="text" ref="Seats" placeholder="请输入席数" class="key-focus" data-model="SeatNumber" v-model="SeatNumber" oninput="value=value.replace(/[^\d]/g,'')" @keyup.enter="inputCursor(2)" @click="selectValue($event,SeatNumber)" />
					</div>
					<div class="from-input" v-show="!RunFeastDeskNum">
						<input type="text" placeholder="请输入席数" :readonly="!RunFeastDeskNum" data-model="SeatNumber" v-model="SeatNumber"/>
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">服务员:</div>
					<div class="from-input" @click="waiSaleClick(1)">
						<input type="text" placeholder="请选择服务员" v-model="Waiter" readonly />
						<i class="iconfont icon-kehu"></i>
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">销售员:</div>
					<div class="from-input">
						<input type="text" placeholder="请选择销售员" v-model="SalesValue" maxlength="15" />
						<i class="iconfont icon-kehu" @click="waiSaleClick(2)"></i>
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-5">
					<div class="lable-txt">订单渠道:</div>
					<div class="from-input" @click="orderChannelClick()">
						<input type="text" placeholder="请选择" v-model="orderChannelName" readonly />
						<i class="iconfont icon-yewuliucheng"></i>
					</div>
				</div>
				<div class="td-5">
					<div class="lable-txt">消费类型:</div>
					<div class="from-input" @click="consumeTypeClick()">
						<input type="text" placeholder="请选择" v-model="consumeAutoIDName" readonly />
						<i class="iconfont icon-gengduo"></i>
					</div>
				</div>
			</div>
			<div class="tr-li">
				<div class="td-6">
					<div class="lable-txt">订单备注:</div>
					<div class="from-input">
						<input type="text" v-board ref="orderNote" class="key-focus" v-model="orderRemark" @keyup.enter="inputCursor(3)" />
					</div>
				</div>
			</div>
			<div class="tr-li" style="width:96%;" v-show="cardShowType==true">
				<el-divider >
					会员信息
					<i class="el-icon-arrow-up" @click="cardShowType=!cardShowType"></i>
				</el-divider>
			</div>
			<div class="tr-li" style="width:96%;" v-show="cardShowType==false">
				<el-divider >
					会员信息
					<i class="el-icon-arrow-down" @click="cardShowType=!cardShowType"></i>
				</el-divider>
			</div>
			<transition name="cardShow">
				<div v-show="cardShowType" style="position:absolute;">
				<div class="tr-li">
					<div class="td-7">
						<div class="lable-txt">卡号:</div>
						<div class="from-input">
							<template v-if="disabledInput">
								<input-pattern type="text" ref="cardfocus" 
									placeholder="请输入会员卡号" v-model="CardID"
									readonly isDisableBarcode
									@click="selectValue($event,CardID)"  @barcode="onMemberInfo(true)" />
							</template>
							<template v-else>
								<input type="text" ref="cardfocus" class="key-focus" placeholder="请输入会员卡号" v-model="CardID"
								@click="selectValue($event,CardID)"  @keydown.enter="onMemberInfo(true)" />
							</template>
							<i class="iconfont icon-sousuo" @click="onMemberInfo(true)"></i>
						</div>
					</div>
					<div class="td-5">
						<button class="btn"  @click="readCard()">读卡</button>
					</div>
				</div>
				<div class="tr-li">
					<div class="td-card">
						<div class="lable-txt">会员等级:</div>
						<div class="from-input">
							<input type="text" placeholder="" v-model="CardLevel" readonly  />
						</div>
					</div>
					<div class="td-card">
						<div class="lable-txt" style="width:68px;">会员姓名:</div>
						<div class="from-input">
							<input type="text" placeholder="" v-model="ClientName" readonly style="width: 162px;" />
						</div>
					</div>
				</div>
				<div class="tr-li">
					<div class="td-card">
						<div class="lable-txt">手机号码:</div>
						<div class="from-input">
							<input type="text"  :value="cardPhoneSecret" readonly />
						</div>
					</div>
					<div class="td-5">
						<div class="lable-txt" style="width:72px;">生日:</div>
						<el-date-picker class="from-date"
							:clearable="false"
							:editable="false"
							type="date"
							placeholder=""
							readonly 
							v-model="birthday"
							:default-value="new Date()">
						</el-date-picker>
					</div>
				</div>
				<div class="tr-li">
					<div class="td-6">
						<div class="lable-txt">客户喜好:</div>
						<div class="from-input">
							<input type="text"  v-model="ClientLike" readonly />
						</div>
					</div>
				</div>
				</div>
			</transition>
        </div>
        <!--右边数字键盘div-->
        <div class="Popup-Basic-right">
			<div class="keyboard">
				<div class="key-li">
					<span data-value="7">7</span><span data-value="8">8</span><span data-value="9">9</span>
				</div>
				<div class="key-li">
					<span data-value="4">4</span><span data-value="5">5</span><span data-value="6">6</span>
				</div>
				<div class="key-li">
					<span data-value="1">1</span><span data-value="2">2</span><span data-value="3">3</span>
				</div>
				<div class="key-li">
					<span data-value=".">.</span><span data-value="0">0</span><span data-value="back"><i class="iconfont icon-zuojiantou"></i></span>
				</div>
			</div>
			<div class="other-box" v-show="opentype==2">
				<el-switch v-model="isCursorCodeNo" :inline-prompt="true" active-text="默认定位光标在卡号" inactive-text=""></el-switch>
			</div>
        </div>
        <div class="Popup-Bsic-bottom">
            <div class="flex-box"><button class="Popup-Basic-left-bottom bottom" @click="CloseClicks()">取消</button></div>
			<div class="flex-box" v-if="opentype==1 && !isTransFoodOpen && moreOpenDesk"><button class="multiBill-bottom bottom" @click="multeBillClick()">多台开单</button></div>
            <div class="flex-box" v-if="opentype==1 && $cacheData?.globalVariable?.GlobalSysSetting?.Running_BestMenu_YN&&!isTransFoodOpen"><button class="menuOrder-bottom bottom" @click="SetDeskClick(true)" ><i class="iconfont icon-caipinxiaolei"></i>菜单点餐</button></div>
            <div class="flex-box"><button class="Popup-Basic-right-bottom bottom" @click="SetDeskClick()">确定</button></div>
        </div>
		<!--选择服务员或销售员-->
		<modal-load :isShow="waiSaleShow">
			<waiter-Sales-Model :isShow="waiSaleShow" v-on:closeModel="waiSaleShow=false" :WaiterList="WaiterList" :SalesList="SalesList" :waiSaleType="waiSaleType" @waiSaleConfirm="waiSaleConfirm" ></waiter-Sales-Model>
		</modal-load>
		<!--订单渠道-->
		<modal-load :isShow="orderChanneShow">
			<order-Channel-Model :isShow="orderChanneShow" v-on:closeModel="orderChanneShow=false" :Openorderclass="orderclass" :OrderList="OrderList" :orderChannel="orderChannel" @orderChanneConfirm="orderChanneConfirm" ></order-Channel-Model>
		</modal-load>
		<!--消费渠道-->
		<modal-load :isShow="consumeTypeShow">
			<consume-Types-Model :isShow="consumeTypeShow" v-on:closeModel="consumeTypeShow=false" :consumeTypes="consumeTypes" :ConsumptionList="ConsumptionList" @consumeTypeConfirm="consumeTypeConfirm"></consume-Types-Model>
		</modal-load>
		<modal-load :isShow="cardMemberShow">
			<card-member-Model :isShow="cardMemberShow" v-on:closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo" ></card-member-Model>
		</modal-load>
		<!-- 多台开单弹层 -->
		<modal-load :isShow="multeipleBillShow" isUnload>
			<multiple-billing-model :isShow="multeipleBillShow" :data="{openDesk,Eat_UnionDeskList}" 
				@close="multeipleBillShow=false"
				@confirm="multeipleDeskConfirm"/>
		</modal-load>
    </modal>
</template>

<script>
import waiterSalesModel from './waiterSalesModel/waiterSalesModel.vue'
import orderChannelModel from './orderChannelModel/orderChannelModel.vue'
import consumeTypesModel from './consumeTypesModel/consumeTypesModel.vue'
import {initKeyBoard } from "/src/common";
import modal from "../../../template/modal/modal";
import { cacheData } from '/src/services';
import { newGuid } from '/src/common/index.js'
import { ElMessage } from "element-plus"
import CardMemberModel from './cardMemberModel/cardMemberModel.vue';
import multipleBillingModel from './multipleBillingModel/multipleBillingModel.vue'

/**全局初始化数据 */
let _data={
    OpenDeskPersonNum:0, /**控制开台输入人数上限(人数为0不限制) */
    RunFeastDeskNum:false, /**启用席数功能 */
    RunConsumeType:false,/**启用账单登记消费类型功能 */
};

export default {
    name: 'opening',
    components:{
        modal,
        waiterSalesModel,
        orderChannelModel,
        consumeTypesModel,
        CardMemberModel,
		multipleBillingModel
    },
    props:{
		//桌台信息
		posDeskInfo:{
			type:Object,
			default:null
		},
		openDeskName:String,
		openDeskCode:String,
		openisShow:Boolean,
		openDeskAuto:String,
		orderInfo:Object,
		opentype:Number,
		orderType:Number,
		checkedOut:Boolean,
		orderMenuList:Array,
		Reserve_Operator:String,
		isTransFoodOpen:{
			typeof:Boolean,
			default:false
		}
    },
	computed:{
		//主桌台信息
		openDesk(){
			return {Desk_AutoID:this.openDeskAuto,Desk_Code:this.openDeskCode,Desk_Name:this.openDeskName}
		},
		//手机号 保密处理
		cardPhoneSecret() {
			return this.$global.phonePrivacy(this.CardPhone);
		},
		//系统参数
		sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
		//修改抬头时 禁止手动输入卡号
		disabledInput(){
			return this.opentype!=1 && this.sysSetting?.RunModifyOrderMemberForbidInputMobile;
		}
	},
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
          _data.OpenDeskPersonNum = globalSysSetting.TheLimitedOpenDeskPersonNum;
          _data.RunFeastDeskNum = globalSysSetting.RunFeastDeskNum;
          _data.RunConsumeType = globalSysSetting.RunConsumeType;
        }
        return {
            waiSaleShow:false,
            orderChanneShow:false,
            consumeTypeShow:false,
            cardMemberShow:false,
            consumeTypes:'',
            card_AutoID:'',
            cardSNR:'',//内卡号
            DeskName:'',  //桌台名
            DeskCode:'', //桌台Code
            DinersNum:'', //就餐人数
            SeatNumber:1, //席数
            CardID:'',//会员卡号
            ClientName:'',//客户名称
            CardLevel:'',//会员等级
            ClientLike:'', //客户喜好
            gender:'',
            CardPhone:'', //手机号
            orderRemark:'',//订单备注
            OrderList: [],
            orderChannel:'',//订单渠道,默认是0线下门店固定POS
            orderChannelName:'',
            /**销售员 */
            SalesList:[],
            SalesValue:'',//销售员
            ConsumptionList:[],
            ConsumptionType:'0', //消费类型
            /**服务员 */
            WaiterList:[],
            Waiter:'',//服务员
            birthday:'',//生日
            waiSaleType:0, //1.选择的服务员 2.选择的销售员
            orderChannelAutoID:-1, //订单类型选择
            orderclass:'', //订单选择
            consumeTypeAutoid:-1, //消费类型选择
            consumeAutoID:'', //消费选择
            consumeAutoIDName:'',
            consuShow:false,
            isread:false, //是否是读卡
            iscardMemberShow:false, //是否是多张卡选择返回
            cardMemberList:[], //会员卡信息
            cardShowType:true, //读卡展示
            currentTargetTxt:'' ,//选中的值
            memberType:0,//是否触发获取会员信息按钮
            RunFeastDeskNum:false,/**启用席数功能 */
            terminationID:'',
            serverID:'',
			//多台开单弹层
			multeipleBillShow:false,
			//多台开单 数据
			Eat_UnionDeskList:[],
			//多台开单 菜单
			moreOpenDesk:null,
			//验证 后的 用户信息
			tempCheckUser:null,
			//默认关闭在卡号上
			isCursorCodeNo:this.$cacheData.get("opening_isCursorCodeNo")||false,
        }
    },
    mounted(){
		this.$nextTick(() => {
			this.defaultFocus();
			initKeyBoard.call(this, this.$el);
			initKeyBoard.call(this,this.$refs.zf);
			this.EmptyDatas();
		});

		let SysProjects=this.$cacheData.SysProjects.get();
		SysProjects?.find(it=>it.Project_Key=="Config")
		.SysProjectGroups?.find(it=>it.Group_Key=="BaseSet_CloudPos")
		.SysProjectItems?.find(it=>it.ProjectItem_Key=="BusinessSet")
		.SysProjectSubItems?.forEach(it=>{
			if(it.ProjectSubItem_Key=="MoreOpenDesk"){
				this.moreOpenDesk=it;
			}
		})

		console.log(SysProjects)
    },
    watch: {
		isCursorCodeNo(newVal){
			this.$cacheData.set("opening_isCursorCodeNo",newVal);
		},
        //用于观察数据变动。对应一个对象,键是观察表达式,值是对应回调。子组件中 需要结合watch监听
        openisShow() { 
            if(this.openisShow==true){
				this.EmptyDatas();
				this.defaultFocus();
            }
        },
        DinersNum(){
			if(_data.OpenDeskPersonNum>0){
				if(Number(this.DinersNum)>_data.OpenDeskPersonNum){
					this.$message.warning('超过就餐人数的最大限制')
					this.DinersNum='',this.currentTargetTxt=''
					return
				}
			}
			let TargetTxt =this.currentTargetTxt
			if(TargetTxt.length>=1){
				TargetTxt=TargetTxt.slice(0,TargetTxt.length-1)
			}
			if(Number(this.DinersNum)!= Number(TargetTxt) && this.currentTargetTxt!=''){
				this.DinersNum= this.DinersNum.substring(this.DinersNum.length-1)
			}
			if(Number(this.DinersNum)== Number(TargetTxt) && this.currentTargetTxt!=''){
				this.DinersNum=''
			}
           this.currentTargetTxt=''
        },
        SeatNumber(){
			if(Number(this.SeatNumber)>100000000){
				this.$message.warning('超过席数的最大限制')
				this.SeatNumber='',this.currentTargetTxt=''
				return
			}
			let TargetTxt =this.currentTargetTxt
			if(TargetTxt.length>=1){
				TargetTxt=TargetTxt.slice(0,TargetTxt.length-1)
			}
			if(Number(this.SeatNumber)!=Number(TargetTxt) && this.currentTargetTxt!=''){
				this.SeatNumber=this.SeatNumber.substring(this.SeatNumber.length-1)
			}
			if(Number(this.SeatNumber)==Number(TargetTxt) && this.currentTargetTxt!=''){
				this.SeatNumber=''
			}
			this.currentTargetTxt=''
        },
        CardID(){
			if(this.iscardMemberShow==false){
				this.memberType=0
			}
			let TargetTxt =this.currentTargetTxt
			if(TargetTxt.length>=1){
				TargetTxt=TargetTxt.slice(0,TargetTxt.length-1)
			}
			if(this.CardID !=TargetTxt && this.currentTargetTxt!=''){
				this.CardID=this.CardID.substring(this.CardID.length-1)
			}
			if(this.CardID==TargetTxt && this.currentTargetTxt!=''){
				this.CardID=''
			}
			this.currentTargetTxt=''
			this.iscardMemberShow = false
        },
    },
    methods:{
		/**默认光标位置 */
		defaultFocus(){
			if(this.opentype==2 && this.isCursorCodeNo){
				setTimeout(() => {
					this.$refs.cardfocus.focus();
				},100);
			}else{
				setTimeout(() => {
					this.$refs.input.focus();
					this.$refs.input.select();
				},100);
			}
		},
		/**回车控制光标 */
		inputCursor(type){
			if(type==1){
				if(this.RunFeastDeskNum){
					this.$refs.Seats.focus();
				}else{
					this.$refs.orderNote.focus();
				}
			}else if(type==2){
				this.$refs.orderNote.focus();
			}else if(type==3){
				if(this.cardShowType){
					this.$refs.cardfocus.focus();
				}else{
					this.enterDesk();
				}
			}
		},
		/**默认input焦点 */
		EmptyDatas(){
			this.empty()
			if(this.consumeTypes!=''){
				this.consuShow==true
			}
			this.RunFeastDeskNum = _data.RunFeastDeskNum;
			this.SalesValue=this.Reserve_Operator;
			/**服务员*/
			this.WaiterList=[]
			this.SalesList=[]
			this.$cacheData.PosWaiters().then((d)=>{
				d?.map(item=>{
					this.WaiterList.push(item);
				})
			}).catch(()=>{
				this.$alert('暂无服务员', "提示", {confirmButtonText: "确定"});
			})
			/**销售员*/
			this.$cacheData.PosSellers().then((d)=>{
				d?.map(item=>{
					this.SalesList.push(item);
				})
			}).catch(()=>{
				this.$alert('暂无销售员', "提示", {confirmButtonText: "确定"});
			})
			let userInfo=this.$auth.getUserInfo(); //获取登录信息
			console.log('orderInfo:',this.orderInfo)
			/**订单渠道 */
			this.orderclass=''
			this.OrderList=[]
			if(this.orderInfo){
				this.orderclass = this.orderInfo.CHANNEL_ID
				this.consumeAutoID = this.orderInfo.Consume_TypeID
			}
			this.$cacheData.OrderChannel().then((d)=>{
				this.orderChannel = d;
				this.orderChannel?.map(order=>{
					order.OrderChannelList.map(item=>{
					if(this.orderclass==''){
						if(item.OrderChannel_AutoID==userInfo?.Default_ChannelID){
						this.orderChannelName = item.OrderChannel_Name
						this.orderclass = item.OrderChannel_AutoID
						}
					}else{
						if(item.OrderChannel_AutoID==this.orderclass){
						this.orderChannelName = item.OrderChannel_Name
						this.orderclass = item.OrderChannel_AutoID
						}
					}
					item = Object.assign(item, {is_type:0,Kind_AutoID:order.OrderChannelKind_AutoID})
					this.OrderList.push(item)
					})
				})
			}).catch(()=>{
				this.$alert('暂无订单渠道', "提示", {confirmButtonText: "确定"});
			})
			/**消费类型*/
			this.OrderList = this.unique(this.OrderList);
			this.ConsumptionList=[]
			this.$cacheData.ConsumeTypes().then((d)=>{
				this.consumeTypes=d;
				this.consumeTypes?.map(consu=>{
					consu.ConsumeTypeList.map(item=>{
						if(this.consumeAutoID==item.ConsumeType_AutoID){
						this.consumeAutoID = item.ConsumeType_AutoID
						this.consumeAutoIDName = item.ConsumeType_Name
						}
						item = Object.assign(item, {is_type:0,Kind_AutoID:consu.ConsumeTypeKind_AutoID})
						this.ConsumptionList.push(item)
					})
				})
			}).catch(()=>{
				this.$alert('暂无消费类型', "提示", {confirmButtonText: "确定"});
			})
			/**修改 */
			if(this.opentype==2){
				this.isread=true
				this.DinersNum = this.orderInfo.Eat_ManNum
				this.SeatNumber = Number(this.orderInfo.Feast_DeskNum)
				this.consumeAutoID = this.orderInfo.Consume_TypeID
				this.CardID = this.orderInfo.Member_CardNo
				this.Waiter = this.orderInfo.Eat_WaiterName
				this.SalesValue = this.orderInfo.Eat_Saler
				this.orderRemark = this.orderInfo.Order_Remark
				/**获取会员信息 */
				if(this.CardID){
					this.onMemberInfo()
				}
			}else{//开台
				if(this.posDeskInfo){
					this.DinersNum=this.posDeskInfo.Seat_Numbers;
				}
				
			}
		},
		/**获取会员信息
		 * @param isBo 是否未输入会员卡号时回测保存
		 */
		onMemberInfo(isBo){
			if(!this.CardID){
				if(isBo){
					this.enterDesk();
				}else{
					this.$message.warning('请先输入会员卡号');
				}
				return;
			}
			let userInfo=this.$auth.getUserInfo(); //获取用户id
			let param={
				Body_ID:newGuid(), //BodyID
				User_ID:userInfo?.User_ID,
				DynamicNo:this.CardID,
				Card_AutoID:this.card_AutoID,
				Card_SN:this.cardSNR,
				IsGetAnalysisData:true
			}
			//获取海鼎配置的终端
			this.$webBrowser.getEntityCards().then(d=>{
				if(d){
					this.terminationID=d.TerminationID;
					this.serverID=d.ServerID;
				}
				//this.terminationID='6121';
				//this.serverID='6121';
				if(this.terminationID && this.serverID){
					param = Object.assign(param,{HdExtendInfo:{terminalId:this.terminationID,serverId:this.serverID,media:''}})
				}
				const loading = this.$loading({
					text: "加载中...",
					spinner: "el-icon-loading",
					background: "rgba(0, 0, 0, 0.7)",
				});
				this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",param).then((data)=>{
					loading.close();
					if(data.ResponseHeader.ResultCode!=0){
						this.$message.error(data.ResponseHeader.ResultDesc);
					}else{
						this.memberType=1
						if(data.ResponseBody.length>1){
							this.cardMemberList = data.ResponseBody;
							this.cardMemberShow=true;
						}else{
							this.cardMemberList = data.ResponseBody;
							this.isread=true
							let meberInfo=data;
							this.birthday = meberInfo.ResponseBody[0]?.Birthday;
							this.ClientName = meberInfo.ResponseBody[0]?.MemberName;
							this.CardPhone = meberInfo.ResponseBody[0]?.Mobile;
							this.CardLevel = meberInfo.ResponseBody[0]?.Bestech_Card_KindName;
							this.ClientLike = meberInfo.ResponseBody[0].MemberAnalysisData?.Habits;
						}
					}
				}).catch((e)=>{
					loading.close();
					this.$alert(e.message, "提示", {confirmButtonText: "确定"});
				})
			})
		},
		/**滑出*/
		CardShowClick(){
			if(this.cardShowType==1){
				this.cardShowType=0
			}else{
				this.cardShowType=1
			}
		},
		/**选择所有 */
		selectValue(e,value){
			e.currentTarget.select();
			this.currentTargetTxt = value
			console.log('选中所有:'+this.currentTargetTxt)
		},
		//关闭弹出框
		CloseClicks(){
			this.$emit("CloseClick");
		},
		/**服务员或销售员点击 */
		waiSaleClick(type){
			this.waiSaleType = type
			this.waiSaleShow=true
		},
		/**服务员或销售返回 */
		waiSaleConfirm(seleUser){
			if(this.waiSaleType==1){
				this.Waiter = seleUser.User_Name
			}else{
				this.SalesValue = seleUser.User_Name
			}
			this.waiSaleShow=false
		},
		/**订单渠道点击 */
		orderChannelClick(){
			if(this.orderChanneShow==false){
				this.orderChanneShow =true
			}else{
				this.orderChanneShow =false
			}
		},
		/**订单渠道返回 */
		orderChanneConfirm(item){
			this.orderclass = item.OrderChannel_AutoID
			this.orderChannelName = item.OrderChannel_Name
			this.orderChanneShow =false
		},
		/**消费渠道点击 */
		consumeTypeClick(){
			if(this.consumeTypeShow==false){
				this.consumeTypeShow =true
			}else{
				this.consumeTypeShow =false
			}
		},
		/**消费渠道返回*/
		consumeTypeConfirm(item){
			this.consumeAutoID = item.ConsumeType_AutoID
			this.consumeAutoIDName = item.ConsumeType_Name
			this.consumeTypeShow =false
		},
		/**实体卡读卡 */
		readCard(){
			this.$webBrowser.redCardM1().then((d)=>{
				if(d){
					if(d.state==0){
						this.card_AutoID=d.data.cardID;
						this.CardID=d.data.cardNo;
						this.cardSNR=d.data.snr
						this.onMemberInfo()
					}else{
						this.$message.error('卡信息读取失败：'+d.message);
					}
				}
			})
		},
		/**
		 * 响应回测进行开台
		 */
		enterDesk(){
			if(this.opentype==1){//开台提示
				this.$confirm("确定"+this.openDeskName+"台号开台吗？", "提示", {
					confirmButtonText: "确定",
					cancelButtonText:"取消",
					callback: (name) => {
						if(name=='confirm'){
							this.SetDeskClick();
						}
					},
				});
			}else{
				this.SetDeskClick();
			}
		},
		//提交开台数据
		SetDeskClick(isMenuOrder){
			if(this.CardID!=''){
				if(this.memberType==0){
					this.$message.warning('未查询到该卡号的客户信息');
					return
				}
			}
			if(Number.isInteger(Number(this.DinersNum))==false){
				this.$message.warning('人数不能为小数');
				return
			} 
			if(Number.isInteger(Number(this.SeatNumber))==false){
				this.$message.warning('席数不能为小数');
				return
			}
			if(this.orderclass==''){
				this.$message.warning('订单渠道不能为空!');
				return
			}
			if(_data.RunConsumeType){
				if(this.consumeAutoID==''){
					this.$message.warning('消费类型不能为空!');
					return
				}
			}
			if(this.opentype==1){
				let userInfo=this.$auth.getUserInfo(); //获取用户id
				let param ={
					Body_ID:newGuid(), //BodyID
					User_ID:userInfo?.User_ID,
					Eat_DeskAutoID:this.openDeskAuto, //桌台id
					Operator_Name:userInfo?.Login_Name, //操作员
					Eat_PersonNum:Number(this.DinersNum), //就餐人数
					Eat_Waiter:this.Waiter, //服务员
					Consume_TypeID:this.consumeAutoID, //消费类型this.ConsumptionType
					Eat_Saler:this.SalesValue, //销售人员
					Feast_DeskNum: Number(this.SeatNumber), //席位数
					Member_CardNo:this.CardID, //会员卡号
					Member_Name:this.ClientName, //客户姓名
					Mobile:this.CardPhone, //手机号
					Member_CardKind:this.CardLevel, //会员等级
					Taste:this.ClientLike, //客户喜好
					Order_Type:0, //订单类型
					Order_Source:1, //下单方式
					Order_ChannelID:this.orderclass, //订单渠道
					Order_Remark:this.orderRemark, //订单备注
					OpenDeskKind:0 //0:普通开台 1:预定开台
				}

				if(this.tempCheckUser){
					param.User_ID=this.tempCheckUser.User_ID;
					param.Login_Name=this.tempCheckUser.Login_Name;
				}

				if(Number(this.DinersNum)==0){
					this.$confirm('就餐人数确认为0吗?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
						if(this.isTransFoodOpen){//如果是转菜开台，则直接返回
							this.$emit("TransFoodOpenDeskInfoReturn",param);
						} else{
							this.SetDeskMethod(param,isMenuOrder)
						}
					}).catch(() => {
						this.$message({type: 'info',message: '开台取消'});          
					});
				}else{
					if(this.isTransFoodOpen)//如果是转菜开台，则直接返回
					{
						this.$emit("TransFoodOpenDeskInfoReturn",param);
					}
					else{
						this.SetDeskMethod(param,isMenuOrder)
					}
				}
			}else{
				//开启了席数功能
				let isRunOrderNum=0
				this.orderMenuList?.forEach(order=>{
					if(order?.Eat_TcItemList){
						if(order?.Eat_TcItemList.length>0 && (order?.Food_Type==2 || order?.Food_Type==3)){
							isRunOrderNum +=order.Eat_Number
						}
					}
					if(order?.FoodSetmeals){
						if(order?.FoodSetmeals.length>0 && (order?.Food_Type==2 || order?.Food_Type==3)){
							isRunOrderNum +=order.Eat_Number
						}
					}
				})
				if(_data.RunFeastDeskNum==true && Number(isRunOrderNum)>0 && Number(this.SeatNumber)>=1){
					if(Number(isRunOrderNum)!= Number(this.SeatNumber)){
						this.$confirm('本桌台为'+Number(this.SeatNumber)+'席,但套餐份数为'+Number(isRunOrderNum)+'/份,请确认数量,是否继续修改抬头?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'})
						.then(() => {
							this.ModifyOrderMethod()
						}).catch(() => {});
					}else{
						this.ModifyOrderMethod()
					}   
				}else{
					this.ModifyOrderMethod()
				}
			}
		},
		ModifyOrderMethod(){
			let userInfo=this.$auth.getUserInfo(); //获取用户id
			let param ={
				Body_ID:newGuid(), //BodyID
				User_ID:userInfo?.User_ID,
				Operator_Name:userInfo?.Login_Name, //操作员
				Eat_AutoID:this.orderInfo.Eat_AutoID, //订单Id
				Eat_PersonNum:Number(this.DinersNum), //就餐人数
				Eat_Waiter:this.Waiter, //服务员
				Eat_Saler:this.SalesValue, //销售人员
				Feast_DeskNum: Number(this.SeatNumber), //席位数
				Member_CardNo:this.CardID, //会员卡号
				Member_Name:this.ClientName, //客户姓名
				Mobile:this.CardPhone, //手机号
				Member_CardKind:this.CardLevel, //会员等级
				Taste:this.ClientLike, //客户喜好
				Consume_TypeID:this.consumeAutoID, //消费类型this.ConsumptionType
				CHANNEL_ID:this.orderclass, //订单渠道
				Timestamps:this.orderInfo.Timestamps,
				Order_Remark:this.orderRemark, //订单备注
				Is_CheckedOut:this.checkedOut //是否已经结账
			}
			if(sessionStorage.getItem("tempUserID")){
				param.User_ID = sessionStorage.getItem("tempUserID")
				param.Operator_Name = sessionStorage.getItem("tempName")
				sessionStorage.removeItem("tempUserID")
				sessionStorage.removeItem("tempName")
			}
			if(this.orderType==2){
				param = Object.assign(param, {Rpt_Date:this.orderInfo.Rpt_Date})//营业日期
			}
			if(Number(this.DinersNum)==0){
				this.$confirm('就餐人数确认为0吗?', '提示', {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() => {
					this.ModifyOrderInfo(param)
				}).catch(() => {
					this.$message({type: 'info',message: '调整桌台取消'});          
				});
			}else{
				this.ModifyOrderInfo(param)
			}
		},
		ModifyOrderInfo(param){
			const loading = this.$loading({
				text: "提交修改中...",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			this.$httpAES.post("Bestech.CloudPos.ModifyOrderInfo",param).then((data)=>{
				loading.close();
				if(data.ResponseHeader.ResultCode!=0){
					this.$message.error(data.ResponseHeader.ResultDesc);
				}else{
					ElMessage.success({ message: "信息修改成功"});
					if(data && data.ResponseBody){
						//订单渠道名称
						data.ResponseBody.OrderChannel_Name=this.OrderList?.find(t=>t.OrderChannel_AutoID==this.orderclass)?.OrderChannel_Name;
						//消费类型名称
						data.ResponseBody.ConsumeType_Name=this.ConsumptionList?.find(t=>t.ConsumeType_AutoID==this.consumeAutoID)?.ConsumeType_Name;
					}
					this.$emit("updateCardClick",data);
				}
			}).catch((e)=>{
				loading.close();
				this.$alert(e.message, "提示", {confirmButtonText: "确定"});
			})
		},
		//开台
		SetDeskMethod(param,isMenuOrder){ 
			if(this.Eat_UnionDeskList?.length>0){//多台开台
				this.OpenUnionDesk(param,isMenuOrder);
				return;
			}

			let Desk_Status=0;
			const loading = this.$loading({
				text: "数据传输中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			this.$httpAES.post("Bestech.CloudPos.OpenDesk", param).then((d)=>{
				loading.close();
				if(d.ResponseHeader.ResultCode!=0){
					this.$message.error(d.ResponseHeader.ResultDesc);
					return;
				}else{
					Desk_Status= 1;  //状态调整成开台
					let OpenDeskInfo =d.ResponseBody;
					if(d.ResponseBody?.PrintsInfo){
						//返回开台打印内容
						this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
					}
					let _OrderConsumes=''
					if(d.ResponseBody?.OrderConsumes){
						_OrderConsumes = d.ResponseBody?.OrderConsumes
					}
					this.$emit("SetOpenDesk",this.openDeskAuto,Desk_Status,OpenDeskInfo,_OrderConsumes,isMenuOrder);
					this.$emit("CloseClick"); //关闭开台弹窗
					return;
				}
			}).catch((e)=>{
				loading.close();
				this.$alert(e.message, "提示", {confirmButtonText: "确定"});
			});
		},
		//联台开台
		OpenUnionDesk(param,isMenuOrder){
			param.Eat_UnionDeskIds=this.Eat_UnionDeskList.map(it=>it.Desk_AutoID);
			let Desk_Status=0;
			const loading = this.$loading({
				text: "数据传输中",
				spinner: "el-icon-loading",
				background: "rgba(0, 0, 0, 0.7)",
			});
			this.$httpAES.post("Bestech.CloudPos.OpenUnionDesk", param).then((d)=>{
				loading.close();
				if(d.ResponseHeader.ResultCode!=0){
					this.$message.error(d.ResponseHeader.ResultDesc);
					return;
				}else{
					Desk_Status= 1;  //状态调整成开台
					let OpenDeskInfo =d.ResponseBody;
					if(d.ResponseBody?.PrintsInfo){
						//返回开台打印内容
						this.$webBrowser.posPrint(d.ResponseBody.PrintsInfo);
					}
					let _OrderConsumes=''
					if(d.ResponseBody?.OrderConsumes){
						_OrderConsumes = d.ResponseBody?.OrderConsumes
					}
					this.$emit("SetOpenDesk",this.openDeskAuto,Desk_Status,OpenDeskInfo,_OrderConsumes,isMenuOrder);
					this.$emit("CloseClick"); //关闭开台弹窗
					return;
				}
			}).catch((e)=>{
				loading.close();
				this.$alert(e.message, "提示", {confirmButtonText: "确定"});
			});
		},
		/**会员选择返回*/
		selectCardInfo(data){
			this.isread=true
			this.CardID=data?.Card_No;
			this.ClientName = data?.MemberName;
			this.CardPhone = data?.Mobile;
			this.CardLevel = data?.Bestech_Card_KindName;
			this.birthday = data?.Birthday;
			this.ClientLike = data.MemberAnalysisData?.Habits;
			this.cardMemberShow = false;
			this.iscardMemberShow=true
		},
		/**清空 */
		empty(){
			this.isread=false
			this.ClientName=''
			this.CardPhone = '';
			this.CardLevel = '';
			this.CardID='',this.DinersNum='',this.SeatNumber=1,this.Waiter='',this.SalesValue='',this.consumeAutoIDName='',this.consumeAutoID=''
			this.orderChannelName = '',this.orderclass ='',this.orderRemark='',this.birthday='',this.ClientLike='',this.terminationID='',
			this.serverID='';
			this.Eat_UnionDeskList=[];
			this.tempCheckUser=null;
		},
		/**去除重复 */
		unique(newarr) {
			const res = new Map();
			return newarr.filter((newarr) => !res.has(newarr.OrderChannel_AutoID) && res.set(newarr.OrderChannel_AutoID, 1));
		},
		//多开台 返回数据
		multeipleDeskConfirm(list){
			this.Eat_UnionDeskList=list;
			this.multeipleBillShow=false;
		},
		//点击多开台
		multeBillClick(){
			if(this.moreOpenDesk.Permission){
				this.multeipleBillShow=true;
			}else{
				this.$checkUser.show('该账户权限不足,不支持多台开单功能',{menuKey:"MoreOpenDesk"}).then(d=>{
					this.tempCheckUser=d;
					this.multeipleBillShow=true;
				}).catch(()=>{ 
					
				});
			}
		}
    }
}
</script>
<style lang="scss">
@import "./opening.scss";
.cardShow{ transition: all ease .1s; }
.cardShow-enter-active { animation:identifier .1s;    overflow: hidden; }
.cardShow-leave-active { animation:against .1s ; overflow: hidden; }
@keyframes identifier {
  from { height: 0; }
  to { height: 0px; }
}
@keyframes against {
 
  from { height: 0px; }
 
  to { height: 0; }
 
}
</style>