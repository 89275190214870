<template>
    <modal class="waiterSalesModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">
            <template v-if="title">{{title}}</template>
            <template v-else-if="waiSaleType==1">选择服务员</template>
            <template v-else>选择销售员</template>
        </div>
        <div class="modal-body">
            <div class="search-box">
                <div class="search"><input type="text" placeholder="请输入编码、姓名检索" v-model="searchText" @keyup.enter="search()"></div>
                <div class="search-bnt" @click="search()">搜索</div>
            </div>
            <div class="panel panel-table" >
                <div class="panel-heading">
                    <div class="tr">
                        <div class="td selectChecked">选择</div>
                        <div class="td code">编码</div>
                        <div class="td name">名称</div>
                    </div>
                </div>
                <div class="panel-body" ref="box" v-scroll-anime="{up:'order-up',down:'order-down',liClass:'.tr'}">
                        <div class="tr" v-for="item in posUsers" :key="item" :class="{selected:item.User_ID==seleUser.User_ID}" @click="posUsersClick(item)"  @dblclick="doubleClick(item)" > 
                            <div class="td selectChecked">
                                <span class="iconfont " :class="item.User_ID==seleUser.User_ID?'icon-iconoption1':'icon-iconoption'"></span>
                            </div>
                            <div class="td code">{{item.User_Code}}</div>
                            <div class="td name">{{item.User_Name}}</div>
                        </div>
                </div>
            </div>
            <div class="item-operation">
                <div class="float-right">
                    <div class="bnt-fangxiang" ref="order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                    <div class="bnt-fangxiang" ref="order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-confirm" @click="waiSaleConfirm()">确认</button>
        </div>
    </modal>
</template>

<script>

/**选择开卡人  */
export default {
    name:"waiterSalesModel",
    emits:["closeModel","waiSaleConfirm"],
    props:{ 
        isShow:Boolean,
        WaiterList:Object,
        SalesList:Object,
        waiSaleType:Number,
        //标题
        title:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            /**开卡人 */
            posUsers:Object,
            posUsersCopy:Object,
            seleUser:Object,
            /**搜索 */
            searchText:''
        }
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newValue){
            if(newValue){
                this.init();
            }
        },
        searchText(){
            let MenuTxt=this.searchText.toLowerCase();
            if(MenuTxt!=''){
                this.posUsers=[]
                this.posUsersCopy.map(item=>{
                    if((item.User_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (item.User_Name||"").toLowerCase().indexOf(MenuTxt)>=0){
                        this.posUsers.push(item)
                    }
                })
            }else{
                this.posUsers = this.posUsersCopy
            }
        },
    },
    methods:{
        /**初始化数据 */
        init(){
            this.seleUser={}
            this.searchText=''
            if(this.waiSaleType==1){
                this.posUsers=this.WaiterList;
                this.posUsersCopy=this.WaiterList;
            }else{
                this.posUsers=this.SalesList;
                this.posUsersCopy=this.SalesList;
            }
        },
        /**选择开卡人 */
        posUsersClick(item){
            this.seleUser = item
        },
        /**搜索 */
        search(){
            let MenuTxt=this.searchText.toLowerCase();
            if(MenuTxt!=''){
                this.posUsers=[]
                this.posUsersCopy.map(item=>{
                    if((item.User_Code||"").toLowerCase().indexOf(MenuTxt)>=0 || (item.User_Name||"").toLowerCase().indexOf(MenuTxt)>=0){
                        this.posUsers.push(item)
                    }
                })
            }else{
                this.posUsers = this.posUsersCopy
            }
        },
        /**双击测试 */
        doubleClick(item){
             this.seleUser = item
            this.$emit("waiSaleConfirm",this.seleUser);
        },
        hide(){
            this.$emit("closeModel");
        },
        waiSaleConfirm(){
            this.$emit("waiSaleConfirm",this.seleUser);
        }
    }
}
</script>

<style lang="scss">
@import "./waiterSalesModel.scss";
</style>