<template>
    <modal class="kitOrderPrintModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">补打厨房单</div>
        <div class="modal-body">
           <div class="filter-box">
                <div class="inline-block">
                    <span class="lable-txt">时间段：</span>
                    <el-time-picker class="from-time" 
                        is-range
                        :editable="false"
                        range-separator="-"
                        v-model="timePeriod"
                        @change="(val)=>fileTimePeriod=timePeriod"
                        format="HH:mm">
                    </el-time-picker>
                </div>
                <div class="inline-block">
                    <span class="lable-txt">任务状态：</span>
                    <el-select v-model="printState" >
                        <el-option :value="-1" label="所有任务"></el-option>
                        <el-option label="打印处理中" :value="0"></el-option>
                        <el-option label="打印完成" :value="1"></el-option>
                        <el-option label="打印失败" :value="2"></el-option>
                        <!-- <el-option label="未设置打印类型" :value="3"></el-option>
                        <el-option label="下单不送厨房打印" :value="4"></el-option> -->
                    </el-select>
                </div>
                <div class="inline-block">
                    <span class="lable-txt">出单类型：</span>
                    <el-select v-model="eatIssueAutoIDs" multiple collapse-tags placeholder="请选择出单类型">
                        <el-option :value="item.Eat_IssueAutoID" :label="item.Eat_IssueName" v-for="item in kitIssuingTypes" :key="item"></el-option>
                    </el-select>
                </div>
                <div class="inline-block search-box">
                    <div class="search">
                        <input type="text" placeholder="按桌号、菜品检索" v-model="searchText">
                    </div>
                    <div class="bnt-search" @click="loadTable()">搜索</div>
                </div>
            </div>
            <div class="flex-box">
                <div class="data-box">
                    <div class="table-box grey-table" ref="tableBox">
                        <el-table class="el-table--scrollable-y" :data="tablePageList"  height="400px" style="width: 100%;"  
                            @sort-change="sortChange" 
                            @selection-change="selectRow"
                            highlight-current-row
                            @current-change="currentChange"
                            border
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                            <el-table-column fixed="left" type="selection" width="40" />
                            <el-table-column sortable="custom" prop="PrintState" label="打印状态" min-width="100">
                                <template #default="scope">
                                    {{(["打印处理中","打印完成","打印失败"])[scope?.row?.PrintState]}}
                                </template>
                            </el-table-column>
                            <el-table-column sortable="custom" prop="Eat_DeskName" label="桌号" min-width="120" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="Eat_IssueName" label="出单类型" min-width="100" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="Eat_XFName" label="菜品名称" min-width="120" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="Printer_Name" label="打印机" min-width="120" align="left">
                                <template #default="scope">
                                    {{scope?.row?.Printer_Desc}}<span v-if="scope?.row?.Printer_Name">({{scope.row.Printer_Name}})</span>
                                </template>
                            </el-table-column>
                            <el-table-column sortable="custom" prop="Eat_ID" label="开单号" min-width="140" align="left"></el-table-column>
                            <el-table-column sortable="custom" prop="Eat_Czy" label="点单人"  min-width="80"></el-table-column>
                            <el-table-column sortable="custom" prop="Eat_Time" label="点单时间" :formatter="formatter"  width="140"></el-table-column>
                            <el-table-column sortable="custom" prop="Finish_Time" label="完成打印时间" :formatter="formatter" width="140"></el-table-column>
                            <el-table-column prop="Err_Msg" label="原因描述" min-width="140" align="left"></el-table-column>
                            <el-table-column fixed="right" label="操作" :width="50" >
                                <template #default="scope">
                                    <el-button @click="print([scope.row.Task_AutoID])" type="text" size="small">补打</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <div class="page-box">
                        <span>当前第 {{tablePageIndex}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{tablePageSum}}页</span>
                        <div class="page-button">
                            <page-Turning class="but but-up" type="up" v-model="tablePageIndex" :pageCount="tablePageSum">上一页</page-Turning>
                            <page-Turning class="but but-down" type="down" v-model="tablePageIndex" :pageCount="tablePageSum">下一页</page-Turning>
                        </div>
                    </div>
                </div>
                <div class="preview">
                    <div class="scroll-box" v-mouse-scroll v-html="Print_Html">
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn" @click="hide()">返回</button>
            <button class="btn btn-confirm" @click="batchPrint()">批量补打</button>
        </div>
    </modal>
</template>

<script>
import { printView } from '/src/common';
// 补打厨房单
export default {
    name:'kitOrderPrintModel',
    emits:["close"],
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
        //订单id
        orderID:{
            type:String,
            default:""
        }
    },
    data(){
        return{
            //0按桌号 1菜品检索
            searchType:0,
            //模糊检索
            searchText:"",
            //厨房出单类型数据
            kitIssuingTypes:[],
            //出单类型 多个
            eatIssueAutoIDs:[],
            //按桌号、结账单号检索
            deskNumberSearch:'',
            //任务状态
            printState:-1,
            /**时间段 */
            timePeriod:'',
            fileTimePeriod:'',
            tablePageSize:7,
            tablePageIndex:1,
            /**数据 */
            tableList:[],
            //排序用
            sortable:undefined,
            /**勾选的数据 */
            checkArr:[],
            //选中单个
            currentRow:null,
            //打印的数据
            printBodies:[],

        }
    },
    computed:{
        Print_Html(){
            return printView(this.printBodies);
        },
        tableFilter(){
            let list=this.tableList||[];
            if(list && list.length>0){
                if(this.searchText!=''){//搜索
                    let seach=this.searchText.toUpperCase();
                    list=list.filter((item)=>(item.Eat_DeskName||'').toUpperCase().indexOf(seach)>=0 || (item.Eat_XFName||'').toUpperCase().indexOf(seach)>=0)
                }
                if(this.fileTimePeriod){//>时间段
                    let start=this.fileTimePeriod[0];
                    let end=this.fileTimePeriod[1];
                    list=list.filter(it=>this.getTimeHM(it.Eat_Time)>=this.getTimeHM(start) && this.getTimeHM(it.Eat_Time)<=this.getTimeHM(end))
                }

                if(this.printState>=0){//任务状态
                    list=list.filter(it=>it.PrintState==this.printState);
                }

                if(this.eatIssueAutoIDs?.length>0){//出单类型
                    list=list.filter(it=>this.eatIssueAutoIDs.indexOf(it.Eat_TypeAutoID)>=0);
                }
            }
            //排序
            if(this.sortable){
                list=Object.assign([],this.tableList);
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            }
            return list;
        },
        /**分页数据 */
        tablePageList(){
            if(this.tableFilter && this.tableFilter.length>0){
                 return this.tableFilter.slice(((this.tablePageIndex-1)*this.tablePageSize),(this.tablePageIndex)*this.tablePageSize);
            }
            return [];
        },
        //分页总页数 
        tablePageSum(){
            let lenth=1;
            if(this.tableFilter && this.tableFilter.length>0){
                lenth=Math.ceil(this.tableFilter.length/this.tablePageSize)||1;
            }
            return lenth;
        },
    },
    watch:{
        isShow(newVal){
            if(newVal){
                //出单类型 多个
                this.eatIssueAutoIDs=[];
                //按桌号、结账单号检索
                this.deskNumberSearch='';
                //任务状态
                this.printState=-1;
                /**时间段 */
                this.timePeriod='';
                this.fileTimePeriod='';
                this.tablePageIndex=1;
                /**数据 */
                this.tableList=[];
                //排序用
                this.sortable=null;
                /**勾选的数据 */
                this.checkArr=[];
                //选中单个
                this.currentRow=null;
                //打印的数据
                this.printBodies=[];
            }
        },
        tablePageSum(){
            if(this.tablePageSum<this.tablePageIndex){
                this.tablePageIndex=1;
            }
        }
    },
    mounted(){
        this.$cacheData.KitIssuingTypes().then((d)=>{
            this.kitIssuingTypes=d;
        }).catch(e=>{
            console.log('厨房出单类型数据获取失败e:'+e)
        })
    },
    methods:{
        hide(){
            this.$emit("close");
        },
        /*时间格式 */
        formatter(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        //勾选的数据
        selectRow(currentRow){
            this.checkArr=currentRow;
        },
        //选中行
        currentChange(row){
            this.currentRow=row;
            this.loadPrintView(row.Task_AutoID);
        },
        /**账单数据排序 */
        sortChange(column){
            this.sortable=column;
        },
        /**截取时间 小时 和 分钟  */
        getTimeHM(date){
            let d=new Date(date);
            return d.getHours()*60+d.getMinutes();
        },
        /**获取厨房单 */
        loadTable(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.tableList=[];
            
            this.$httpAES.post("Bestech.CloudPos.GetKitPrintTasks",{Order_ID:this.orderID}).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tablePageIndex=1;
                    this.tableList=d.ResponseBody||[];
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载厨房单信息数据失败：',e);
            })
        },
        //打印数据预览
        loadPrintView(id){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let param={
                Task_AutoID:id,//打印任务主键ID
            }
            this.printBodies=[];
            this.$httpAES.post("Bestech.CloudPos.GetKitPrintTaskPreview",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.printBodies=d.ResponseBody||[];
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载打印数据预览失败：',e);
            })
        },
        /**补打 */
        print(autoIds){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            let param={
                Operator_Name:userInfo.Login_Name,
                Task_AutoIds:autoIds,//打印任务主键列表
            }
            this.$httpAES.post("Bestech.CloudPos.KitPrintTaskReprint",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.$message.success("打印任务发送成功");
                    if(d.ResponseBody){//打印数据
                        this.$webBrowser.posPrint(d.ResponseBody);
                    }
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载打印数据失败：',e);
            })
        },
        batchPrint(){
            let autoIds=this.checkArr?.map(it=>{return it.Task_AutoID});
            if(!autoIds || autoIds.length==0){
                this.$message.warning("请勾选需要批量补打的数据");
                return;
            }
            this.print(autoIds)
        }
    }
}
</script>

<style lang="scss">
@import './kitOrderPrintModel.scss'
</style>