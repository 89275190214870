<template>
    <modal class="yiMaCouponsPayModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">翼码卡券核销<span class="close" @click="hide()">×</span></div>
        <div ref="code">
        <div class="The-head">
            请输入或扫码：
            <div class="from-input" >
                <i v-if="$webBrowser?.isMobile?.Android" class="iconfont icon-a-saoma13x" @click="scanCode"></i>
                <i v-else class="iconfont icon-a-saoma13x"></i>
                <input type="text" ref="input" class="key-focus" data-model='idCode' v-model="idCode"  @focus="(e)=>e.target.select()" @keyup.enter="readCard()" />
            </div>
            <button class="btn" @click="readCard()">查询</button>
            <span class="radio-st" >
            <label class="checkbox" @click="radioClick(1)"><input type="radio" name="_fa_radio" :checked="tickChecke" />
            <i class="iconfont icon-a-danxuanweixuanzhong"></i>按券码查询</label></span>
            <span class="radio-st"  >
                <label class="checkbox" @click="radioClick(2)"><input type="radio" name="_fa_radio" :checked="mobleChecke" />
                <i class="iconfont icon-a-danxuanweixuanzhong"></i>按手机号查询</label>
            </span>
        </div>
        <div class="modal-body">
            <div class="clearfix">
                <div class="left-box">
                    <div class="panel">
                        <div class="panel-heading">券列表</div>
                        <div class="panel-body item-box clearfix" v-scroll-anime="{up:'left-order-up',down:'left-order-down',liClass:'.item-li',isRow:false}">
                            <div class="item-li coupons" :class="{selected:item._selected}" @click="onTicket(item)"
                            v-for="item in ticketInfos" :key="item">
                                <div class="coupons-name">
                                    <div class="coupon-left" :class="{selected:item.Check==true}">
                                        <div class="left-txt">
                                            <span class="m">¥</span>
                                            <span class="y">{{item.Coupon_Amount}}</span>
                                        </div>
                                    </div>
                                    <div class="coupon-right" :class="{selected:item.Check==true}">
                                        <div class="coupon-top">
                                            <span class="tk-txt">{{item.Coupon_Name}}</span>
                                            <span class="tk-time">{{item.Coupon_Code}}</span>
                                            <span class="tk-time">有效期:{{new Date(item.Start_Time).Format("yyyy-MM-dd")}}至{{new Date(item.End_Time).Format("yyyy-MM-dd")}}</span>
                                        </div>
                                        <span class="cou-ge"></span>
                                        <div class="coupon-bottom">
                                            详细信息
                                            <div class="triangle" v-show="item.extendShow==false" @click="extenClick(item)"></div>
                                            <div class="triangles" v-show="item.extendShow==true" @click="extenClick(item)"></div>
                                        </div>
                                        <div class="extend" v-show="item.extendShow==true">
                                            <span style="margin-left:-120px;" v-show="item.Consume_MinMoney>0">使用说明</span>
                                            <span class="ex-span" v-show="item.Consume_MinMoney>0" >消费满{{item.Consume_MinMoney}}元可使用此券</span>
                                        </div>
                                        <i class="iconfont icon-gou-fine"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="item-operation">
                            <div class="float-right">
                                <div class="bnt-fangxiang" ref="left-order-up"><i class="iconfont icon-fangxiang-up"></i></div>
                                <div class="bnt-fangxiang" ref="left-order-down"><i class="iconfont icon-fangxiang-down"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="right-box">
                    <div class="keyboard">
                        <div class="key-li"><span data-value="7">7</span><span data-value="8">8</span><span
                                data-value="9">9</span></div>
                        <div class="key-li"><span data-value="4">4</span><span data-value="5">5</span><span
                                data-value="6">6</span></div>
                        <div class="key-li"><span data-value="1">1</span><span data-value="2">2</span><span
                                data-value="3">3</span></div>
                        <div class="key-li"><span data-value=".">.</span><span data-value="0">0</span><span
                                data-value="back"><i class="iconfont icon-zuojiantou"></i></span></div>
                    </div>
                    <div class="box-bottom">
                        <div><span class="btm-span">待支付金额:</span>
                            <span class="btm-span-money">
                                {{invoMoney}}
                            </span>
                        </div>
                        <div class="tick-div"><span class="tick-span">券抵扣:</span><span class="tick-money">{{Number(this.tickDeduMoney).toFixed(2)}}</span></div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">取消</button>
            <button class="btn btn-border" @click="select()">重新查询核销结果</button>
            <button class="btn btn-confirm" @click="confirm()">确定</button>
        </div>
        <!--券数量修改-->
        <modal-load :isShow="numShow" :isUnload="true">
            <num-modify-model :isShow="numShow" @numCloseModel="numCloseModel" @cashConfirm="cashConfirm"></num-modify-model>
        </modal-load>
    </modal>
</template>
<script>

import { initKeyBoard } from '/src/common' 
import { newGuid } from '/src/common/index.js'
import numModifyModel from '../../diningDetail/numModifyModel/numModifyModel.vue'

/**翼码卡券核销*/
export default {
    name:"yiMaCouponsPayModel",
    components:{
        numModifyModel
    },
    props:{
        isShow:Boolean,
        unionEatAutoID:Object,
        choicePayType:Object,
        unionType:Boolean,
        orderMenuList:Object,
        headingInfo:Object,
        unPaidMoney:Number,
        RoomAutoID:String,
        DeskName:String,
        //单据id
        EatAutoID:String,
        //单据时间戳
        Timestamps:String,
        MantissaMoney:Number,
        Channellike:String,
    },
    data(){
        return {
            numShow:false,
            idCode:'',
            /**券数据 */
            ticketInfos:[],
            /**选中的券 */
            selectCoupon:[],
            /**当前选中的券 */
            presentCoupon:'',
            /**菜品信息 */
            posFoodData:'',
            tickChecke:true,
            mobleChecke:false,
            /**保存订单支付返回记录 */
            payPreviewData:'',
            /**交易记录 */
            Payments:[],
            /**临时订单数据 */
            memberorderInfo:'',  
            tickDeduMoney:0,//券抵扣金额
            amountMoney:0, //代金券抵扣金额
            userInfo:'', //登录用户
            //记录时间戳
            tmpTimestamps:''
        }
    },
    mounted(){
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
            this.posFoodData = d.data
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        this.$nextTick(()=> {
           initKeyBoard.call(this,this.$refs.code);
        })
        this.init();
    }, 
    computed:{
        //结账金额
        invoMoney(){
            let invoMoney=0;
            if(Number(this.unPaidMoney).toFixed(2) - Number(this.tickDeduMoney).toFixed(2)>0){
                invoMoney = Number(Number(this.unPaidMoney) - Number(this.tickDeduMoney)).toFixed(2)
            }
            return invoMoney
        }
    },
    watch: {
        orderInfo:{
            immediate:true,
            handler(){
                this.init();
            }
        },
        isShow(newVal) {
            if(newVal){
                this.init();
            }
        }
    },
    methods:{
        /**安卓 扫码 */
		scanCode() {
			if (window.webview) {
				this.$webBrowser.hasCamera().then((isQr) => {
					if (isQr == "1") {
						this.$webBrowser.qr().then((d) => {
							this.idCode=d;
                            this.readCard();
						}).catch((e) => {
							this.$message({ message: "扫码失败:" + e, type: "warning" });
						});
					} 
				});
			} 
		},
        init(){
            //this.mobleChecke=false
            this.idCode=''
            this.ticketInfos=[],this.Payments=[],this.payPreviewData=''
            this.selectCoupon=[],this.tickChecke=true,this.mobleChecke=false,this.tickDeduMoney=0
            this.memberorderInfo = JSON.parse(JSON.stringify(this.orderMenuList))
            this.memberorderInfo.sort(this.compare('Eat_CheckPrice',''));
            this.userInfo=this.$auth.getUserInfo();
            this.tmpTimestamps=this.Timestamps;
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        //重新查询核销结果
        select(){
            if(this.EatAutoID==''){
                this.$message.warning('没有需要查询的支付结果');
                return
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let param={
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.EatAutoID, //结账单ID
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Operate_EquipmentID:this.userInfo?.Default_ChannelID, //设备id
                Pos_StationName:this.userInfo?.Site_Name, //站点名称
                Timestamps:this.tmpTimestamps, //账单时间戳
            }
            const loading = this.$loading({
                text: "查询支付结果中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.1)",
            });
            console.log(param)
            this.$httpAES.post("Bestech.CloudPos.RequeryThePaymentResult",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                    if(data.ResponseBody?.Timestamps){
                        this.tmpTimestamps = data.ResponseBody.Timestamps.toString();
                        this.$emit("setTimestamps",this.tmpTimestamps)
                    }
                }else{
                    if(data.ResponseHeader.ResultDesc){
                        this.$message.success({ message:data.ResponseHeader.ResultDesc});
                    }
                    if(data.ResponseBody?.Timestamps){
                        this.tmpTimestamps = data.ResponseBody.Timestamps.toString();
                        this.$emit("setTimestamps",this.tmpTimestamps)
                    }
                    return;
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        radioClick(type){
            if(type==1){
                this.tickChecke=true
                this.mobleChecke=false
            }else{
                this.tickChecke=false
                this.mobleChecke=true
            }
            setTimeout(() => {
                this.$refs.input.focus();
            },100);
        },
        extenClick(item){
            if(item.extendShow==true){
                item.extendShow=false
            }else{
                item.extendShow=true
            }
        },
        readCard(){
            if(!this.idCode){
                this.$message.warning('查询码不能为空');
                return
            }
            //获取平台券信息
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,
                Platform_Type:4, //平台类型 1:抖音 2:美团 3:口碑 4: 翼码
                Dynamic_Code:this.idCode,
                Dynamic_Type:'',//查询码类型 ，mobile : 按手机号查询 ，不传按默认查询类型
            }
            if(this.mobleChecke){
                param.Dynamic_Type='mobile'
            }
            const loading = this.$loading({
                text: "获取券信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CouponPlatform.GetCouponInfo",param).then((data)=>{
                loading.close();
                this.$refs.input?.focus();
                this.$refs.input?.select();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                }else{
                    this.ticketInfos = data?.ResponseBody
                    this.ticketInfos.forEach(tick=>{
                        Object.assign(tick,{extendShow:false,Check:false,deDuMoney:0,newCoupon_Id:newGuid()})
                    })
                    if(this.memberorderInfo){
                        this.memberorderInfo.forEach((order)=>{
                            Object.assign(order,{isCoupon:false,CouponNumber:0})
                        })
                    }
                    this.memberorderInfo.sort(this.compare('Eat_CheckPrice',''));
                    this.selectCoupon=[],this.tickDeduMoney=0
                    return
                    
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        //关闭
        hide(){
            this.$emit("closeModel")
        },
        //点击券
        onTicket(item){
            if(item.Check==true){
                this.selectCoupon.some((set,index)=>{
                    if(set.newCoupon_Id==item.newCoupon_Id){
                       if(set?.Check==true){
                            if(set.Exchange_Num){
                                 this.tickDeduMoney -= Number(set.deDuMoney) * Number(set.Exchange_Num)
                            }else{
                                 this.tickDeduMoney -= Number(set.deDuMoney)
                            }
                            if(set?.CouponNum){
                                this.tickDeduMoney -= Number(set.deDuMoney) * (Number(set.CouponNum)-1)
                            }
                            //券类型 1:代金券 2:折扣券 3.商品券
                            if(item.Coupon_Type==3){
                                this.memberorderInfo.forEach(order=>{
                                    if(set.ConsumeID==order.Eat_ConsumeID){
                                        order.CouponNumber = Number(Number(Number(order.CouponNumber)-1).toFixed(2))
                                        order.isCoupon=false
                                    }
                                })
                            }
                            this.selectCoupon.splice(index, 1);
                            index--
                            item.Check=false
                        }
                        return true
                    }
                })
            }else{
                //券的最低消费额，0 不限制
                if(item.Consume_MinMoney!=0){
                    if(this.orderInfo.Payable_Money<item.Consume_MinMoney){
                        this.$message.warning('账单未达到使用该券的金额!');
                        return
                    }
                }
                //时间段控制 0所有时段,1指定时段
                let date = new Date()
                if(new Date(item.Start_Time)>date || date > new Date(item.End_Time)){
                    this.$message.warning('不在该券可使用的时间范围内!');
                    return
                }
                //1.代金券
                if(item.Coupon_Type==1){
                    item.Check=true;
                    item.deDuMoney = item.Coupon_Amount;
                    this.CoupondeduMoney(item,'','');
                }
                //2.折扣价
                if(item.Coupon_Type==2){
                    item.Check=true
                    item.deDuMoney = Number(Number(this.unPaidMoney)-(Number(this.unPaidMoney) * (item.Discount_Rate / 100)).toFixed(2))
                    this.CoupondeduMoney(item,'','')
                }
                //3.商品券
                if(item.Coupon_Type==3){
                    //FoodRange_Type适用类型 1:通用券，所有商品适用，2:部分商品券
                    if(item.FoodRange_Type==1){
                        this.memberorderInfo.some(order=>{
                            //排除口味要求和赠送的菜品
                            if(!order?.is_taste && !order?.Is_Gift){
                                if(order.isCoupon==false && order.Eat_Number>0 && order.Eat_Number>order.Exchanged_Number){
                                    if(item.Food_Weight==0){
                                        item.deDuMoney = Number(order.Eat_CheckPrice)
                                        item.Check=true
                                        this.CoupondeduMoney(item,order,'')
                                        if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                            order.CouponNumber += 1
                                        }
                                        if(order.CouponNumber>=order.Eat_Number){
                                            order.isCoupon=true
                                        }
                                        return true
                                    }else{
                                        let _weight=this.commWeight(item,order)
                                        item.deDuMoney = Number(order.Eat_CheckPrice)
                                        item.Check=true
                                        this.CoupondeduMoney(item,order,_weight)
                                        if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                            order.CouponNumber += _weight
                                        }
                                        if(order.CouponNumber>=order.Eat_Number){
                                            order.isCoupon=true
                                        }
                                        return true
                                    }
                                }
                            }
                        })
                    }
                    if(item.FoodRange_Type==2){
                        let title =''
                        let _types = 0
                        this.memberorderInfo.some(order=>{
                            if(order.isCoupon==false && !order?.Is_Gift && !order?.is_taste){
                                if(item.Exchange_FoodCodes.indexOf(order.EAT_XFCode)!=-1 && order.Eat_Number>0 && order.Eat_Number>order.Exchanged_Number){
                                    if(item.Food_Weight==0){
                                        _types=1
                                        item.deDuMoney = Number(order.Eat_CheckPrice)
                                        item.Check=true
                                        if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                            order.CouponNumber += 1
                                        }
                                        if(order.CouponNumber>=order.Eat_Number){
                                            order.isCoupon=true
                                        }
                                        this.CoupondeduMoney(item,order,'')
                                    }else{
                                        _types=1
                                        let _weight=this.commWeight(item,order)
                                        item.deDuMoney = Number(order.Eat_CheckPrice)
                                        item.Check=true
                                        if(order.CouponNumber==0 || order.CouponNumber<order.Eat_Number){
                                            order.CouponNumber += _weight
                                        }
                                        if(order.CouponNumber>=order.Eat_Number){
                                            order.isCoupon=true
                                        }
                                        this.CoupondeduMoney(item,order,_weight)
                                    }
                                    
                                }else{
                                    if(order.Eat_Number<=order.Exchanged_Number){
                                        title = '该券对应的菜品已使用过券,已不满足条件'
                                    }else{
                                        title = '未找到编号'+item.Exchange_FoodCodes+'的菜品'
                                    }
                                }
                                if(_types==1){
                                    return true
                                }
                            }
                        })
                        if(_types==0){
                            if(title==''){
                                title='没有满足条件的菜品'
                            }
                            this.$message.warning(title);
                            return
                        }
                    }
                }
                //如果IsVerifyWithInputNum为True则弹出数量选择
                if(item.IsVerifyWithInputNum==true){
                    this.numShow=true
                    this.presentCoupon=item
                }
            }
        },
        //商品券兑换商品重量(单位：斤），0 不限制
        commWeight(item,order){
            if(item.Food_Weight>=order.Eat_Number){
                return order.Eat_Number
            }else if(item.Food_Weight<=order.Eat_Number-order.Exchanged_Number){
                return item.Food_Weight
            }else if(item.Food_Weight>order.Eat_Number-order.Exchanged_Number){
                return Number(Number(order.Eat_Number-order.Exchanged_Number).toFixed(2))
            }
        },
        //deduMoney变动
        CoupondeduMoney(item,order,weight){
            let _item= JSON.parse(JSON.stringify(item))
            this.selectCoupon.push(_item)
            this.tickDeduMoney=0
            this.selectCoupon.forEach(sele=>{  
                if(sele.Check==true && !sele?.ConsumeID){
                    if(order){
                        if(item.Food_Weight!=0){
                            sele = Object.assign(sele,{ConsumeID:order.Eat_ConsumeID,Exchange_Num:weight})
                        }else{
                            let _num=0
                            if(order.Exchanged_Number!=0){
                                if(order.Eat_Number-order.Exchanged_Number>1){
                                    _num=1
                                }else{
                                    _num = order.Eat_Number-order.Exchanged_Number
                                }
                            }else{
                                if(order.Eat_Number<1){
                                    _num = order.Eat_Number
                                }else{
                                    _num = 1
                                }
                            }
                            sele = Object.assign(sele,{ConsumeID:order.Eat_ConsumeID,Exchange_Num:_num})
                        }
                    }else{
                        sele = Object.assign(sele,{Exchange_Num:1})
                    }
                }
                if(sele.Exchange_Num){
                    this.tickDeduMoney += sele.deDuMoney * sele.Exchange_Num
                }else{
                    this.tickDeduMoney += sele.deDuMoney
                }
            })
        },
        //确定支付
        confirm(){
            if(this.selectCoupon.length==0){
                this.$message.warning('请先选择优惠券!');
                return
            }
            /**支付方式 */
            let _unpaiMoney = Number(this.unPaidMoney)
            let _payment=[]
            this.selectCoupon.forEach(tick=>{
                let _choice={
                    Coupon_AutoId:tick.Coupon_TemplateId,
                    Coupon_Code:tick.Coupon_Code,
                    Coupon_Name:tick.Coupon_Name,
                    Coupon_Kind:tick.Coupon_Type,
                    Coupon_Num:1,
                    Coupon_CostPrice:tick.Merchant_FactAmount,
                    Exchange_ConsumeID:tick?.ConsumeID,
                    Exchange_Num:tick?.Exchange_Num,
                    Coupon_VerifyToken:tick.Coupon_VerifyToken,
                    Scene_Type:1,
                    External_UserId:tick?.External_UserId,
                    Customer_Mobile:tick?.Customer_Mobile,
                    Channel_ID:tick?.Channel_ID
                }
                if(tick?.CouponNum){
                    _choice.Coupon_Num=Number(tick?.CouponNum);
                }
                //多收金额
                let OverChargeMoney=0
                if(Number(tick.deDuMoney) * 1 >= _unpaiMoney){
                     OverChargeMoney = Number(tick.deDuMoney) * 1 - _unpaiMoney;
                     _unpaiMoney=0
                }else{
                    _unpaiMoney = _unpaiMoney - Number(tick.deDuMoney) * 1
                }
                let Payment_Money=Number(tick.deDuMoney) * tick.Exchange_Num; //商品券 支付金额
                if(tick.Coupon_Type==1){//代金券 支付金额
                    Payment_Money=Number(tick.deDuMoney) * _choice.Coupon_Num;
                }else if(tick.Coupon_Type==2){//折扣券 支付金额
                    Payment_Money=Number(tick.deDuMoney);
                }
                _payment.push({
                    Payment_ID:this.choicePayType.PAY_ID,  //支付方式ID
                    Payment_Code:this.choicePayType.PAY_CODE, //支付方式编号
                    Payment_Name:this.choicePayType.PAY_NAME, //支付方式名称
                    Payment_Type:this.choicePayType.INTERFACE_TYPE, //支付方式类型
                    Payment_Money: Payment_Money, //支付金额
                    OverCharge_Money:OverChargeMoney,  //多收金额
                    Payment_FactMoney:0, //计营业额
                    Qr_Code:'', //扫码付款的码值
                    Card_No:'', //卡号
                    CouponInfo:_choice, //券的详情
                })
            })
            let _orderConsumes=this.$global.getFastOrderConsumes(this.orderMenuList);
            
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:'', //订单ID，当异常单重新处理时需要传入
                Timestamps:'',//订单单时间戳，当异常单重新处理时需要传入
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_PersonNum:this.headingInfo?.DinersNum,//人数
                Channel_ID:this.Channellike,//订单渠道ID
                Room_ID:this.RoomAutoID,//餐厅ID
                Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                Eat_DeskName:this.DeskName, //桌台名称
                Eat_TakeNo:this.headingInfo?.brandNumber,//牌号
                Eat_AllRemark:'', //订单全单备注
                Order_Remark:this.headingInfo.orderRemark,//订单备注
                Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                NotSendToKitPrint:false, //下单不送打厨房
                MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                //Member:'',//会员信息
                OrderConsumes:_orderConsumes, //订单消费详细
                Payments:_payment//支付方式
            }
            //当异常单重新处理时需要传入
            if(this.EatAutoID){
                param.Eat_AutoID=this.EatAutoID
                if(this.tmpTimestamps){
                    param.Timestamps=this.tmpTimestamps;
                }
            }
            /**支付预览 */
            const loading = this.$loading({
                text: "支付预览中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            console.log('param:'+JSON.stringify(param))
            this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                loading.close();
                console.log('data:'+JSON.stringify(data))
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(data.ResponseBody?.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    /**Is_NeedSMSCode：是否需要短信验证码0不需要1需要 */
                    if(data.ResponseBody.Is_NeedSMSCode==1){
                        console.log('短信验证')
                    }
                    /**Is_NeedPassWord：是否需要密码验证0不需要1需要 */
                    if(data.ResponseBody.Is_NeedPassWord==1){
                        console.log('密码验证')
                    }
                    /**支付提交判断 0不需要 1需要 */
                    if(data.ResponseBody.Is_NeedSubmit==1){
                        this.PaySubmit(data)
                    }else{
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        this.$emit('subMultipleConfirm',data.ResponseBody,this.selectCoupon);
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**支付提交接口 */
        PaySubmit(data){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: data.ResponseBody?.Eat_AutoID,//结账单ID
                Member_Type:data.ResponseBody?.Member_Type,  //会员类型
                Biz_ID:data.ResponseBody?.Biz_ID, //业务交易流
                MantissaAdjustMoney:data.ResponseBody?.MantissaAdjustMoney,//尾数调整金额
                Verify_Code:'', //验证码
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Timestamps:data.ResponseBody.Timestamps.toString(),
                Payments:data.ResponseBody.Payments
            }
            /**支付提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    if(data.ResponseBody.IsMultipleCardPay==false){
                        this.Payments = this.Payments.concat(data.ResponseBody.Payments)
                        this.isPaySubmit =1
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },100);
                        }
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        this.$message.success({ message: "支付完成"});
                        this.$emit('submiConfirm')
                    }else{
                        if(data.ResponseBody.IsMultipleCardPay==true){
                            this.$emit('subMultipleConfirm',data.ResponseBody);
                        }
                    }
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        cashConfirm(allNum){
            this.numShow=false
            this.$keyavail=false
            this.selectCoupon.forEach(tick=>{
                if(tick.newCoupon_Id==this.presentCoupon.newCoupon_Id){
                    if(!tick?.CouponNum){
                        tick = Object.assign(tick,{CouponNum:allNum})
                    }else{
                        tick.CouponNum=allNum
                    }
                    this.tickDeduMoney += tick.deDuMoney *(Number(tick.CouponNum)-1)
                }
            })
        },
        numCloseModel(){
            this.numShow=false
            this.$keyavail=false
            this.onTicket(this.presentCoupon)
            this.presentCoupon=''
        },
        /**数组排序 */
        compare(attr,Time) {
            return function(a,b){
                var val1 = a[attr];
                var val2 = b[attr];
                var Times1 = a[Time];
                var Times2 = b[Time];
                return val2 - val1 || Times1 - Times2;
            }
        }
    }
}
</script>

<style lang="sass">
@import "./yiMaCouponsPayModel.scss";
</style>