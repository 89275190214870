<template>
    <div class="fastFoodDetail">
        <div class="desk-box">
            <div class="panel">
                <div class="panel-body">
                    <div class="bnt-edit" @click="this.modiRiseShow=true,this.RiseKey=new Date().getTime()"><i class="iconfont icon-pencil-create"></i></div>
                    <div class="tr-li">
                        <div class="td-5" >
                            <i class="iconfont icon-zhuotai" @click="this.choiceDeskShow=true"></i>
                            <key-board-input class="deskName" :keyOptions="{isNumber:true,isKeyDownEnter:true}" isKeyEnterClose isBlurClose v-model="DeskName"/>
                        </div>
                        <div class="td-5" style="padding-left:20px;">
                           <div class="lable-txts">牌号:</div>
                           <div class="from-input fontBold">{{this.headingInfo.brandNumber}}</div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="td-5">
                            <div class="lable-txt">用餐渠道:</div>
                            <div class="channe-bnt from-input">
                                <span :class="{selected:item.OrderChannel_AutoID == Channellike}" 
                                    v-for="item in orderChannel" :key="item" @click="channeClick(item)">{{item.OrderChannel_Name}}</span>
                            </div>
                        </div>
                        <div class="td-5" style="padding-left:20px;">
                            <div class="lable-txtr">人数:</div>
                            <div class="from-input fontBold">{{this.headingInfo.DinersNum}}</div>
                        </div>
                    </div>
                    <div class="tr-li">
                        <div class="remak-5">
                            <div class="lable-txt">订单备注:</div>
                            <div class="from-input fontBold">{{this.headingInfo.orderRemark}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <order-menu-box class="panel panel-table flex-column-box" ref="orderMenu"/>
            <div class="prepaidclass" v-show="this.prepaidshow==true">
                <div class="pre-title">
                    <span class="pre-span">已付明细(合计{{this.paymentHistory.length}}笔,共<span style="color:red;font-size:18px;font-weight:600">￥{{Number(amountPaid).toFixed(2)}}</span>)</span>
                    <span class="pre-x" @click="this.prepaidshow=false">×</span>
                </div>
                <div style="height:250px;overflow-y:scroll;">
                    <div class="prepa-deta" v-for="(pay,index) in paymentHistory" :key="index">
                        <div class="dete-div">
                            <span class="span-pay">{{pay.Payment_Name}}</span>
                            <span class="span-Money">￥{{pay.Payment_Money}}</span>
                            <span class="span-delete" @click="deleteClick(index,pay)">
                                <i class="iconfont icon-shanchu"></i>
                            </span>
                        </div>
                        <div class="div-Oper">
                            <span>{{pay.Operator_Name}}</span>
                            <span class="span-time">{{pay.Time}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer orderFooter">
                <div class="float-left txt">
                    <div style="height:40%;">
                        <span class="money">
                            合计:{{ orderTotal.total.totalMoney }}
                        </span>
                        <span class="moneys">
                            <span class="my">应收:<span class="mytow">￥</span></span>
                            <span class="m">{{orderTotal.total.money}}</span>
                        </span><br/>
                    </div>
                    <div>
                        <span class="money">
                            共{{orderTotal.total.number}}份<span style="font-size:12px;color:#487BB1;left:8px;position:relative;">优惠:{{orderTotal.total.discountMoney}}</span>
                        </span>
                        <span class="moneys" style="left:10px;">
                            <span class="yf" @click="this.prepaidshow=true">已付</span>
                            <span class="yy">￥</span>
                            <span class="amount">{{Number(amountPaid).toFixed(2)}}</span>
                        </span><br/>
                    </div>
                </div>
                <div class="float-right" @mousedown="(e)=>{e.preventDefault()}">
                    <div class="bnt-fangxiang" @click="$refs.orderMenu.pageUp()"><i class="iconfont icon-fangxiangquan-top"></i></div>
                    <div class="bnt-fangxiang" @click="$refs.orderMenu.pageDown()"><i class="iconfont icon-fangxiangquan-bottom"></i></div>
                </div>
            </div>
        </div>
        <div class="operate-button">
            <div class="scroll scrollnot">
                <div class="button-group" v-if="QKOrderMain">
                    <button class="btn" v-for="(item,ProjectItem_Index) in QKOrderMain" :key="ProjectItem_Index" v-show="item.Is_Show"  @click="MiddleMenuClick(item)">
                        <div v-if="item.ProjectItem_Key=='Plus'"><b class="iconfont icon-jia"></b></div>
                        <div v-else-if="item.ProjectItem_Key=='Minus'"><b class="iconfont icon-jian"></b></div>
                        <div v-else>
                            {{item.ProjectItem_Name}}
                        </div>
                        <div v-if="item.ProjectItem_Key=='GetTemporaryStorage' && this.$cacheData.lockersCache.getLockersList().length>0">
                            <b class="b-Temporary">{{this.$cacheData.lockersCache.getLockersList().length}}</b>
                        </div>
                        <span class="shortKey" v-if="item.ProjectItem_ShortcutKey">{{item.ProjectItem_ShortcutKey}}</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="menu-box">
            <div class="div-box flex-column-box">
                <div class="panel food-main-box" style="overflow:inherit" v-if="orderMap_Type==2 ||orderMap_Type==1" :style="foodMainStyle">
                    <!-- 品项选择 -->
                    <categorys-page class="filter-nav panel" 
                        :data="foodMainCategory" 
                        :row="foodMainArg.row" 
                        :col="foodMainArg.col" 
                        :selectIndex="mainCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodMainPageIndex"
                        navFirstName="全部大类"
                        v-slot="{item}" @navClick="onFoodNav">
                        {{item.MainCategory_Name}}
                    </categorys-page>
                </div>
                <div class="panel food-sub-box" style="overflow:inherit" v-if="orderMap_Type==3 ||orderMap_Type==1" :style="foodSubStyle">
                    <categorys-page class="filter-nav panel" 
                        :data="foodSubCategorys" 
                        :row="foodSubArg.row" 
                        :col="foodSubArg.col" 
                        :selectIndex="subCategoryIndex"
                        :bottomH="setBottomH"
                        :pageIndex="foodSubPageIndex"
                        navFirstName="全部小类"
                        v-slot="{item}" @navClick="onFoodSubNav">
                        {{item.SubCategory_Name}}
                        <b v-show="subCategoryNumberKey[item.SubCategory_ID]>0">{{subCategoryNumberKey[item.SubCategory_ID]}}</b>
                    </categorys-page>
                </div>
                <div class="panel menu menu-flex-box" :style="menuStyle">
                    <div class="keyboard" v-if="this.$cacheData.Numberkey.getNumberKeyOff()==true">
                        <div class="key-li tr-li" >
                            <span @click="keyNumber(0,0)">0<p class="bubbing" v-show="orderNumber>=0 && orderNumberType==0 && orderNumber">{{orderNumber}}</p></span>
                            <span @click="keyNumber(1,1)">1<p class="bubbing" v-show="orderNumber>0 && orderNumberType==1">{{orderNumber}}</p></span>
                            <span @click="keyNumber(2,2)">2<p class="bubbing" v-show="orderNumber>0 && orderNumberType==2">{{orderNumber}}</p></span>
                            <span @click="keyNumber(3,3)">3<p class="bubbing" v-show="orderNumber>0 && orderNumberType==3">{{orderNumber}}</p></span>
                            <span @click="keyNumber(4,4)">4<p class="bubbing" v-show="orderNumber>0 && orderNumberType==4">{{orderNumber}}</p></span>
                            <span @click="keyNumber(5,5)">5<p class="bubbing" v-show="orderNumber>0 && orderNumberType==5">{{orderNumber}}</p></span>
                            <span @click="keyNumber(6,6)">6<p class="bubbing" v-show="orderNumber>0 && orderNumberType==6">{{orderNumber}}</p></span>
                            <span @click="keyNumber(7,7)">7<p class="bubbing" v-show="orderNumber>0 && orderNumberType==7">{{orderNumber}}</p></span>
                            <span @click="keyNumber(8,8)">8<p class="bubbing" v-show="orderNumber>0 && orderNumberType==8">{{orderNumber}}</p></span>
                            <span @click="keyNumber(9,9)">9<p class="bubbing" v-show="orderNumber>0 && orderNumberType==9">{{orderNumber}}</p></span>
                            <span @click="keyNumber('.')">.</span>
                            <span @click="keyNumber(-1)"><i class="iconfont icon-zuojiantou"></i></span>
                        </div>
                    </div>
                    <!-- 菜单 -->
                    <layout-rc class="item-box clearfix" :isVarCss="true" :row="arg.row" :col="arg.col" :marginW="2" :marginH="2" >
                        <div class="item-li" :class="{selected:item.Food_ID==choicePosFoods}" v-for="item in foodsPages" :key="item"  @click="onPlaceFoods(item)">
                            <div class="title">
                                <i class="iconfont icon-tesecai" v-show="sysSetting.RunOrderListShowSpecialFoodFlag && item.Is_Special"></i>{{item.Food_Name}}
                            </div>
                            <b v-show="getFoodNumber(item)>0">{{getFoodNumber(item)}}</b>
                            <div class="bottom-box" v-if="item.Is_Limited && item.Is_LimitedProject">
                                <div class="text-left" v-if="item.Is_Promotion || (item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price)">剩: <span class="red">{{item.RemainNum}}</span></div>
                                <div class="bottom-flex-box">
                                    <!-- 促销价 -->
                                    <div class="cxPrice" v-if="item.Is_Promotion">
                                        <div class="cxTag">促</div>
                                        <div class="unit">￥</div>{{item.Promotion_Price}}
                                    </div>
                                    <!-- vip 价格 -->
                                    <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                        <div class="vipTag">VIP</div>
                                        <div class="price"> 
                                            <div class="unit">￥</div>{{item.Food_VipPrice}}
                                        </div>
                                    </div>
                                    <div class="text-left" v-else>剩: <span class="red">{{item.RemainNum}}</span></div>
                                    <div class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</div>
                                </div>
                            </div>
                            <div class="bottom-box bottom-flex-box" v-else>
                                <!-- 促销价 -->
                                <div class="cxPrice" v-if="item.Is_Promotion">
                                    <div class="cxTag">促</div>
                                    <div class="unit">￥</div>{{item.Promotion_Price}}
                                </div>
                                <!-- vip 价格 -->
                                <div class="vipPrice" v-else-if="item.Food_VipPrice>0 && item.Food_VipPrice!=item.Food_Price">
                                    <div class="vipTag">VIP</div>
                                    <div class="price"> 
                                        <div class="unit">￥</div>{{item.Food_VipPrice}}
                                    </div>
                                </div>
                                <div class="text-right">￥{{item.Food_Price}}/{{item.Food_Size}}</div>
                            </div>

                            <tag-sold-out v-if="item.Is_SoldOut"></tag-sold-out>
                            <div class="tag tag-grey" v-if="!item.Is_SoldOut && item.Is_Limited && item.Is_LimitedProject && item.RemainNum==0">售罄</div>
                        </div>
                    </layout-rc>
                    <div class="search-box">
                        <key-board-input class="search"
                            type="text" v-model="varietySelect" ref="searchBox" placeholder="菜品模糊检索"
                            @keydown="keyCursor"
                            isBarcode @barcode="barcode"
                            :focusLock="true"
                            :keyOptions="{isKeyDownEnter:true}"
                        ></key-board-input>
                        <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                        <div class="page-box-bottom">
                            <span class="last-page iconfont icon-fangxiangquan-left" @click="posFoodsPage.pageIndex<=1?1:posFoodsPage.pageIndex--"></span>
                            <span class="page-number">{{ posFoodsPage.pageIndex }}/{{ posFoodsPage.pageSize }}页</span>
                            <span class="next-page iconfont icon-fangxiangquan-right" @click="posFoodsPage.pageIndex>=posFoodsPage.pageSize?posFoodsPage.pageSize:posFoodsPage.pageIndex++" ></span>
                        </div>
                    </div>
                </div>
                <div class="panel footer-flex-box" :style="footerStyle">
                    <layout-rc class="btn-box" :isVarCss="true" :list="payTypeMenuPages" :row="footerArg.row" :col="footerArg.col" :marginW="2" :marginH="2">
                        <div class="btn nowrap" v-for="(item,PAY_CODE) in payTypeMenuPages" :key="PAY_CODE" @click="payClick(item)">
                            {{item.PAY_NAME}}
                        </div>
                        <div class="page-box" v-if="payTypePagesCount>1">
                            <page-turning class="page-botton" v-model="payTypePageIndex" :pageCount="payTypePagesCount" type="up"><i class="iconfont icon-a-order-up"></i></page-turning>
                            <page-turning class="page-botton" v-model="payTypePageIndex" :pageCount="payTypePagesCount"><i class="iconfont icon-a-order-down"></i></page-turning>
                        </div>
                    </layout-rc>
                    <div class="CheckOut-btn" @click="invoicingConfirm()">
                        <div>结</div>
                        <div>账</div>
                    </div>
                </div>
            </div>
        </div>
        <modal-list @modalShow="modalShow" @modalClose="modalClose">
        <!-- 菜品要求操作 -->
        <modal-load :isShow="demandShow" :isUnload="true">
            <demand-model  :isShow="demandShow" v-on:closeModel="demandShow=false,BindPosFoods=''" :BindPosFoods="BindPosFoods" :allfoodSubCategorys="allfoodSubCategorys" :orderMenuList="orderMenuList" :foodtype="foodtype"  @confirm="confirm" @BindPosconfirm="BindPosconfirm" :masKindsList="masKindsList" :disRadioOrder="disRadioOrder" >
            </demand-model>
        </modal-load>
        <!-- 全单菜品要求操作 -->
        <modal-load :isShow="demandFullShow" :isUnload="true">
            <demand-full-model  :isShow="demandFullShow" v-on:closeModel="demandFullShow=false" @confirmall="confirmall" :orderMenuList="orderMenuList" :masKindsList="masKindsList" v-if="masKindsList!=null"></demand-full-model>
        </modal-load>
        <!-- 数量 -->
        <modal-load :isShow="adjusquantityShow">
            <adjust-quantity-model  :isShow="adjusquantityShow" v-on:closeModel="adjusquantityShow=false" :orderMenuList="orderMenuList" :disRadioclass="disRadioclass" :disRadioOrder="disRadioOrder"  @quantityReturn="quantityReturn" ></adjust-quantity-model>
        </modal-load>
        <!--辅助单位-->
        <modal-load :isShow="assSizeShow">
            <assist-Size-model  :isShow="assSizeShow" v-on:closeModel="assSizeShow=false" :assistSizeInfo="assistSizeInfo" :orderNumber="orderNumber" @assSizeConfirm="assSizeConfirm" ></assist-Size-model>
        </modal-load>
        <!-- 改价 -->
        <modal-load :isShow="changePriceShow">
            <fast-num-price-model  :isShow="changePriceShow" :isPrice="true" v-on:closeModel="changePriceShow=false,this.checkUser={}" :isCheckOut="false" :disRadioOrder="disRadioOrder" :orderInfo="orderInfo" @changepriceClick="changepriceClick" ></fast-num-price-model>
        </modal-load>
        <!--其他功能-->
        <modal-load :isShow="subShow">
            <sub-Item-Model  :isShow="subShow" v-on:closeModel="subShow=false" :otherFuncList="otherFuncList" :orderInfo="orderInfo" @subItemReturn="subItemReturn" ></sub-Item-Model>
        </modal-load>
        <!--菜品换单位-->
        <modal-load :isShow="unitShow">
            <change-Unit-Model  :isShow="unitShow" v-on:closeModel="unitShow=false" :multipleSizeFoods="theMultipleSizeFoods" @onPlaceFoods="onPlaceFoods" ></change-Unit-Model>
        </modal-load>
        <!-- 提取暂存 -->
        <modal-load :isShow="lockersShow">
            <lockers-model  :isShow="lockersShow" v-on:closeModel="lockersShow=false" @lockersConfirm="lockersConfirm" ></lockers-model>
        </modal-load>
        <!-- 赠送操作 -->
        <modal-load :isShow="giveShow" :isUnload="true">
            <give-return-model  :isShow="giveShow" :isReturn="false" v-on:closeModel="giveShow=false,this.checkUser={}" 
            :disRadioOrder="disRadioOrder" :recordFunctionCheck="recordFunctionCheck" :foodOperationRecord="foodOperationRecord" @GiveFoodFreeClick="GiveFoodFreeClick"
            :checkUser="checkUser"></give-return-model>
        </modal-load>
        <!--Ar支付-->
        <modal-load :isShow="arShow">
            <ar-account-Model  :isShow="arShow" v-on:closeModel="arShow=false" 
                :unPaidMoney="unPaidMoney" 
                :MantissaMoney="MantissaMoney" 
                :Channellike="Channellike" 
                :headingInfo="headingInfo" 
                :choicePayType="choicePayType"
                :EatAutoID="EatAutoID"
                :Timestamps="Timestamps"
                :RoomAutoID="RoomAutoID" :DeskName="DeskName"
                :orderMenuList="orderMenuList"
                @submiConfirm="submiConfirm"
                @subMultipleConfirm="subMultipleConfirm">
            </ar-account-Model>
        </modal-load>
        <!--正品卡券核销-->
        <modal-load :isShow="zpCouponShow" :isUnload="true">
            <zp-couponspay-model
                :isShow="zpCouponShow" 
                v-on:closeModel="zpCouponShow=false"
                :MantissaMoney="MantissaMoney" 
                :Channellike="Channellike" 
                :unPaidMoney="unPaidMoney"
                :RoomAutoID="RoomAutoID"
                :headingInfo="headingInfo" 
                :DeskName="DeskName"
                :choicePayType="choicePayType"
                :orderMenuList="orderMenuList"
                :EatAutoID="EatAutoID"
                :Timestamps="Timestamps"
                @setTimestamps="(time)=>Timestamps=time"
                @submiConfirm="submiConfirm"
                @subMultipleConfirm="subMultipleConfirm">
            </zp-couponspay-model>
        </modal-load>
        <!--翼码卡券核销-->
        <modal-load :isShow="ymCouponShow" :isUnload="true">
            <yi-ma-coupons-pay-model 
                :isShow="ymCouponShow" 
                v-on:closeModel="ymCouponShow=false"
                :MantissaMoney="MantissaMoney" 
                :Channellike="Channellike" 
                :unPaidMoney="unPaidMoney"
                :RoomAutoID="RoomAutoID"
                :headingInfo="headingInfo" 
                :DeskName="DeskName"
                :choicePayType="choicePayType"
                :orderMenuList="orderMenuList"
                :EatAutoID="EatAutoID"
                :Timestamps="Timestamps"
                @setTimestamps="(time)=>Timestamps=time"
                @submiConfirm="submiConfirm"
                @subMultipleConfirm="subMultipleConfirm"
            />
        </modal-load>
        <!-- 套餐 -->
        <modal-load :isShow="setMealShow">
            <set-Meal-model  :isShow="setMealShow" :isDemand="true" v-on:closeModel="setMealShow=false" :tcChangeType="tcChangeType" :disRadioOrder="disRadioOrder" :orderNumber="orderNumber" :posFoodSetMealInfos="posFoodSetMealInfos"  @setMealClick="setMealClick" > </set-Meal-model>
        </modal-load>
        <!--修改抬头-->
        <modal-load :isShow="modiRiseShow">
            <modi-Rise-model  :key="RiseKey" :isShow="modiRiseShow"  :headingInfo="headingInfo" :orderMenuList="orderMenuList"  
                :memberInfo="meberInfo"
                v-on:closeModel="modiRiseShow=false" @setDeskclose="setDeskclose"></modi-Rise-model>
        </modal-load>
        <!-- 商品券 或 现金券 选择 -->
        <modal-load :isShow="paymentCouponsShow" :isUnload="true">
            <payment-coupons-model  :isShow="paymentCouponsShow" 
            :data="payCouponsData" 
            :unPaidMoney="unPaidMoney" 
            :orderMenuList="orderMenuList" 
            :choicePayType="choicePayType" 
            :paymentHistory="paymentHistory"
            v-on:closeModel="paymentCouponsShow=false,this.choicePayType=[]"
            @payCouponsConfirm="payCouponsConfirm">
        </payment-coupons-model>
        </modal-load>
        <!-- 扫码付 -->
        <modal-load :isShow="scanCodePay" :isUnload="true">
            <scanCode-Pay-model :isShow="scanCodePay" 
                :orderMenuList="orderMenuList" 
                :payTypeSSPay="payTypeSSPay"
                :unPaidMoney="unPaidMoney" 
                :Channellike="Channellike"
                :headingInfo="headingInfo"
                :MantissaMoney="MantissaMoney"
                :paymentHistory="paymentHistory"
                :rechargeMoney="0"
                :EatAutoIDs="EatAutoID"
                :Timestamps="Timestamps"
                :RoomAutoID="RoomAutoID" :DeskName="DeskName"
                @paymentHistoryDelete="paymentHistoryDelete"
                @scanPaySuccessConfirm="scanPaySuccessConfirm"
                @ThePaymentResultClic="ThePaymentResultClic"
                @ThePaymentResultTime="ThePaymentResultTime"
                v-on:closeModel="scanCodePay=false,this.choicePayType=[]">
            </scanCode-Pay-model>
        </modal-load>
        <!-- 银石预付卡支付 -->
        <modal-load :isShow="yinShiYFPayShow" :isUnload="true">
            <fast-yin-shi-yu-fu-card-Pay-model :isShow="yinShiYFPayShow" 
                :orderMenuList="orderMenuList" 
                :payTypeSSPay="choicePayType"
                :unPaidMoney="unPaidMoney" 
                :Channellike="Channellike"
                :headingInfo="headingInfo"
                :MantissaMoney="MantissaMoney"
                :paymentHistory="paymentHistory"
                :rechargeMoney="0"
                :EatAutoIDs="EatAutoID"
                :Timestamps="Timestamps"
                :RoomAutoID="RoomAutoID" :DeskName="DeskName"
                @paymentHistoryDelete="paymentHistoryDelete"
                @scanPaySuccessConfirm="scanPaySuccessConfirm"
                @ThePaymentResultClic="ThePaymentResultClic"
                @ThePaymentResultTime="ThePaymentResultTime"
                v-on:closeModel="yinShiYFPayShow=false,this.choicePayType=[]"/>
        </modal-load>
        <!-- 银时智能pos 扫码付  -->
        <modal-load :isShow="yinShiPayShow" :isUnload="true">
            <fast-yin-shi-scanCode-Pay-model  :isShow="yinShiPayShow" 
                :orderMenuList="orderMenuList" 
                :payTypeSSPay="choicePayType"
                :unPaidMoney="unPaidMoney" 
                :Channellike="Channellike"
                :headingInfo="headingInfo"
                :MantissaMoney="MantissaMoney"
                :paymentHistory="paymentHistory"
                :EatAutoIDs="EatAutoID"
                :Timestamps="Timestamps"
                :RoomAutoID="RoomAutoID" :DeskName="DeskName"
                @paymentHistoryDelete="paymentHistoryDelete"
                @scanPaySuccessConfirm="scanPaySuccessConfirm"
                @ThePaymentResultClic="ThePaymentResultClic"
                @ThePaymentResultTime="ThePaymentResultTime"
                v-on:closeModel="yinShiPayShow=false,this.choicePayType=[]" />
        </modal-load>
        <!--会员支付-->
        <modal-load :isShow="memberPayShow">
            <member-payment-model  ref="com"
                :isShow="memberPayShow" :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @memberCloseModel="memberCloseModel" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic"
                @payMultipleConfirm="payMultipleConfirm" @updatePay="updatePay">
            </member-payment-model>
        </modal-load>
        <!--微生活会员支付-->
        <modal-load :isShow="wlivePayShow" isUnload>
            <wlive-member-payment-model 
                :isShow="wlivePayShow" :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @memberCloseModel="memberCloseModel" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic"
                @updatePay="updatePay">
            </wlive-member-payment-model>
        </modal-load>
        <!--新享会支付-->
        <modal-load :isShow="newEnjoyShow">
            <new-enjoy-show-model 
                :isShow="newEnjoyShow" :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @memberCloseModel="memberCloseModel" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic">
            </new-enjoy-show-model>
        </modal-load>
        <!--海鼎卡-->
        <modal-load :isShow="hdShow">
            <card-abutting-model 
                :isShow="hdShow" :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @memberCloseModel="memberCloseModel" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic"
                @discountSchemeClic="discountSchemeClic" @payMultipleConfirm="payMultipleConfirm">
            </card-abutting-model>
        </modal-load>
        <!-- 折扣方案 -->
        <modal-load :isShow="discountShow" :isUnload="true">
            <discount-model :isShow="discountShow"  
                v-on:closeModel="discountShow=false" 
                :orderMenuList="orderMenuList" :checkUser="checkUser" :discountSchemeList="discountScheme" :recordFunctionCheck="recordFunctionCheck" 
                :paymentHistory="paymentHistory" :disRadioOrder="disRadioOrder"
                :Running_ReadCardToDiscount_YN="Running_ReadCardToDiscount_YN"
                @discountSchemeClic="discountSchemeClic" @CancelDiscountConfirm="CancelDiscountConfirm">
            </discount-model>
        </modal-load>
        <!--选择桌号-->
        <modal-load :isShow="choiceDeskShow">
            <choice-Desk-Model  :isShow="choiceDeskShow" v-on:closeModel="choiceDeskShow=false" @deskclose="deskclose">
            </choice-Desk-Model>
        </modal-load>
        <!--付款弹窗-->
        <modal-load :isShow="paymentShow">
            <paymentlayer-model  :isShow="paymentShow" 
                :unPaidMoney="unPaidMoney" 
                :choicePayType="choicePayType" 
                :key="payMoneyKey"
                v-on:closeModel="paymentShow=false,this.choicePayType=[]"
                @cashConfirm="cashConfirm">
            </paymentlayer-model>
        </modal-load>
        <!--权限验证:disRadioOrder="disRadioOrder"-->
        <modal-load :isShow="checkShow" :isUnload="true">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" 
                :checkBtnlist="checkBtnlist" 
                :orderType="1"
            @checkbtnReturn="checkbtnReturn"></check-user-button-model>
        </modal-load>
        
        <!-- 卡券核销 -->
        <modal-load :isShow="couponVerifyShow" isUnload>
            <coupon-verify-model :isShow="couponVerifyShow"  @close="couponVerifyShow=false" 
                :RoomAutoID="RoomAutoID"
                :allfoodSubCategorys="allfoodSubCategorys" @confirm="couponsReturn" />
        </modal-load>
        <!--KLCOUPON 昆仑卡券核销-->
        <modal-load :isShow="klCouponShow" :isUnload="true">
            <kunlun-couponspay-model
                :isShow="klCouponShow" 
                v-on:closeModel="klCouponShow=false"
                :orderInfo="orderInfo" 
                :RoomAutoID="RoomAutoID"
                @KunLunCouponsReturn="couponsReturn">
            </kunlun-couponspay-model>
        </modal-load>
         <!-- 昆仑会员支付 -->
        <modal-load :isShow="kunlunPayShow">
            <fast-kun-lun-member-pay-model 
                :isShow="kunlunPayShow" 
                :payType="payType" 
                :snackPaidMoney="unPaidMoney" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @close="kunlunPayShow=false" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic" 
                @payMultipleConfirm="kunlunMultipleConfirm"
            />
        </modal-load>
        <!--大拈会员支付-->
        <modal-load :isShow="daNianPayShow">
            <fast-da-nian-pay-model 
                :isShow="daNianPayShow" :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :discountSchemeList="discountScheme" 
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @close="daNianPayShow=false" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic"
                @discountSchemeClic="discountSchemeClic" @payMultipleConfirm="payMultipleConfirm" />
        </modal-load>
        <!--G9会员卡支付-->
        <modal-load :isShow="gNinePayShow">
            <fast-g-payment-model
                :isShow="gNinePayShow" 
                :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @close="gNinePayShow=false" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic"
                @discountSchemeClic="discountSchemeClic" @payMultipleConfirm="payMultipleConfirm"/>
        </modal-load>
        <!-- 西软XMS储值支付  -->
        <modal-load :isShow="westStoredShow" isUnload>
            <fast-west-stored-model 
                :isShow="westStoredShow" 
                :snackPaidMoney="unPaidMoney" :payType="payType" :choicePayType="choicePayType" :orderMenuList="orderMenuList" 
                :MantissaMoney="MantissaMoney" :paymentHistory="paymentHistory" :EatAutoIDs="EatAutoID" :Timestamps="Timestamps"
                :headingInfo="headingInfo" :Channellike="Channellike" :RoomAutoID="RoomAutoID" :DeskName="DeskName" :totalMoney="totalMoney" :discountMoneuy="discountMoneuy"
                @memberCardBack="memberCardBack" @close="westStoredShow=false" @paySuccessConfirm="paySuccessConfirm" @ThePaymentResultClic="ThePaymentResultClic"
                @discountSchemeClic="discountSchemeClic" @payMultipleConfirm="payMultipleConfirm"/>
        </modal-load>
        <!--西软卡券核销-->
        <modal-load :isShow="westStoredCouponShow" isUnload>
            <fast-west-soft-couponspay-model
                :isShow="westStoredCouponShow" 
                @closeModel="westStoredCouponShow=false"
                :MantissaMoney="MantissaMoney" 
                :Channellike="Channellike" 
                :unPaidMoney="unPaidMoney"
                :RoomAutoID="RoomAutoID"
                :headingInfo="headingInfo" 
                :DeskName="DeskName"
                :choicePayType="choicePayType"
                :orderMenuList="orderMenuList"
                :EatAutoID="EatAutoID"
                :Timestamps="Timestamps"
                @setTimestamps="(time)=>Timestamps=time"
                @submiConfirm="submiConfirm"
                @subMultipleConfirm="subMultipleConfirm"
            />
        </modal-load>
        <!-- 组合菜品 -->
        <combo-food-model :isShow="isComboFoodShow" 
            :data="comboFoodInfo" 
            :editInfo="editComboFoodInfo"
            :posFoodData="getPosFoodData" 
            @close="isComboFoodShow=false"
            @confirm="comboConfirm"/>
        </modal-list>
        <!-- 取餐叫号 -->
        <modal-load :isShow="callCheckShow" isUnload>
            <call-check-num-model 
                :isShow="callCheckShow" @close="callCheckShow=false" />
        </modal-load>
        <!-- BankPos操作 -->
        <modal-load :isShow="bankPosShow">
            <bank-pos-model :isShow="bankPosShow" @close="bankPosShow=false;"/>
        </modal-load>
        <!-- 扫码订单核销 -->
        <modal-load :isShow="scanOrderShow" isUnload>
            <scan-code-order-model :isShow="scanOrderShow" @close="scanOrderShow=false"/>
        </modal-load>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </div>
</template>
<script>

import { initKeyBoard,newGuid,NumberConversion } from '/src/common' 
import { cacheData } from '/src/services'
import { ElMessage } from "element-plus";

import DemandModel from '../diningDetail/demandModel/demandModel.vue'
import adjustQuantityModel from '../diningDetail/adjustQuantityModel/adjustQuantityModel.vue'
import assistSizeModel from '../diningDetail/assistSizeModel/assistSizeModel.vue'
import subItemModel from '../diningDetail/subItemModel/subItemModel.vue'
import fastNumPriceModel from './fastNumPriceModel/fastNumPriceModel.vue'
import changeUnitModel from '../diningDetail/changeUnitModel/changeUnitModel.vue'
import DemandFullModel from '../diningDetail/demandFullModel/demandFullModel.vue'
import lockersModel from '../diningDetail/lockersModel/lockersModel.vue'
import modiRiseModel from './modiRiseModel/modiRiseModel.vue'
import setMealModel from '../diningDetail/setMealModel/setMealModel.vue'
import giveReturnModel from './giveReturnModel/giveReturnModel.vue'
import arAccountModel from './arAccountModel/arAccountModel.vue'
import scanCodePayModel from './scanCodePayModel/scanCodePayModel.vue'
import MemberPaymentModel from './memberPaymentModel/memberPaymentModel.vue'
import discountModel from './discountModel/discountModel.vue'
import choiceDeskModel from './choiceDeskModel/choiceDeskModel.vue'
import paymentlayerModel from './paymentlayerModel/paymentlayerModel.vue'
import wliveMemberPaymentModel from './wliveMemberPaymentModel/wliveMemberPaymentModel.vue'
import paymentCouponsModel from './paymentCouponsModel/paymentCouponsModel.vue'
import newEnjoyShowModel from './newEnjoyShowModel/newEnjoyShowModel.vue'
import cardAbuttingModel from './cardAbuttingModel/cardAbuttingModel.vue'
import checkUserButtonModel from '../../checkUserRightModel/checkUserButtonModel.vue'
import zpCouponspayModel from './zpCouponspayModel/zpCouponspayModel.vue'
import yiMaCouponsPayModel from './yiMaCouponsPayModel/yiMaCouponsPayModel.vue'
import kunlunCouponspayModel from './kunlunCouponspayModel/kunlunCouponspayModel.vue'
import fastKunLunMemberPayModel from './fastKunLunMemberPayModel/fastKunLunMemberPayModel.vue'
import comboFoodModel from '../diningDetail/comboFoodModel/comboFoodModel.vue'
import orderMenuBox from './orderMenuBox.vue'
import fastDaNianPayModel from './fastDaNianPayModel/fastDaNianPayModel.vue'
import fastGPaymentModel from './fastGPaymentModel/fastGPaymentModel.vue'
import callCheckNumModel from './callCheckNumModel/callCheckNumModel.vue'
import fastYinShiScanCodePayModel from './fastYinShiScanCodePayModel/fastYinShiScanCodePayModel.vue'
import couponVerifyModel from './couponVerifyModel/couponVerifyModel.vue'
import bankPosModel from '../diningDetail/bankPosModel/bankPosModel.vue'
import scanCodeOrderModel from './scanCodeOrderModel/scanCodeOrderModel.vue'
import fastYinShiYuFuCardPayModel from './fastYinShiYuFuCardPayModel/fastYinShiYuFuCardPayModel.vue'
import fastWestStoredModel from './fastWestStoredModel/fastWestStoredModel.vue'
import fastWestSoftCouponspayModel from './fastWestSoftCouponspayModel/fastWestSoftCouponspayModel.vue'

/**全局初始化数据 */
let _data={
    /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
    orderMap_Type:1,
    arg:{row:6,col:6,marginW:'6',marginH:'6'},
    args:{row:3,col:6,marginW:'8',marginH:'8'},
    MergeSameFoodInOrder:false,/**点单时,相同菜品自动合并一行 */
    FastFoodMantissaAdjustKind:0,/**快餐尾数调整方式*/
    RunFastFoodMustChooseDeskNo:false,/**快餐业态,下单必选桌号 */
    RunFastFoodWithSignCard:false,/**快餐业态启用牌号功能 */
    Running_DtFoodCard_YN:false,/**是否启用读卡点菜功能*/
    Running_ReadCardToDiscount_YN:false,//是否开启读卡全单打折， true:开启 false:未开启
};


export default {
    name:"fastFoodDetail",
    components:{
        DemandModel,
        adjustQuantityModel,
        assistSizeModel,
        subItemModel,
        fastNumPriceModel,
        changeUnitModel,
        DemandFullModel,
        lockersModel,
        modiRiseModel,
        setMealModel,
        giveReturnModel,
        arAccountModel,
        scanCodePayModel,
        MemberPaymentModel,
        discountModel,
        choiceDeskModel,
        paymentlayerModel,
        wliveMemberPaymentModel,
        paymentCouponsModel,
        newEnjoyShowModel,
        cardAbuttingModel,
        checkUserButtonModel,
        zpCouponspayModel,
        yiMaCouponsPayModel,
        kunlunCouponspayModel,
        fastKunLunMemberPayModel,
        comboFoodModel,
        orderMenuBox,
        fastDaNianPayModel,
        fastGPaymentModel,
        callCheckNumModel,
        fastYinShiScanCodePayModel,
        couponVerifyModel,
        bankPosModel,
        scanCodeOrderModel,
        fastYinShiYuFuCardPayModel,
        fastWestStoredModel,
        fastWestSoftCouponspayModel
    },
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting){
            _data.orderMap_Type = globalSysSetting.OrderMap_Type;
            _data.arg={row:globalSysSetting.OrderMap_X,col:globalSysSetting.OrderMap_Y,marginW:'6',marginH:'6'};
            _data.args={row:3,col:6,marginW:'8',marginH:'8'};
            _data.MergeSameFoodInOrder = globalSysSetting.MergeSameFoodInOrder;
            _data.FastFoodMantissaAdjustKind = globalSysSetting.FastFoodMantissaAdjustKind,
            _data.RunFastFoodMustChooseDeskNo = globalSysSetting.RunFastFoodMustChooseDeskNo,
            _data.RunFastFoodWithSignCard = globalSysSetting.RunFastFoodWithSignCard,
            _data.Running_DtFoodCard_YN=globalSysSetting.Running_DtFoodCard_YN;
            _data.Running_ReadCardToDiscount_YN=globalSysSetting.Running_ReadCardToDiscount_YN
        }
        return {
            demandShow:false,
            adjusquantityShow:false,
            assSizeShow:false,
            subShow:false,
            changePriceShow:false,
            unitShow:false,
            demandFullShow:false,
            lockersShow:false,
            modiRiseShow:false,
            setMealShow:false,
            giveShow:false,
            arShow:false,
            scanCodePay:false,
            //银石预付卡支付 弹层
            yinShiYFPayShow:false,
            //银时智能pos支付 弹层
            yinShiPayShow:false,
            memberPayShow:false,
            cardPriceShow:false,
            discountShow:false,
            choiceDeskShow:false,
            paymentShow:false,
            wlivePayShow:false,
            paymentCouponsShow:false,
            newEnjoyShow:false,
            hdShow:false,
            zpCouponShow:false,/**正品卡券弹层 */
            ymCouponShow:false,/**翼码卡券弹层 */
            klCouponShow:false,/**昆仑卡券弹层 */
            /**昆仑会员卡弹层 */
            kunlunPayShow:false,
            daNianPayShow:false,//大拈会员支付
            gNinePayShow:false,//G9 会员支付
            westStoredShow:false,//西软XMS储值支付
            westStoredCouponShow:false,//西软卡券核销
            RiseKey:'',
            payMoneyKey:'',
            //大类 样式
            foodMainStyle:{},
            foodMainArg:{row:2,col:8},
            //小类 样式
            foodSubStyle:{},
            foodSubArg:{row:2,col:8},
            //横排底部按钮样式
            footerStyle:{},
            footerArg:{row:1,col:8},
            menuStyle:{},
            /**点菜界面竖排自定义按钮 */
            QKOrderMain:[],
            //底部支付按钮 当前页码 
            payTypePageIndex:1,
            /*点菜单 列表是否展开 */
            isChecOutOpen:false,
            /**大类数据 */
            foodMainCategory:[],
            //大类分页 当前页码 
            foodMainPageIndex:1,
            mainCategoryIndex:0,
            /**小类数据 */
            foodSubCategorys:[],
            //小类分页 当前页码 
            foodSubPageIndex:1,
            /**全部小类数据 */
            allfoodSubCategorys:[],
            subCategoryIndex:0,
            //菜品数据 
            posFoodData:{},
            /**菜品数据  */
            posFoods:[],
            /**菜品页码 */
            posFoodsPage:{
                pageIndex:1,
                pageSize:1
            },
            /**选择的菜品 */
            choicePosFoods:'',
             /**辅助菜品 */
            assistSizeInfo:Object,
            /**套餐菜品操作默认0 选择套餐,1.未下单套餐退菜 */
            tcChangeType:0,
            /**对应套餐数据 */
            posFoodSetMealInfos:[],
            /**下单菜品数据 */
            arg:_data.arg,
            args:_data.args,
            money:'',
            checOutShow:'height:calc(100% - 100px - 5px)',
            isCheckOutclass:'panel-body',
            disRadioclass:-1, //是否选中
            foodSetmealsclass:-1, //套餐里的菜品选中
            foodtype:0,
            disRadioOrder:'',  //选中的菜品
            discountScheme:'', //折扣方案
            masKindsList:'', //菜品口味
             /**菜品组合信息 */
            comboFoodList:[],
            /**要修改的组合菜品 信息 */
            editComboFoodInfo:null,
            /**菜品组合 弹层 */
            isComboFoodShow:false,
            //选中的菜品 组合数据
            comboFoodInfo:null,
            //取餐叫号弹层
            callCheckShow:false,
            //卡券核销弹层
            couponVerifyShow:false,
            /***商品券 或 现金券 显示数据 */
            payCouponsData:{},
            /**用户数据 */
            userInfo:'',
            /**订单数据 */
            orderInfo:{},
            /**订单菜单集合 */
            orderMenuList:[],
            Temporaryorder:[],
            /**临时存放订单菜品的集合 */
            TemporderMenuList:[],
            /**返回的全单菜品做法口味选择 */
            masKinds:[],
            /**支付类型 */
            PayKinds:[],
            payType:[],
            /**选中的支付id */
            ispayid:0,//
            /**选中的支付类型 */
            choicePayType:[],
            /**聚合支付类型 */
            payTypeSSPay:[],
            /**菜品检索 */
            varietySelect:'',
            //记录上一次检索 不记录则按菜品检索
            oldVarietySelect:"",
            /**用户姓名 */
            Login_Name:'',
            /**点菜界面类别显示方式 1：大类+小类，2：大类，3：小类 */
            orderMap_Type:_data.orderMap_Type,
            /**多规格菜品 */
            multipleSizeFoods:[],
            theMultipleSizeFoods:'',
            /**其他功能按钮 */
            otherFuncList:'',
            readyShowType:0,
            /**是否启用了会员价 */
            carPriceType:false,//是否开启会员价
            /**套餐内菜品选择 */
            choicefoodOrder:'',
            /**桌台 */
            DeskName:'',
            /**餐厅数据 */
            PosRooms:[],
            /**餐厅ID */
            RoomAutoID:'',
            /**会员信息 */
            cardMemberList:'',
            /**绑定做法菜品 */
            BindPosFoods:'',
            /**权限验证临时保存ID和操作员 */
            tempUserId:'',
            tempOperatorName:'',
            prepaidshow:false,
            orderChannel:[], /**用餐渠道 */
            ChanneList:[],
            Channellike:'',/**选择的渠道 */
            DefaultChannelID:'',
            // totalMoney:0,/**合计金额 */
            // unPaidMoney:0,/**未付金额 */
            // MantissaMoney:0, /***尾数调整金额 */
            // discountMoneuy:0,/**折扣的优惠金额 */
            //countNumber:0,/**总份数 */
            headingInfo:{
                DeskName:'',
                DeskAutoID:'',
                RoomAutoID:'',
                DinersNum:1,
                orderChannelName:'',
                OrderChannel_AutoID:'',
                brandNumber:'',
                orderRemark:''
            }, /**修改台头返回 */
            eatCheckIndex:'',/**结账单号 */
            paymentHistory:[], /**支付记录保存 */
            payinvoicing:'', /**结账 */
            EatAutoID:'', /**订单ID，当单据重新处理时需要传入 */
            Timestamps:'',/**订单单时间戳 */
            meberInfo:null,/**会员信息 */
            /**结账界面横排自定义功能按钮 */
            userCheckOutMenu:[],
            foodOperationRecord:[],/**菜品操作记录 */
            IsMultipleCardPay:false, /**是否触发多卡支付 */
            mallMethod:[], //全单口味做法
            aloneMethod:[], //单独口味做法
            MethodNumList:[],//同步临时保存的口味做法数量
            orderNumber:'',//点单界面数量
            orderNumberType:-1,
            wgNum:0,//称重
            recordFunctionCheck:'', //记录选择的功能按钮
            merCouponFood:[],//使用的商品券和支付记录直接的对应关系记录.退款冲销使用
            /**权限弹框 */
            checkShow:false,
            checkBtnlist:'',
            checkUser:{},
            Running_ReadCardToDiscount_YN:_data.Running_ReadCardToDiscount_YN,
            //ai检索 多个
            aiSearchText:[],
            //是否启用ai识别点菜
            isAiEnable:false,
            /**BankPos操作*/
            bankPosShow:false,
            /**扫码订单核销 */
            scanOrderShow:false,
        }
    },
    computed:{
        sysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting
        },
        //全部菜品
        foodAll(){
            let all=[];
            this.getPosFoodData?.forEach(category=>{
                category?.FoodSubCategorys?.forEach(sub=>{
                    sub?.PosFoods?.forEach(it=>{
                        all.push(it);
                    })
                })
            })
            return all;
        },
        /**菜品分页显示 */
        foodsPages(){
            let size=this.arg.row*this.arg.col;
            return this.posFoods?.slice((this.posFoodsPage.pageIndex-1)*size,this.posFoodsPage.pageIndex*size);
        },
        getPosFoodData(){
            return this.posFoodData.data||[];
        },
        /**点单底部按钮 分页显示 */
        payTypeMenuPages(){
            let size=this.footerArg.row*this.footerArg.col;
            if(this.payTypePagesCount>1){
                size=size-1;
            }
            return this.payType?.slice((this.payTypePageIndex-1)*size,this.payTypePageIndex*size);
        },
        /**点单底部按钮 总页数 */
        payTypePagesCount(){
            let size=this.footerArg.row*this.footerArg.col;
            let num=1;
            if(this.payType?.length>size){
                size=size-1;
                num=Math.ceil(this.payType.length/size);
            }
            return num;
        },
        //判断是否有弹出层出现
        isModalShow(){
            if(this.demandShow || this.demandFullShow || this.demandFullShow || this.assSizeShow 
                || this.changePriceShow || this.subShow || this.unitShow || this.lockersShow 
                || this.giveShow || this.arShow || this.setMealShow || this.modiRiseShow 
                || this.paymentCouponsShow || this.scanCodePay || this.memberPayShow || this.wlivePayShow 
                || this.discountShow || this.choiceDeskShow || this.paymentShow || this.adjusquantityShow
                || this.newEnjoyShow || this.hdShow || this.isComboFoodShow || this.kunlunPayShow ||this.daNianPayShow || this.gNinePayShow
                || this.callCheckShow || this.yinShiPayShow || this.couponVerifyShow || this.bankPosShow || this.scanOrderShow
                || this.yinShiYFPayShow || this.westStoredShow || this.westStoredCouponShow
            ){
                return true;
            }
            return false;
        },
        /**判断 支付弹层是否显示 */
        isPayMoadlShow(){
            if(this.arShow || this.scanCodePay || this.memberPayShow || this.newEnjoyShow 
                || this.wlivePayShow || this.hdShow || this.paymentCouponsShow ||  this.paymentShow || this.yinShiPayShow
                || this.yinShiYFPayShow
            ){
                return true;
            }
            return false;
        },
        /**点单菜品的数量 未下单的菜品 */
        foodNumberKey(){
            let data={};
            this.orderMenuList?.filter(it=>!it.is_taste)?.forEach(it=>{
                if(data[it.EAT_XFBMID]){
                    data[it.EAT_XFBMID]=Number((data[it.EAT_XFBMID]+Number(it.Eat_Number)).toFixed(2))
                }else{
                    data[it.EAT_XFBMID]=Number(it.Eat_Number);
                }
                it.FoodSetmeals?.forEach(item=>{
                    if(data[item.FoodSetmeal_ID]){
                        data[item.FoodSetmeal_ID]=Number((data[item.FoodSetmeal_ID]+Number(item.FoodSetmeal_Number)).toFixed(2))
                    }else{
                        data[item.FoodSetmeal_ID]=Number(item.FoodSetmeal_Number);
                    }
                })
            })
            return data;
        },
        /**小类下 点单菜品的数量 未下单的菜品 */
        subCategoryNumberKey(){
            let subCategory={};
            this.foodSubCategorys?.forEach(it=>{
                let number=0;
                it.PosFoods?.forEach(item=>{
                    if(this.foodNumberKey[item.Food_ID]>0){
                        number+=this.foodNumberKey[item.Food_ID];
                    }

                    item.MultipleSizeFoods?.forEach(it=>{
                        if(this.foodNumberKey[it.Food_ID]>0){
                            number+=this.foodNumberKey[it.Food_ID];
                        }
                    })
                })
                if(number>0){
                    number=Number(number.toFixed(2));
                    subCategory[it.SubCategory_ID]=number;
                }
            })
            return subCategory;
        },
        /**已付金额 */
        amountPaid(){
            let totalMoney=0;
            this.paymentHistory?.forEach(pay=>{
                totalMoney += Number(pay?.Payment_Money);
            })
            return Number(totalMoney.toFixed(4));
        },
        //合计订单金额
        totalOrderMoney(){
            let total={
                totalMoney:0,//结账总金额
                unPaidMoney:0,//应收金额
                MantissaMoney:0,//位数调整金额
                countNumber:0,//菜品份数
                discountMoneuy:0,//优惠金额
            };
            let sumMoney=0;//销售总金额
            if(this.orderMenuList?.length>0){
                this.orderMenuList.forEach(order=>{
                    if(order.isPromotion2){//第二份折扣
                        total.totalMoney += Number(Number(order.zkPromotionPrice * Number(order.Eat_Number)))
                    }else{
                        total.totalMoney += Number(Number(order.Eat_CheckPrice * Number(order.Eat_Number)))
                    }
                    
                    sumMoney+=Number(order.Eat_SalePrice||0) * Number(order.Eat_Number||0);
                    total.countNumber += Number(order.Eat_Number||0);
                    if(order?.FoodSetmeals){
                        order.FoodSetmeals.forEach(food=>{
                            total.totalMoney += Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number);
                            sumMoney+=Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number);
                        })
                    }
                    
                })
                
                total.totalMoney = Number(total.totalMoney.toFixed(2))
                total.countNumber=Number(total.countNumber.toFixed(4));
                if(this.$refs.orderMenu?.fullReductionMoney>0){//计算满减
                    total.totalMoney=Number((total.totalMoney-this.$refs.orderMenu?.fullReductionMoney).toFixed(2));
                }
                sumMoney=Number(sumMoney.toFixed(2));
                total.discountMoneuy= Number((sumMoney-total.totalMoney).toFixed(2));//优惠金额
            }
            //计算尾数调整
            total.unPaidMoney=this.$global.mantissaAdjust(_data.FastFoodMantissaAdjustKind,total.totalMoney);
            //尾数调整金额 = 应收 - 结账总金额
            total.MantissaMoney=Number((total.unPaidMoney-total.totalMoney).toFixed(4));

            //应收-已支付金额
            total.unPaidMoney=Number((total.unPaidMoney-this.amountPaid).toFixed(2));

            if(total.unPaidMoney<=0){
                total.unPaidMoney=0
            }
            //this.money = total.unPaidMoney;

            return total;
        },
        /**订单信息 + 金额合计 显示用*/
        orderTotal(){
            return {
                bill:JSON.parse(JSON.stringify(this.orderMenuList)),//订单菜品信息 
                total:{
                    totalMoney:Number(this.totalOrderMoney.totalMoney + this.totalOrderMoney.discountMoneuy).toFixed(2),//合计金额
                    number:this.totalOrderMoney.countNumber,//多少份
                    discountMoney:Number(this.totalOrderMoney.discountMoneuy+(this.totalOrderMoney.MantissaMoney * -1)).toFixed(2),//优惠
                    money:this.totalOrderMoney.unPaidMoney.toFixed(2)//应收
                }
            }
        },
        /**合计金额 */
        totalMoney(){
            return this.totalOrderMoney.totalMoney;
        },
        /**未付金额 */
        unPaidMoney(){
            return this.totalOrderMoney.unPaidMoney;
        },
        /***尾数调整金额 */
        MantissaMoney(){
            return this.totalOrderMoney.MantissaMoney;
        }, 
        /**折扣的优惠金额 */
        discountMoneuy(){
            return this.totalOrderMoney.discountMoneuy;
        },
        /**总份数 */
        countNumber(){
            return this.totalOrderMoney.countNumber;
        },
    },
    created(){
       document.addEventListener("keydown",this.keydown)
    },
    /**当前位置的组件将要离开时触发 */
    beforeRouteLeave(to, from, next) {
        if(to.params?.isForced){//强制离开
            next();
            return ;
        }
        if(this.orderMenuList.length>0){
            this.$confirm('存在点单的菜品,确定离开快餐页面吗?', '提示', {
                type: 'warning',
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: (name) => {
                    if(name=='confirm'){
                        next();
                    }else{
                        this.$emit("navIndex",0);
                    }
                }
            })
        }else{
            next();//执行离开
        }
    },
    mounted(){
        this.$emit("navIndex",0);
        this.$nextTick(()=>{
            setTimeout(() => {
                 this.$refs.searchBox?.focus();
            },100);
            //this.$refs.searchBox.focus();
        })
        this.userInfo=this.$auth.getUserInfo(); //获取用户id
        this.Login_Name=this.userInfo.Login_Name; //用户姓名
        
        this.$cacheData.lockersCache.getLockersList(480)
        this.$cacheData.UserRooms().then((d)=>{
            this.PosRooms = d
            this.thefirstcanteenID();
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到桌台数据', "提示", {confirmButtonText: "确定"});
        })
        this.$cacheData.DeskPosconfigure().then((d)=>{
            this.orderMap_Type=_data.orderMap_Type = d.OrderMap_Type;
            this.args=_data.args={row:3,col:6,marginW:'8',marginH:'8'};
            _data.Running_DtFoodCard_YN=d.Running_DtFoodCard_YN;
            d.DisplayingConfigs?.forEach((it)=>{
                let style={};
                if(it.ScreenPx>0){
                    style["height"]=it.ScreenPx+"%";
                }
                if(it.Button_Color){
                    style["--bgColor"]=it.Button_Color;
                }
                if(it.Font_Name){
                    style["--fontFamily"]=it.Font_Name;
                }
                if(it.Font_Size){
                    style["--fontSize"]=it.Font_Size+"px"
                }
                if(it.Font_Color){
                    style["--fontColor"]=it.Font_Color
                }
                let arg={row:it.IntRow,col:it.IntCol};
                if(it.Config_Kind==11){//菜品大类显示类型
                    this.foodMainArg=arg;
                    this.foodMainStyle=style;
                }else if(it.Config_Kind==12){//为菜品小类显示类型   
                    this.foodSubArg=arg;
                    this.foodSubStyle=style;
                }else if(it.Config_Kind==13){//为菜品显示类型   
                    this.arg=_data.arg={row:it.IntRow,col:it.IntCol,marginW:'6',marginH:'6'};
                    this.menuStyle=style;
                    //this.foodMainArg=arg; 
                    //this.foodMainStyle=style;
                }else if(it.Config_Kind==14){//为功能键显示类型   
                    this.footerArg=arg;
                    style["--fontSize"]=it.Font_Size+"px"
                    this.footerStyle=style;
                }
            })
        }).catch(()=>{
            //this.$alert("基本配置获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        //菜品数据结构
        this.$cacheData.PosFoods().then((d)=>{
           this.posFoodData=d;
        }).catch(e=>{
            console.log('e:'+e)
            this.$alert('未找到菜品数据', "提示", {confirmButtonText: "确定"});
        })
        /* 获取支付方式 */
        this.$cacheData.PayType().then((data)=>{
            this.PayKinds = data
            /**取出聚合支付 **/
            data.forEach(item=>{
                item.PayTypes.forEach(pay=>{
                    //快餐排除G9付款方式和预付金押金付款
                    if(pay.INTERFACE_TYPE!='9026001' && pay.INTERFACE_TYPE!='11'){
                        this.payType.push(pay)
                    }
                    if(pay.INTERFACE_TYPE==801010301){
                        this.payTypeSSPay = pay;
                    }
                })
            })
        }).catch(e=>{
            this.$alert("支付方式获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        
        /**折扣列表*/
        this.$cacheData.DiscountScheme().then((d)=>{
            this.discountScheme = d;
        }).catch((e)=>{
            this.$alert("折扣数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })
        /**菜品口味 */
        this.$cacheData.MasKinds().then((d)=>{
            this.masKindsList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })

        /**菜品组合信息 */
        this.$cacheData.ComboFood().then((d)=>{
            this.comboFoodList = d;
        }).catch(e=>{
            this.$alert("菜品口味数据获取失败："+e.message, "提示", {confirmButtonText: "确定"});
        })

        /**自定义功能按钮 */
        let SysProjects=this.$cacheData.SysProjects.get();
        if(SysProjects && SysProjects.length>0){
            SysProjects.forEach(element=> {
                if(element.Project_Key=='Order'){
                    element.SysProjectGroups.forEach(project=>{
                        /**点菜界面竖排自定义功能按钮 */
                        if(project.Group_Key=='QKOrderMain-Middle'){
                            project.SysProjectItems.forEach((item)=>{
                                let Menu=this.QKOrderMain[item.ProjectItem_Key];
                                if(Menu==null ||Menu=='undefined'){
                                    if(project.OtherFunc_YN==false){
                                        if(project.OtherFunc_Key!=item.ProjectItem_Key){
                                            this.QKOrderMain.push(item);
                                        }
                                    }else{
                                        this.QKOrderMain.push(item);
                                    }
                                }
                            })
                        }
                        /**结账界面横排自定义功能按钮 */
                        if(project.Group_Key=='CheckOut-Bottom'){
                            project.SysProjectItems.forEach(item=>{
                                let Menu=this.userCheckOutMenu[item.ProjectItem_Key];
                                if(Menu==null ||Menu=='undefined' ){
                                    this.userCheckOutMenu.push(item);
                                }
                            })
                        }
                    })
                }
            });
        }
        this.DefaultChannelID = this.userInfo?.Default_ChannelID
        /**默认渠道 */
        this.orderChannel=[]
        this.$cacheData.OrderChannel().then((d)=>{
            this.ChanneList=[]
            d.map(order=>{
                order.OrderChannelList.map(item=>{
                    this.ChanneList.push(item)
                })
            })
            this.OrderChannelUpdate()
          }).catch(()=>{
              this.$alert('暂无订单渠道', "提示", {confirmButtonText: "确定"});
          })
        if(this.$route.params.data){
            this.abnormalOrder(JSON.parse(this.$route.params.data))
        }
        if(_data.RunFastFoodMustChooseDeskNo==true){
            this.choiceDeskShow=true
        }

        //监听 ai识别结果
        this.$webBrowser.on("openYoyoAi",(data)=>{
            console.log("isAiEnable:",this.isAiEnable,"openYoyoAi",data);
            if(data && (data.status==0 || data.state==0)){
                if(this.isAiEnable && data.data){
                    try {
                        let itemList=JSON.parse(data.data);
                        if(itemList.length>0){
                            this.aiSearchText=itemList.map(it=>it.itemCode);
                        }
                    } catch (error) {
                        console.log("openYoyoAi 解析data报错",error)
                    }
                }
            }
        });
        
        //是否开启AI识别 Running_AI_Recognition
        if(this.sysSetting.Running_AI_Recognition){
            console.log("读取AI识别功能配置")
            this.$webBrowser.getAiConfig().then(d=>{//AI识别功能
                console.log("getAiConfig",d)
                if(d && d.isEnable==true){
                    console.log("openYoyoAi")
                    //开启识别
                    this.$webBrowser.openYoyoAi().then(d=>{
                        if(d && (d.status==0 || d.state==0)){
                            this.isAiEnable=true;
                            console.log("AI开启识别");
                        }else{
                            this.$message.error("AI识别开启失败:"+d?.message);
                        }
                    })
                }
            })
        }
        //注册快捷键
        this.$shortcutKey.setFun((e,KeyBoard)=>{
            //是否锁屏了
            if(this.$global.isLockScreen || this.isModalShow)return; 
            this.QKOrderMain.find(it=>{
                if(it.ProjectItem_ShortcutKey==KeyBoard){
                    this.MiddleMenuClick(it);
                    return true;
                }else if(it.ProjectItem_Key== "OtherFunc"){
                    let item=it.SysProjectSubItems?.find(item=>item.ProjectSubItem_ShortcutKey==KeyBoard);
                    if(item){
                        this.subItemReturn(item);
                        return true;
                    }
                }
                return false;
            })
            if(e.key=="+" || e.code=="NumpadAdd"){
                this.$msgbox.close();
                this.clickNumAdd();
            }else if(e.key=="-" || e.code=="NumpadSubtract"){
                this.$msgbox.close();
                this.clickNumReduce();
            }
        });
    },
    watch:{
        isPayMoadlShow(newVal){
            this.$webBrowser.openAuxiliaryScreen({
                isPPay:newVal // 副屏显示请支付
            })
        },
        //订单信息 变化了
        orderTotal(){
            //副屏显示
            this.$webBrowser.openAuxiliaryScreen(this.orderTotal);
        },
        getPosFoodData(){
            this.foodMainCategory=this.getPosFoodData;
            let all=[];//全部大类
            this.foodMainCategory.forEach(category=> {
                if(category.FoodSubCategorys && category.FoodSubCategorys.length>0){
                    all=all.concat(category.FoodSubCategorys);
                }
            });
            all._all=[];//全部小类
            if(all.length>0){
                all.forEach(sub => {
                    let _SubCategory={SubCategory_Name:sub.SubCategory_Name,SubCategory_ID:sub.SubCategory_ID}
                    this.allfoodSubCategorys.push(_SubCategory);
                    if(sub.PosFoods && sub.PosFoods.length>0)
                    all._all=all._all.concat(sub.PosFoods)
                });
            }
            this.foodMainCategory._all=all; //小分类
            
            if(this.mainCategoryIndex>=this.foodMainCategory?.length){this.mainCategoryIndex=0;}
            if(this.mainCategoryIndex>0){
                this.foodSubCategorys=this.foodMainCategory[this.mainCategoryIndex-1].FoodSubCategorys;
            }else{
                this.foodSubCategorys=this.foodMainCategory._all;
            }
            
            if(this.subCategoryIndex>=this.foodSubCategorys?.length){this.subCategoryIndex=0;}
            if(this.subCategoryIndex>0){
                this.posFoods=this.foodSubCategorys[this.subCategoryIndex-1].PosFoods;
            }else{
                this.posFoods=this.foodSubCategorys._all;
            }
            this.posFoods.map(pos=>{
                this.foodSubCategorys.map(food=>{
                    if(food.SubCategory_ID==pos.SubCategory_ID){
                        pos = Object.assign(pos,{foodNumber:0,SubCategory_Code:food.SubCategory_Code})
                    }
                })
            })
        },
        /**菜品搜索 */
        varietySelect(newVal,oldVal){
            if(newVal && (newVal.indexOf('+')>=0 || newVal.indexOf('-')>=0 )){
                this.$nextTick(()=>{
                    this.varietySelect=oldVal;
                })
                return;
            }
            
            let MenuTxt=this.varietySelect.toLowerCase().trim();
            if(MenuTxt=="" && this.oldVarietySelect){
                MenuTxt=this.oldVarietySelect.toLowerCase().trim();
            }else{
                this.oldVarietySelect="";
            }

            if(MenuTxt!=''){
                this.subCategoryIndex=0;
                this.mainCategoryIndex=0;

                this.posFoods=this.foodAll.filter(pos=>(pos.Food_Name||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_PinYin||"").toLowerCase().indexOf(MenuTxt)>=0 || (pos.Food_Code||"").toLowerCase().indexOf(MenuTxt)>=0);
                
                this.$nextTick(()=>{
                    if(this.foodsPages && this.foodsPages.length>0 && !this.foodsPages.some(it=>it.Food_ID==this.choicePosFoods)){
                        this.choicePosFoods=this.foodsPages[0].Food_ID;
                    }
                })
            }else{
                if(this.aiSearchText?.length>0){//有ai识别
                    return;
                }
                this.posFoods =this.foodAll;
            }
        },
        //ai识别结果
        aiSearchText(){
            if(this.aiSearchText?.length>0){//有ai识别
                this.varietySelect="";
                this.oldVarietySelect="";
                this.subCategoryIndex=0;
                this.mainCategoryIndex=0;
                this.posFoods=this.foodAll.filter(pos=>this.aiSearchText.indexOf(pos.Food_Code)>=0);
                
                this.$nextTick(()=>{
                    if(this.foodsPages && this.foodsPages.length>0 && !this.foodsPages.some(it=>it.Food_ID==this.choicePosFoods)){
                        this.choicePosFoods=this.foodsPages[0].Food_ID;
                    }
                })
            }else{
                this.posFoods =this.foodAll;
            }
        },
        posFoods(){
            let size=this.arg.row*this.arg.col;
            this.posFoodsPage.pageIndex=1;
            this.posFoodsPage.pageSize=Math.ceil(this.posFoods.length/size);
        },
        mainCategoryIndex(){
            this.foodSubPageIndex=1;
        },
        DeskName(newVal,oldVal){
            if(newVal && (newVal.indexOf('+')>=0  || newVal.indexOf('-')>=0 )){
                this.DeskName=oldVal;
            }
        },
        //修改抬头 弹层显示时 修改同步桌号信息
        modiRiseShow(newVal){
            if(newVal){
                if(this.headingInfo){
                    this.headingInfo.DeskName=this.DeskName;
                }
            }
        }
    },
    methods:{
        setBottomH(){
            let top=this.$refs.searchBox?.getBoundingClientRect().top;
            if(top>0){
                return window.innerHeight-top;
            }
            return 0;
        },
        /**异常订单跳转 */
        abnormalOrder(data){
            /**抬头 */
            this.DeskName = data.Eat_DeskName
            this.headingInfo.DeskName = data.Eat_DeskName
            this.headingInfo.DeskAutoID = data.Eat_DeskID
            this.headingInfo.RoomAutoID = data.Eat_RoomID
            this.headingInfo.DinersNum = data.Eat_ManNum
            this.headingInfo.OrderChannel_AutoID = data.CHANNEL_ID
            this.headingInfo.brandNumber = 0
            this.headingInfo.orderRemark =data.Order_Remark
            this.orderChannel=[]
            this.DefaultChannelID = this.headingInfo?.OrderChannel_AutoID;
            this.Channellike =  this.headingInfo?.OrderChannel_AutoID;
            this.eatCheckIndex = data.Eat_CheckIndex
            this.OrderChannelUpdate()
            /**订单数据 */
            data?.Order_ConsumeList.forEach(order=>{
                if(order?.ConsumeOperations){
                    this.discountMoneuy += order?.Eat_SaleMoney - order?.Eat_CheckMoney
                }
                let _item ={
                    Eat_ConsumeID:order?.Eat_ConsumeID,
                    SubCategory_ID:order?.SubCategory_ID,
                    SubCategory_Code:order?.SubCategory_Code,
                    EAT_XFBMID:order?.EAT_XFBMID,
                    EAT_XFCode:order?.EAT_XFCode,
                    EAT_XFName:order?.EAT_XFName,
                    Eat_Number:order?.Eat_Number,
                    Eat_CheckPrice:order?.Eat_CheckPrice,
                    FoodDiscount_Dimension:order?.FoodDiscount_Dimension,
                    FoodProperty_Dimension:order?.FoodProperty_Dimension,
                    ModiPrice:0,
                    Eat_SalePrice:order?.Eat_SalePrice,
                    Eat_VipPrice:0,/**没有会员价 */
                    Eat_MasName:order?.Eat_MasName,
                    Food_Status:-1,
                    Food_Type:order?.Food_Type,
                    Food_AssSize:order?.Food_AssSize,
                    AssSizeNumber:'',
                    Eat_XFSize:order?.Eat_XFSize,
                    FoodModifys:null,
                    FoodSetmeals:order?.Eat_TcItemList?.map(it=>{
                        return {
                            SubCategory_ID:it.SubCategory_ID,
                            FoodSetmeal_ID:it.EAT_XFBMID,
                            FoodSetmeal_Code:it.EAT_XFCode,
                            FoodSetmeal_Name:it.EAT_XFName,
                            FoodSetmeal_Number:it.Eat_Number,
                            FoodSetmeal_Price:it.Eat_CheckPrice,
                            FoodSetmeal_Size:it.Eat_XFSize,
                            FoodSaleMoney:it.Eat_SaleMoney,
                            FoodModifys:null,
                            Eat_MasName:it.Eat_MasName,
                            ModiPrice:0,
                            Food_Status:-1,
                            Eat_ConsumeID:it.Eat_ConsumeID
                        }
                    }),
                    Is_Special:order?.Is_Special,//是否是推荐菜
                    Is_Discounted:order?.Is_Discounted,
                    Is_Seasonal:order?.Is_Seasonal, /**没有,是否时价菜 */
                    RemainNum:order?.RemainNum, /**没有 */
                    Is_Limited:order?.Is_Limited, /**没有 */
                    Is_ChangedNum:order?.Is_ChangedNum,
                    Is_ChargeForService:order?.Is_ChargeForService,
                    Is_SetMeal:false, //是否是套餐菜品
                    Is_Gift:order?.Is_Gift,
                    Exchanged_Number:0,
                    ConsumeOperations: order?.ConsumeOperations,//没有菜品的操作记录
                    is_discount:order?.FoodDiscount_Dimension=='折',
                    Eat_TcAutoID_Batch:order.Eat_TcAutoID_Batch,
                    Eat_TcName:order.Eat_TcName,
                    Eat_TcIndex:order.Eat_TcIndex,
                };

                if(_item.FoodDiscount_Dimension=="减"){//满减菜品 还原
                    _item.Eat_CheckPrice=_item.Eat_SalePrice;
                    _item.FoodDiscount_Dimension="";
                    _item.ConsumeOperations=null;
                }else if(_item.FoodDiscount_Dimension=="二"){//第二份折扣 还原
                    _item.Eat_CheckPrice=_item.Eat_SalePrice;
                    _item.FoodDiscount_Dimension="";
                     _item.ConsumeOperations=null;
                }
        
                if(order?.Food_AssSize){
                    _item.Eat_Number= order?.Number
                    _item.AssSizeNumber=order?.AssSizeNumber
                }
                if(order?.Mas_ParentID){
                    _item = Object.assign(_item,{Mas_ParentID:order?.Mas_ParentID})
                }
                this.orderMenuList.push(_item);
                this.radioClick(this.orderMenuList.length-1,_item);
            })
            data?.Order_PaymentDetailList.forEach(pay=>{
                let item={
                    Payment_ID:pay?.Payment_ID, //支付方式ID
                    Payment_Code:pay?.Payment_Code,  //支付方式编号
                    Payment_Name:pay?.Payment_Name,  //支付方式名称
                    Payment_Type:pay?.Payment_Type,  //支付方式类型
                    Payment_Money:pay?.Payment_Money, //支付方式金额
                    Payment_FactMoney:pay?.Payment_Money,  //支付实收金额
                    Qr_Code:'', //扫码付款码值
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                    Time:this.getCurrentDateTime(pay?.Eat_PaymentTime.toString()),
                    is_pay:true, /**付款记录是否写入POS */
                    Eat_PayDetailID:pay?.Eat_PayDetailID,
                    Operator_Name:pay?.Eat_Czy /**操作员 */
                }
                this.paymentHistory.push(item);
            })
            this.money=0;
            this.EatAutoID = data?.Eat_AutoID 
            this.Timestamps = data?.Timestamps, /**没有,订单时间戳 */

            console.log("abnormalOrder",this.discountMoneuy);
            setTimeout(() => {
                this.posFoodNumbermethod(5)
                this.orderMenuList.forEach(order=>{
                    let item=this.foodAll?.find(it=>it.Food_ID==order.EAT_XFBMID);
                    if(item){
                        order.Eat_VipPrice = item.Food_VipPrice
                        order.Is_Seasonal = item.Is_Seasonal
                        order.RemainNum = item.RemainNum
                        order.Is_Limited = item.Is_Limited
                        if(order.FoodDiscount_Dimension=='促'){
                            order.Eat_CheckPrice=item.Promotion_Price
                            order.Promotion_Price = item.Promotion_Price
                            order.ConsumeOperations=[{OperateType:5}]
                        }
                    }
                })
            },1000);
        },
        //扫描枪 建索商品
        barcode(){
            console.log("barcode")
            let MenuTxt=(this.varietySelect||"").toLowerCase().trim();
            if(MenuTxt){
                let posFoods =this.foodMainCategory?._all?._all;
                let item=posFoods?.find(it=>(it.Food_BarCode||"").toLowerCase()==MenuTxt);
                if(item){
                    this.onPlaceFoods(item);
                    //扫码的 清除记录
                    this.oldVarietySelect=""
                }
            }
        },
        /**控制菜品搜索框 上下左右光标 */
        keyCursor(e){
            if(e.keyCode=='37' || e.keyCode=='38' || e.keyCode=='39' || e.keyCode=='40' || e.keyCode=='13'){
                e.preventDefault();
                if(this.foodsPages && this.foodsPages.length>0){
                    let index=this.foodsPages.findIndex(item=>item.Food_ID==this.choicePosFoods);
                    if(e.keyCode=='37'){//左边
                        index--;
                    }else if(e.keyCode=='38'){//上
                        index=index-this.arg.col
                    }else if(e.keyCode=='39'){//右边
                        index++;
                    }else if(e.keyCode=='40'){//下
                        index=index+this.arg.col;
                    }else if(e.keyCode=='13'){
                        if(this.foodsPages.length>0){
                            if(index>=0 && this.getFoodNumber(this.foodsPages[index])>0 && this.choicePosFoods==this.foodsPages[index].Food_ID){
                                this.$confirm("【"+this.foodsPages[index].Food_Name+"】已点，是否继续添加一份?", "提示", {
                                    confirmButtonText: "确定",
                                    cancelButtonText:"取消",
                                    callback: (name) => {
                                        if(name=='confirm'){
                                            this.onPlaceFoods(this.foodsPages[index]);
                                        }
                                    },
                                });
                            }else{
                                this.onPlaceFoods(this.foodsPages[index]);
                            }
                        }else{
                            this.$message.warning('未找到菜品');
                            return
                        }
                        return;
                    }
                    if(index<0) index=0;
                    if(index>=0 && index<this.foodsPages.length){
                        this.choicePosFoods=this.foodsPages[index].Food_ID;
                    }
                }
            }
        },
        keyChecked(){
            return true;
        },
        /**菜品点菜数量 标识 */
        getFoodNumber(item){
            let num=0;
            if(this.foodNumberKey[item.Food_ID]>0){
                num=this.foodNumberKey[item.Food_ID];
            }
            if(item.MultipleSizeFoods?.some(it=>this.foodNumberKey[it.Food_ID]>0)){
                item.MultipleSizeFoods.forEach(it=>{
                    if(this.foodNumberKey[it.Food_ID]>0){
                        num+=this.foodNumberKey[it.Food_ID];
                    }
                })
                num=Number(num.toFixed(4));
            }
            return num;
        },
        /**点菜界面竖排自定义按钮点击 */
        MiddleMenuClick(item){
            //不需要判断菜品信息的按钮
            let arrKey=['GetTemporaryStorage','AllDelete','OtherFunc','PrintPreViewBill','ReadCardOrder','KLCOUPON','OpenCashBox','CallCheckNum','SaveCoupons','BankPos','CheckPrintSM'];
            if(this.orderMenuList.length==0 && arrKey.indexOf(item.ProjectItem_Key)<0){
                this.$message.warning("没有找到订单菜品数据,请重新尝试!");
                return
            }
            this.checkBtnlist = JSON.parse(JSON.stringify(item))
            /**如果是点击的是赠送按钮,并且是取消赠送的菜品,则跳过权限验证 */
            if(item.Permission==false){
                if(item.ProjectItem_Key=='GiveFree' && this.disRadioOrder?.ConsumeOperations.length>0){
                    this.clickGivereturn(1,item) 
                    return
                }else{
                    this.$confirm('该账户权限不足,不支持'+item.ProjectItem_Name+'功能', '提示', {
                        confirmButtonText: '去验证',
                        type: 'warning'})
                    .then(() => {
                        this.checkShow=true
                        
                    }).catch(()=>{});
                    return;
                }
            }
            switch(item.ProjectItem_Key){
                case "SaveCoupons":{//卡券核销
                    if(this.orderMenuList.length>0){
                        this.$message.warning("存在新点菜品,请先结账再使用此功能");
                        return;
                    }
                    if(this.EatAutoID){//有订单id了
                        this.$message.warning("已生成订单,请先结账再使用此功能!");
                        return;
                    }
                    this.couponVerifyShow=true;
                    break;
                }
                case 'CallCheckNum':{//取餐叫号
                    this.callCheckShow=true;
                    break
                }
                case 'SingleDel':
                    this.clickDelete(this.disRadioclass,this.disRadioOrder)  /**单删 */
                    break
                case 'Mas':
                    this.clickDemandShow() /**要求 */
                    break
                case 'Plus':
                    this.clickNumAdd() /**+ */
                    break
                case 'Minus':
                    this.clickNumReduce() /**- */
                    break
                case 'NumKeybord':
                    this.clickQuantity() /**数量 */
                    break
                case 'GiveFree': 
                    {
                        if(this.userInfo.TheLimitedGiftingMoney==0 && Object.keys(this.checkUser).length==0){
                            this.$message.warning('没有赠送权限,请去后台设置')
                            return
                        }
                        if(this.disRadioOrder?.is_taste){
                            this.$message.warning('口味做法无法赠送')
                            return
                        }
                        if(this.disRadioOrder?.ConsumeOperations){
                            let OperateType= this.disRadioOrder?.ConsumeOperations[0]?.OperateType
                            if(OperateType==1){
                                this.$message.warning('该菜品已进行过折扣,无法再进行赠送')
                            }else if(OperateType==3){
                                this.$message.warning('该菜品已进行过改价,无法再进行赠送')
                            }else if(OperateType==4){
                                this.$message.warning('该菜品已进行过会员价,无法再进行赠送')
                            }else if(OperateType==6){
                                this.$message.warning('该菜品已进行过第二份折扣,无法再进行赠送')
                            }else{
                                this.clickGivereturn(1,item) /**赠送 */
                            }
                        }else{
                            this.clickGivereturn(1,item) /**赠送 */
                        }
                        break
                    }
                case 'DiscountPlan': /**折扣 */
                    {
                        if(this.orderMenuList.length>0){
                            this.recordFunctionCheck=item
                            this.discountShow=true
                        }else{
                            this.$message.warning('请先点单菜品')
                        }
                    }
                    break;
                case 'ModifyPrice':
                        this.clickPriceChange() /**改价 */
                    break
                case 'AllMas':
                    this.clickDemandFullShow() /**全单 */
                    break
                case 'OtherFunc':
                    this.otherFuncList = item.SysProjectSubItems
                    this.subShow = true
                    
                    break
                case 'TemporaryStorage'://暂存菜品
                    if(this.orderMenuList.length==0){
                        this.$message.warning('没有可暂存的菜品信息！')
                    }else{
                        this.$confirm('您确定暂存当前菜品?', "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText:"取消",
                            callback: (name) => {
                                if(name=='confirm'){
                                    let _data=[];
                                    this.orderMenuList.map(item=>{
                                        if(item.Food_Status==-1){
                                            let json=Object.assign({},item);
                                            json.OrderComboConsumes=undefined;
                                            _data.push(json);
                                        }
                                    })
                                    if(_data.length){

                                        this.$cacheData.lockersCache.set(_data)
                                        this.$message.success("暂存成功");
                                        this.emptydata()
                                    }else{
                                        this.$message.error('没有新菜品不需要暂存')
                                    }
                                }
                            },
                        });
                    }
                    break
                // case 'SendButNoToKit': //下单不送达厨房
                //     this.onPlaceOrder(true)
                //     break
                case 'AllDeskRefund': //整桌退菜
                    {
                        let status=0
                        this.orderMenuList.map(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.wholeShow = true;
                        
                    }
                    break
                case 'PrintPreViewBill': //重印上一单
                    this.printPreViewBill()
                    break
                case 'GetTemporaryStorage'://提取暂存菜品
                    {
                        let lockersCache = this.$cacheData.lockersCache.getLockersList(480)
                        if(lockersCache.length>0){
                            this.lockersShow=true;
                            
                        }else{
                            this.$message.warning('没有暂存订单信息')
                        }
                    }
                    break
                case 'DiyFood'://自定义菜品
                    this.customDishesShow=true;
                    
                    break
                case 'ChangeSize': //换单位
                    this.unitClick()
                    break
                 /**会员价 */
                case 'MemberPrice':
                    {
                        if(this.carPriceType==false){
                            this.FoodVipMoney(item);
                            setTimeout(() => {
                                this.$refs.searchBox.focus();
                            },100);
                        }else{
                            this.$confirm('是否取消会员价', '提示', {
                                confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                            .then(() => {
                                this.FoodVipMoney(item);
                                setTimeout(() => {
                                    this.$refs.searchBox.focus();
                                },100);
                            }).catch(() => {
                            });
                        }
                    }
                    break
                case 'CopyMenu'://复制菜品
                    {
                        let status=0
                        this.orderMenuList.some(meu=>{
                            if(meu.Food_Status==-1){
                                status=1
                                return true
                            }
                        })
                        if(status==1){
                            this.$message.error('存在新点菜品,请先落单再使用此功能')
                            return
                        }
                        this.copyFoodShow=true;
                        
                    }
                    break
                case 'TcChangeFood'://套餐换菜
                    if(this.disRadioOrder.Eat_CheckMoney<=0){
                        this.$message.error('即配套餐换菜请选择套餐头');
                        return;
                    }
                    if(this.disRadioOrder.Food_Type==1){
                        this.$message.error('该功能仅适用于套餐菜品');
                        return;
                    }
                    if(this.disRadioOrder.Eat_Number<=0){
                        this.$message.error('套餐菜品数量为0,不能进行套餐换菜操作');
                        return;
                    }
                    if(this.disRadioclass==-1){
                         this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                         return
                    }
                    this.foodAll.some(pos=>{
                        if(pos.Food_ID==this.disRadioOrder.EAT_XFBMID){
                            this.posFoodSetMealInfos=[]
                            this.posFoodSetMealInfos.push(pos);
                            return true
                        }
                    })
                    /**菜品未下单*/
                    if(this.disRadioOrder.Food_Status==-1){
                        this.tcChangeType = 1
                        if(!this.disRadioOrder.FoodSetmeals){
                            this.$message.error('请选择对应套餐');
                            return;
                        }else{
                            this.subShow = false
                        }
                        /**即配套餐 */
                        if(this.disRadioOrder.Food_Type==3){
                            this.$message.error('即配套餐下单后才可调整');
                            return;
                        }else{
                            this.setMealShow = true
                            
                        }
                    }else{
                         /**即配套餐 */
                        if(this.disRadioOrder.Food_Type==3){
                            this.readyShowType=2
                            this.readyShow=true
                            
                            //this.$message.error('下单的即配套餐暂无改菜功能')
                        }else{
                            /**已经下单的菜品 */
                            this.cheageSetMealShow=true;
                            
                        }
                    }
                    break
                case 'AllDelete'://全删
                    {
                        let _order =[]
                        let _deleteorder=[]
                        this.orderMenuList.map(order=>{
                            if(order.Food_Status!=-1){
                                _order.push(order)
                            }
                            if(order.Food_Status==-1){
                                _deleteorder.push(order)
                            }
                        })
                        if(_deleteorder.length==0){
                            this.$message.warning('没有需要删除的菜品')
                            return
                        }
                        this.$confirm('确定删除所有菜品吗?', '提示', {
                            confirmButtonText: '确认删除',
                            cancelButtonText: '取消',
                            type: 'warning'})
                        .then(() => {
                            this.orderMenuList = [];
                            this.posFoodNumbermethod(4)
                        }).catch(()=>{});
                    }
                    break
                case 'OpenCashBox'://开钱箱
                    this.$webBrowser.openCashBoxBnt().then(d=>{
                        if(d?.state!=0){
                            this.$message.error('钱箱打开失败:'+(d?.message||""));
                        }
                    });
                break
                case 'ReadCardOrder'://读卡点菜
                {
                    this.readCardFood((foodids)=>{
                        this.$alert("本次读卡点菜"+foodids.length+"道菜！").then(()=>{
                            this.readCardAddFood(foodids)
                        })
                    })
                    break
                }
                case 'KLCOUPON'://昆仑卡核销
                {
                    let status=0
                    this.orderMenuList.map(meu=>{
                        if(meu.Food_Status==-1){
                            status=1
                            return true
                        }
                    })
                    if(status==1){
                        this.$message.error('存在新点菜品,请先结账再使用此功能')
                        return
                    }
                    this.klCouponShow=true;
                    
                    break;
                }
                case 'GroupUpdateXFBM'://组合修改
                {
                    if(this.disRadioOrder){
                        //是否是组合菜品
                        let foodInfo=this.disRadioOrder;
                        if(foodInfo.Food_Type==1 && foodInfo.Eat_TcAutoID_Batch && !foodInfo.Eat_TcName){
                            if(this.disRadioOrder.Is_Combo){
                                foodInfo=this.orderMenuList.find(it=>it.Eat_TcIndex==1 && it.Eat_TcAutoID_Batch== this.disRadioOrder.Eat_TcAutoID_Batch);
                            }
                            // if(!foodInfo.OrderComboConsumes[0].Kind_Name){//未找到明细分组 说明是异常单过来的
                            //     this.$message.warning("该组合为异常单过来的,无法修改!");
                            //     return;
                            // }
                            this.comboFoodInfo=this.comboFoodList?.find(it=>it.Food_ID==foodInfo.EAT_XFBMID);
                            if(this.comboFoodInfo){
                                this.editComboFoodInfo=foodInfo;
                                this.isComboFoodShow=true;
                                
                            }else{
                                this.$message.warning("未查询到该组合,无法修改!");
                            }
                        }else{
                            this.$message.warning('该功能仅适用于组合菜品');
                        }
                    }else{
                        this.$message.warning("请先选择左边组合菜品进行修改!");
                    }
                    break;
                } 
                case 'BankPos':{//BankPos操作
                    this.bankPosShow=true;//
                    break
                }
                case 'CheckPrintSM':{//扫码订单核销
                    if(this.orderMenuList.length>0){
                        this.$message.warning("存在新点菜品,请先结账再使用此功能");
                        return;
                    }
                    if(this.EatAutoID){//有订单id了
                        this.$message.warning("已生成订单,请先结账再使用此功能!");
                        return;
                    }
                    this.scanOrderShow=true;//
                    break
                }
               
            }
        },
        /**修改抬头返回 */
        setDeskclose(headingInfo,meberInfo){
            this.headingInfo = headingInfo
            if(this.headingInfo?.OrderChannel_AutoID){
                this.orderChannel=[]
                this.DefaultChannelID = this.headingInfo?.OrderChannel_AutoID;
                this.Channellike=this.headingInfo?.OrderChannel_AutoID;
                this.OrderChannelUpdate()
            }
            if(this.headingInfo.DinersNum==''){
                this.headingInfo.DinersNum=0
            }
            this.meberInfo = meberInfo;
            this.DeskName = this.headingInfo?.DeskName
            if(this.headingInfo?.RoomAutoID){
                this.RoomAutoID = this.headingInfo?.RoomAutoID
            }
            this.modiRiseShow=false
        },
        /**全单点击 */
        clickDemandFullShow(){
            let _status=0;
            this.orderMenuList.map(item=>{
                if(item.Food_Status==-1){
                    _status=1;
                }
            })
            if(_status==1){
                if(this.masKindsList){
                    this.demandFullShow=true
                    
                }else{
                    this.$message.warning('请先去后台配置相对应的菜品口味信息');
                }
            }else{
                this.$alert('没有新点菜品暂时无法调整', '提示', {confirmButtonText: '确定',});
            }
        },
        /**点击大类*/
        onFoodNav(item,index){
            if(item){
                if(!item.FoodSubCategorys._all){
                    item.FoodSubCategorys._all=[];//全部菜品
                    item.FoodSubCategorys.forEach(sub => {
                        if(sub.PosFoods && sub.PosFoods.length>0)
                        item.FoodSubCategorys._all=item.FoodSubCategorys._all.concat(sub.PosFoods)
                    });
                }
                this.foodSubCategorys=item.FoodSubCategorys;
                this.mainCategoryIndex=index;
                this.onFoodSubNav();
            }else if(this.foodMainCategory && this.foodMainCategory._all){//全部
                this.foodSubCategorys=this.foodMainCategory._all;
                this.mainCategoryIndex=0;
                this.onFoodSubNav();
            }
            this.oldVarietySelect="";
        },
        /**点击小类*/
        onFoodSubNav(item,index){
            if(item){
                this.posFoods=item.PosFoods;
                this.subCategoryIndex=index;
            }else if(this.foodSubCategorys && this.foodSubCategorys._all){//全部
                this.posFoods=this.foodSubCategorys._all;
                this.subCategoryIndex=0
            }
            this.varietySelect="";
            this.oldVarietySelect="";
        },
        /**点击菜品,添加到数组中 */
        onPlaceFoods(item){
            if(this.$cacheData.Numberkey.getNumberKeyOff()==false){
                this.orderNumber=''
                this.orderNumberType=-1
            }
            let _RemainNum=0
            this.assistSizeInfo={}
            if(!item){
                this.$message({message: '请先选择菜品',type: 'warning'});
                return
            }
            if(item.Is_SoldOut){
                this.$message({message: '该菜品已售完',type: 'warning'});
                return
            }
            if(item.Is_Limited==true){
                if(item.RemainNum==0){
                    if(item.Is_LimitedProject){
                        this.$message({message: '该菜品已售完',type: 'warning'});
                        return;
                    }
                }else{
                    this.orderMenuList.map(order=>{
                        if(order.EAT_XFBMID==item.Food_ID && order.Eat_Number>=1 && order.Food_Status==-1 && order.Eat_CheckPrice==item.Food_Price){
                            _RemainNum = _RemainNum+order.Eat_Number
                        }
                    })
                    if(this.isUnitWeight(item.Food_Size) && Number(this.wgNum)>0){   
                        if((_RemainNum+Number(this.wgNum))>item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }
                    if(Number(this.orderNumber)>0){
                        if((_RemainNum+Number(this.orderNumber))>item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }else{
                        if(_RemainNum>=item.RemainNum){
                            this.$message({message: '不能超过该菜品的剩余数量',type: 'warning'});
                            return
                        }
                    }
                    
                }
            }

            

            /**判断是否多规格菜品 */
            if(item.Is_MultipleSize){
                if(item.Is_MultipleSize==true){
                    this.unitShow=true
                    if(this.multipleSizeFoods.length==0){
                        this.multipleSizeFoods.push(item)
                    }else{
                        let _SizeNum=0
                        this.multipleSizeFoods.map(food=>{
                            if(food.Food_ID==item.Food_ID){
                                _SizeNum=1
                            }
                        })
                        if(_SizeNum==0){
                            this.multipleSizeFoods.push(item)
                        }
                    }
                    this.theMultipleSizeFoods=item;
                    return
                }
            }
            this.unitShow=false
            //是否是组合菜品
            let comboFood=this.comboFoodList?.find(it=>it.Food_ID==item.Food_ID);
            if(comboFood){
                this.comboFoodInfo=comboFood;
                this.editComboFoodInfo=null;
                if(this.orderNumber>0){
                    this.editComboFoodInfo={
                        Eat_Number:this.orderNumber
                    };
                }
                this.isComboFoodShow=true;
                return;
            }
            /**判断是否是辅助单位菜品 */
            if(item.Food_AssSize){
                if(item.Food_AssSize!='' && this.assSizeShow==false && this.BindPosFoods==''){
                    this.assSizeShow = true
                    this.assistSizeInfo = JSON.parse(JSON.stringify(item))
                    return
                }
            }
            /**判断是否是绑定做法菜品 */
            if(item.Is_BindEatMas){
                if(item.Is_BindEatMas==true){
                    if(!this.masKindsList){
                        this.$message.warning('该菜品有默认口味,先请先去后台配置相对应的菜品口味信息');
                        return
                    }else{
                        this.demandShow=true
                        this.BindPosFoods = JSON.parse(JSON.stringify(item))
                        return
                    }
                }
            }
            let _item=''
            this.disRadioCheck = true;
            this.choicePosFoods = item.Food_ID;
            /**是否是套餐 */
            if(item.Is_SetMeal){
                if(!item.SetMealInfo){
                    this.$message.warning('该套餐后台没有搭配对应的菜品');
                    return
                }else{
                    this.tcChangeType=0
                    this.posFoodSetMealInfos=[]
                    this.posFoodSetMealInfos.push(item);
                    this.setMealShow=true;
                }
            }else{
                this.orderMenuList.map((order,index)=>{
                    if(order.EAT_XFBMID==this.disRadioOrder.EAT_XFBMID && order.Food_Status==-1 && this.unitType==1){
                        this.orderMenuList.splice(index,1)
                        this.unitType=0
                    }
                })
                if(_data.MergeSameFoodInOrder==false){
                    _item = this.onPlaceFoodsChildren(item)
                    //setTimeout(()=>{this.$refs.box.scrollTop = this.$refs.box.scrollHeight;},0)
                    this.radioClick(this.orderMenuList.length-1,_item);
                   
                }else{
                    let orderIndex = this.orderMenuList.findIndex(order=>
                        !(order.Eat_TcAutoID_Batch && !order.Eat_TcName) //排除组合菜品
                        && !order.isPromotion2 && order.EAT_XFBMID==item.Food_ID 
                        && order.Food_Status==-1 && order.Eat_MasName=='' && order.Eat_CheckPrice==item.Food_Price )
                    if(orderIndex>=0){
                       let order=this.orderMenuList[orderIndex];
                       if(order.EAT_XFBMID==item.Food_ID && order.Food_Status==-1 && order.Eat_MasName=='' && order.Eat_CheckPrice==item.Food_Price){
                            if(Number(this.orderNumber)>0){
                                order.Eat_Number = this.addNum(Number(this.orderNumber),order.Eat_Number)
                            }else if(this.isUnitWeight(item.Food_Size) && Number(this.wgNum)>0){
                                order.Eat_Number = this.addNum(Number(this.wgNum),order.Eat_Number)
                            }else{
                                order.Eat_Number++
                            }
                            if(item.Food_AssSize){
                                order.AssSizeNumber +=item.AssSizeNumber
                            }
                            this.radioClick(orderIndex,order);
                        }
                   }else{
                        _item = this.onPlaceFoodsChildren(item)
                        //setTimeout(()=>{this.$refs.box.scrollTop = this.$refs.box.scrollHeight;},0)
                        this.radioClick(this.orderMenuList.length-1,_item);
                   }
                }
                this.posFoodNumbermethod(1)
                this.orderNumber=''
                this.orderNumberType=-1
                this.wgNum=0
            }
            //选择菜品时记录一下
            if(this.varietySelect) {
                this.oldVarietySelect=this.varietySelect;
            }
            this.varietySelect=''

            if(this.isAiEnable){
                if(this.aiSearchText?.length>0){
                    this.aiSearchText=null;
                    //回传ai选择结果
                    this.$webBrowser.selectAiData({itemCode:item.Food_Code,itemName:item.Food_Name,pinyin:item.Food_PinYin,markType:1});
                }else{
                    //回传ai学习结果
                    this.$webBrowser.selectAiData({itemCode:item.Food_Code,itemName:item.Food_Name,pinyin:item.Food_PinYin,markType:2});
                }
            }
            
        },
        onPlaceFoodsChildren(item){
            let _item ={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:item.SubCategory_ID,
                SubCategory_Code:item.SubCategory_Code,
                EAT_XFBMID:item.Food_ID,
                EAT_XFCode:item.Food_Code,
                EAT_XFName:item.Food_Name,
                Eat_Number:1,
                Eat_CheckPrice:item.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:item.Food_Price,
                Eat_VipPrice:item.Food_VipPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:item.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:item.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:item.Is_Special,//是否是推荐菜
                Is_Discounted:item.Is_Discounted,
                Is_Seasonal:item.Is_Seasonal,
                RemainNum:item.RemainNum,
                Is_Limited:item.Is_Limited,
                Is_ChangedNum:item.Is_ChangedNum,
                Is_ChargeForService:item.Is_ChargeForService,
                Is_SetMeal:item.Is_SetMeal,
                Promotion_Price:0,
                Exchanged_Number:0,
                ConsumeOperations:[]};
            //促销价
            if(item?.Promotion_Price){
                _item.Eat_CheckPrice = item?.Promotion_Price
                _item.Promotion_Price = item?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            if(item.Food_AssSize){
                _item.Eat_Number= item.Number
                _item.AssSizeNumber=item.AssSizeNumber
            }
            if(Number(this.orderNumber)>0){
                _item.Eat_Number = Number(this.orderNumber)
            }else if(this.isUnitWeight(item.Food_Size) && Number(this.wgNum)>0){
                _item.Eat_Number = Number(this.wgNum)
            }
            this.orderMenuList.push(_item);
            return _item
        },
        /**删除 */
        clickDelete(index,item){
            if(item.Food_Status==0){
                this.$alert('', '提示', {confirmButtonText: '确定',});
            }else{
                if(this.disRadioclass>-1){
                    if(this.foodtype==1){
                        this.$alert('套餐下的菜品不允许单独删除', '提示', {confirmButtonText: '确定',});
                        return
                    }
                    if(this.disRadioOrder?.Is_Combo){
                        this.$alert('组合菜品下的菜品不允许单独删除', '提示', {confirmButtonText: '确定',});
                        return
                    }
                    if(this.disRadioOrder.Food_Status!=-1){
                        this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                    }
                    else{
                        if(item?.is_taste){//口味加价做法菜品
                            this.orderMenuList.forEach((order,i)=>{
                                /**找到选择口味对应的菜品 */
                                if(order.Eat_ConsumeID==item.Eat_ConsumeID && order?.FoodModifys){
                                    /**找到对应的口味删除 */
                                    order?.FoodModifys.map((food,j)=>{
                                        if(food.Mas_AutoID==item.Mas_AutoID){
                                            order?.FoodModifys.splice(j--,1)
                                        }
                                    })
                                    let data = this.optFlavorsMap(order?.FoodModifys,order)
                                    order.Eat_MasName = data._MasName
                                }
                                if(order.Eat_ConsumeID==item.Eat_ConsumeID && order.Mas_AutoID==item.Mas_AutoID){
                                    this.orderMenuList.splice(i,1)
                                    i--
                                }
                            })
                            this.orderMenuList.forEach(order=>{
                                /**如果是套餐的话 */
                                if(order.FoodSetmeals){
                                    order.FoodSetmeals.forEach(food=>{
                                        if(food.Eat_ConsumeID==item.Eat_ConsumeID){
                                            if(food.FoodModifys){
                                                food.FoodModifys.forEach((mo,j)=>{
                                                    if(mo.Mas_AutoID==item.Mas_AutoID){
                                                        food?.FoodModifys.splice(j--,1)
                                                    }
                                                })
                                                let data = this.optFlavorsMap(food?.FoodModifys,order)
                                                food.Eat_MasName = data._MasName
                                            }
                                        }
                                    })
                                }
                                
                            })
                            /**删除对应储存的口味要求和做法 */
                            if(this.aloneMethod){
                                this.aloneMethod.forEach((alon,i)=>{
                                    if(alon.disRadioOrder.Eat_ConsumeID==item.Eat_ConsumeID){
                                        if(alon?.optFlavors){
                                            alon.optFlavors.forEach((opt,j)=>{
                                                if(opt.Mas_AutoID==item.Mas_AutoID){
                                                    alon.optFlavors.splice(j,1)
                                                    j--
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                            if(this.mallMethod){
                                this.mallMethod.forEach(me=>{
                                    if(me.order.Eat_ConsumeID==item.Eat_ConsumeID){
                                        if(me.optFlavors){
                                            me.optFlavors.forEach((opt,j)=>{
                                                if(opt.Mas_AutoID==item.Mas_AutoID){
                                                    me.optFlavors.splice(j,1)
                                                    j--
                                                }
                                            })
                                        }
                                    }
                                })
                            }
                        }else{//非口味加价做法菜品
                            let arr=[]
                            this.orderMenuList.forEach(order=>{
                                //过滤 要删除的菜品 
                                if(order.Eat_ConsumeID!=item.Eat_ConsumeID){
                                    //要删除组合菜品下的菜品
                                    if(item.OrderComboConsumes?.some(it=>it.Eat_ConsumeID==order.Eat_ConsumeID)){
                                        return;
                                    }
                                    arr.push(order)
                                }
                            })
                            if(item?.FoodSetmeals){
                                 for(let i=0; i<arr.length; i++){
                                    item.FoodSetmeals.forEach(food=>{
                                        if(food.Eat_ConsumeID==arr[i]?.Eat_ConsumeID){
                                            arr.splice(i,1)
                                            i--;
                                        }
                                    })
                                }
                            }
                            this.orderMenuList = arr
                        }
                        if(item?.Is_Gift){
                            if(this.foodOperationRecord.length>0){
                                this.foodOperationRecord.forEach((food,index)=>{
                                    if(food.foodType==1 && food.Eat_ConsumeID==item.Eat_ConsumeID){
                                       this.foodOperationRecord.splice(index,1)
                                       this.discountMoneuy = this.discountMoneuy -item.Eat_CheckPrice
                                    }
                                })
                            }                             
                        }
                        if(!item?.is_taste){
                            this.discountMoneuy = this.discountMoneuy - ((Number(item?.Eat_SalePrice) - Number(item.Eat_CheckPrice)) * item.Eat_Number)
                            if(this.discountMoneuy<0){
                                this.discountMoneuy =0
                            }
                        }
                        this.posFoodNumbermethod(3)
                        this.clickEndConsume(index-1);
                    }
                    
                }else{
                    this.$message.warning('请先选择已点菜的菜品')
                }
            }
        },
        /**点击最后一条明细数据 */
        clickEndConsume(index){
            if(index==undefined || index==null){
                index=this.orderMenuList.length-1;
            }else if(this.orderMenuList.length<=index){
                index=this.orderMenuList.length-1;
            }
            if(index<0){
                index=0;
            }
            if(index>=0){
                for(let i=index;i>=0;i--){
                    if(this.orderMenuList[i] && !this.orderMenuList[i].Is_Combo){
                        index=i;
                        this.radioClick(index,this.orderMenuList[index]);
                        break;
                    }
                }
            }
            
        },
        /**菜单选择菜品 */
        radioClick(index,item){
            if(item){
                this.foodSetmealsclass=-1
                this.disRadioOrder =item
                this.foodtype = 0
                this.choicefoodOrder=''
                this.disRadioclass=-1;
                this.disRadioclass=index;
            }else{
                this.disRadioOrder={}
                this.disRadioclass=-1
            }
            this.$nextTick(()=>{
                this.$refs.orderMenu?.scrollRow();
            })
        },
        /**菜单要求 */
        clickDemandShow(){
            if(this.disRadioclass>-1){
                if(this.disRadioOrder.Food_Status==-1){
                    if(!this.masKindsList){
                         this.$message.warning('请先去后台配置相对应的菜品口味信息');
                    }else{
                        this.demandShow=true
                        
                    }
                }else{
                    this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
            }
        },
        /**订单口味数量同步 */
        orderFoodSyn(order,type){
            if(order?.is_taste){
                if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                    if(order?.Price_Kind==0){
                        if(type==1){
                            order.Eat_Number++
                        }else{
                            order.Eat_Number = this.accSub(1,order.Eat_Number);
                        }
                    }
                }
            }
        },
        /**增加数量 */
        clickNumAdd(index,item,adType){
            if(this.foodtype==1){
                this.$alert('套餐菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.Is_Combo){
                this.$alert('组合菜品下的菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(!this.disRadioOrder?.is_taste){
                if(this.disRadioOrder?.ConsumeOperations){
                    let OperateType= this.disRadioOrder?.ConsumeOperations[0]?.OperateType
                    if(OperateType==1){
                        this.$message.warning('折扣菜品,无法增加数量')
                        return
                    }else if(OperateType==2){
                        this.$message.warning('菜品赠送,无法增加数量')
                        return
                    }else if(OperateType==3){
                        this.$message.warning('菜品改价,无法增加数量')
                        return
                    }else if(OperateType==4){
                        this.$message.warning('会员价菜品,无法增加数量')
                        return
                    }
                }
            }
            if(this.disRadioOrder?.Is_Gift==true){
                this.$message.warning('菜品赠送,无法增加数量')
                return
            }
            if(index!=null){
                if(item.Food_Status==0){
                    this.$message.warning('该菜品已下单暂时无法调整')
                }else{
                    /**判断是否是辅助单位菜品 */
                    if(this.orderMenuList[index].Food_AssSize){
                        if(adType==null){
                            this.adjusquantityShow=true;
                            return
                        }
                    }
                    this.orderMenuList[index].Eat_Number++;
                    if(this.orderMenuList[index].FoodModifys){
                        this.orderMenuList[index].FoodModifys.map(item=>{
                            if(item.Price_Kind==0){
                                item.Mas_Num=this.addNum(1,item.Mas_Num)
                            }
                        })
                    }
                    this.posFoodNumbermethod(1,true)
                }
            }else{
                if(this.disRadioclass>-1){
                    if(this.disRadioOrder.Food_Status!=-1){
                        this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                    }else{
                        /**判断是否是辅助单位菜品 */
                        if(this.orderMenuList[this.disRadioclass].Food_AssSize){
                            if(adType==null){
                                this.adjusquantityShow=true;
                                return
                            }
                        }
                        let EatNumber= this.orderMenuList[this.disRadioclass].Eat_Number
                        let _EATXFBMID = this.orderMenuList[this.disRadioclass].EAT_XFBMID
                        let Limited = '',RemainNum=0
                        this.orderMenuList.map(order=>{
                            if(order.EAT_XFBMID==_EATXFBMID && !order?.is_taste){
                                if(order.Is_Limited==true){
                                    RemainNum += order.Eat_Number
                                    if(order.RemainNum!=0){
                                        if(RemainNum>=order.RemainNum){
                                            Limited='已超过菜品的最大剩余数量'
                                        }
                                    }
                                }
                            }
                        })
                        if(Limited!=''){    
                            this.$message.error(Limited);
                            return
                        }
                        this.FoodModifysNumberAdd(EatNumber)
                        this.posFoodNumbermethod(1,true)
                    }
                }else{
                    this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                }
            }
        },
        /**口味要求同步增加数量 */
        FoodModifysNumberAdd(EatNumber){
            /**选择的口味 */
            this.orderMenuList = JSON.parse(JSON.stringify(this.orderMenuList))
            if(this.disRadioOrder?.is_taste){
                //if(this.orderMenuList[this.disRadioclass]?.Is_SetMealCountAvgPrice==false){
                this.orderMenuList[this.disRadioclass].Eat_Number=this.addNum(1,this.orderMenuList[this.disRadioclass].Eat_Number)
                //}
                this.orderMenuList.map(order=>{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID && !order?.is_taste){
                        if(order.FoodModifys){
                            order.FoodModifys.map(item=>{
                                if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                    item.Mas_Num=this.addNum(1,item.Mas_Num)
                                }
                            })
                        }
                        /**有套餐 && order?.Is_SetMealCountAvgPrice==false*/
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.forEach(food=>{
                                if(food.FoodModifys){
                                    food.FoodModifys.map(item=>{
                                        if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID && food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                            item.Mas_Num=this.addNum(1,item.Mas_Num)
                                        }
                                    })
                                }
                            })
                        }
                    }else{
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                                item.Mas_Num=this.addNum(1,item.Mas_Num)
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }else{
                /**普通菜品数量调整 */
                if(this.foodtype==0){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            if(order?.Price_Kind!=1){
                                order.Eat_Number=this.addNum(1,order.Eat_Number)
                            }
                            if(order?.FoodModifys){
                                order.FoodModifys.map(item=>{
                                    if(item.Price_Kind==0){
                                        item.Mas_Num=this.addNum(1,item.Mas_Num)
                                    }
                                })
                            }
                            /**有套餐 */
                            if(order?.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.forEach(food=>{
                                    let num=1
                                    if(food.FoodSetmeal_Number>1 && food.FoodSetmeal_Number>= EatNumber){
                                        num = food.FoodSetmeal_Number / EatNumber
                                        food.FoodSetmeal_Number = Number(Number(Number(food.FoodSetmeal_Number)+Number(num)).toFixed(2))
                                    }else{
                                        food.FoodSetmeal_Number=this.addNum(1,food.FoodSetmeal_Number)
                                    }
                                    if(food.FoodModifys){
                                        let orNumber=0
                                        this.orderMenuList.forEach(or=>{
                                            if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                if(or.Price_Kind==0 && or.Eat_Number<=food.FoodSetmeal_Number){
                                                    or.Eat_Number = food.FoodSetmeal_Number
                                                    orNumber = or.Eat_Number
                                                }else{
                                                    if(or.Price_Kind==0){
                                                        or.Eat_Number= or.Eat_Number + num
                                                        orNumber = or.Eat_Number
                                                    }
                                                }
                                            }
                                        })
                                        food.FoodModifys.forEach(mod=>{
                                            if(mod.Price_Kind==0){
                                                mod.Mas_Num =orNumber
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                }else if(this.foodtype==1){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.choicefoodOrder.Eat_ConsumeID){
                            if(order?.is_taste){
                                if(order.Price_Kind==0){
                                    order.Eat_Number=this.addNum(1,order.Eat_Number)
                                    if(order.FoodModifys){
                                        order.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=this.addNum(1,item.Mas_Num)
                                            }
                                        })
                                    }
                                }
                            }else{
                                order.Eat_Number++
                                if(order.FoodModifys){
                                    order.FoodModifys.map(item=>{
                                        if(item.Price_Kind==0){
                                            item.Mas_Num=this.addNum(1,item.Mas_Num)
                                        }
                                    })
                                }
                            }
                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    if(food.FoodSetmeal_Number>1  && food.FoodSetmeal_Number>= order.Eat_Number){
                                        let num = food.FoodSetmeal_Number / (order.Eat_Number-1)
                                        food.FoodSetmeal_Number = Number(food.FoodSetmeal_Number)+ Number(num)
                                    }else{
                                        food.FoodSetmeal_Number=this.addNum(1,food.FoodSetmeal_Number)
                                    }
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                if(Number(food.FoodSetmeal_Number)>(Number(order.Eat_Number) -1)){
                                                    let masNum = Number(food.FoodSetmeal_Number) / Number(order.Eat_Number)
                                                    item.Mas_Num = Number(item.Mas_Num) + masNum
                                                }else{
                                                    item.Mas_Num=this.addNum(1,item.Mas_Num)
                                                }
                                                this.orderMenuList.map(or=>{
                                                    if(or.Eat_ConsumeID==food.Eat_ConsumeID && or?.is_taste==true){
                                                        or.Eat_Number = item.Mas_Num
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }
                        }
                    })
                }
            }
        },
         /**减少数量 */
        clickNumReduce(index,item,adType){
            if(this.foodtype==1){
                this.$alert('套餐菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.Is_Combo){
                this.$alert('组合菜品下的菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(!this.disRadioOrder?.is_taste){
                if(this.disRadioOrder?.ConsumeOperations){
                    let OperateType= this.disRadioOrder?.ConsumeOperations[0]?.OperateType
                    if(OperateType==1){
                        this.$message.warning('折扣菜品,无法增加数量')
                        return
                    }else if(OperateType==2){
                        this.$message.warning('菜品赠送,无法增加数量')
                        return
                    }else if(OperateType==3){
                        this.$message.warning('菜品改价,无法增加数量')
                        return
                    }else if(OperateType==4){
                        this.$message.warning('会员价菜品,无法增加数量')
                        return
                    }
                }
            }
            if(this.disRadioOrder?.Is_Gift==true){
                this.$alert('赠送菜品无法调整数量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(index!=null){
                if(item.Food_Status==0){
                    this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                }else{
                    if(this.orderMenuList[index].Eat_Number>1){
                        /**判断是否是辅助单位菜品 */
                        if(this.orderMenuList[index].Food_AssSize){
                            if(adType==null){
                                this.adjusquantityShow=true;
                                return
                            }
                        }
                        this.orderMenuList[index].Eat_Number=this.accSub(1,this.orderMenuList[index].Eat_Number);
                        if(this.orderMenuList[index].FoodModifys){
                            this.orderMenuList[index].FoodModifys.map(item=>{
                                if(item.Price_Kind==0){
                                    item.Mas_Num=this.accSub(1,item.Mas_Num);
                                }
                            })
                        }
                        this.posFoodNumbermethod(2)
                    }
                }
            }else{
                if(this.disRadioclass>-1){
                    if(this.disRadioOrder.Food_Status!=-1){
                            this.$alert('该菜品已下单暂时无法调整', '提示', {confirmButtonText: '确定',});
                    }else{
                        this.orderMenuList = JSON.parse(JSON.stringify(this.orderMenuList))
                        if(this.orderMenuList[this.disRadioclass].Eat_Number>1){
                            /**判断是否是辅助单位菜品 */
                            if(this.orderMenuList[this.disRadioclass].Food_AssSize){
                                if(adType==null){
                                    this.adjusquantityShow=true;
                                    return
                                }
                            }
                            let EatNumber= this.orderMenuList[this.disRadioclass].Eat_Number
                            /**选择的口味 */
                            if(this.disRadioOrder?.is_taste){
                                this.orderMenuList[this.disRadioclass].Eat_Number=this.accSub(1,this.orderMenuList[this.disRadioclass].Eat_Number);
                                this.orderMenuList.map(order=>{
                                    if(order.FoodModifys){
                                        order.FoodModifys.map(item=>{
                                            if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID && order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                                item.Mas_Num=this.accSub(1,item.Mas_Num);
                                            }
                                        })
                                    }
                                    if(order.FoodSetmeals){
                                        order.FoodSetmeals.map(food=>{
                                            if(food.FoodModifys){
                                                food.FoodModifys.map(item=>{
                                                    if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID && food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                                        item.Mas_Num=this.accSub(1,item.Mas_Num);
                                                    }
                                                })
                                            }
                                        })
                                    }
                                })
                            }else{
                                /**选的普通菜品 */
                                if(this.foodtype==0){
                                    this.orderMenuList.map(order=>{
                                        /**普通菜品调整 */
                                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                            if(order.Price_Kind!=1){
                                                if(order.Eat_Number>1){
                                                    order.Eat_Number=this.accSub(1,order.Eat_Number);
                                                }
                                            }
                                            /**有口味 */
                                            if(order.FoodModifys){
                                                order.FoodModifys.map(item=>{
                                                    if(item.Price_Kind==0){
                                                        if(item.Mas_Num>1){
                                                            item.Mas_Num=this.accSub(1,item.Mas_Num);
                                                        }
                                                    }
                                                })
                                            }
                                            /**有套餐 */
                                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                                order.FoodSetmeals.map(food=>{
                                                    let num=1
                                                    if(food.FoodSetmeal_Number>1 && food.FoodSetmeal_Number>= EatNumber){
                                                        num = food.FoodSetmeal_Number / EatNumber
                                                        food.FoodSetmeal_Number = this.accSub(num,food.FoodSetmeal_Number)
                                                    }else{
                                                       food.FoodSetmeal_Number = this.accSub(num,food.FoodSetmeal_Number)
                                                    }
                                                    if(food.FoodModifys){
                                                        let orNumber=0
                                                        this.orderMenuList.map(or=>{
                                                            if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                                if(or.Price_Kind==0 && or.Eat_Number<=food.FoodSetmeal_Number){
                                                                    or.Eat_Number = food.FoodSetmeal_Number
                                                                    orNumber = or.Eat_Number
                                                                }else{
                                                                    if(or.Price_Kind==0){
                                                                        or.Eat_Number = this.accSub(num,or.Eat_Number)
                                                                        orNumber = or.Eat_Number
                                                                    }
                                                                }
                                                            }
                                                        })
                                                        food.FoodModifys.map(item=>{
                                                            if(item.Price_Kind==0){
                                                                item.Mas_Num =orNumber
                                                            }
                                                        })
                                                    }
                                                })
                                            }
                                        }
                                    })
                                }else if(this.foodtype==1){/**选的套餐菜品 */
                                    this.orderMenuList.map(order=>{
                                        if(order.Eat_ConsumeID==this.choicefoodOrder.Eat_ConsumeID){
                                            if(Number(order.Eat_Number)>1){
                                                if(order?.is_taste){
                                                    if(order.Price_Kind==0){
                                                        order.Eat_Number =this.accSub(1,order.Eat_Number)
                                                    }
                                                }else{
                                                    order.Eat_Number =this.accSub(1,order.Eat_Number)
                                                }
                                                if(order.FoodModifys){
                                                    order.FoodModifys.map(item=>{
                                                        if(item.Price_Kind==0){
                                                            item.Mas_Num=this.accSub(1,item.Mas_Num)
                                                        }
                                                    })
                                                }
                                                if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                                    order.FoodSetmeals.map(food=>{
                                                        if(food.FoodSetmeal_Number>=EatNumber){
                                                            let num = food.FoodSetmeal_Number / EatNumber
                                                            food.FoodSetmeal_Number = this.accSub(num,food.FoodSetmeal_Number)
                                                        }else{
                                                            food.FoodSetmeal_Number = this.accSub(1,food.FoodSetmeal_Number)
                                                        }
                                                        if(food.FoodModifys){
                                                            let _orNumbers=0
                                                            this.orderMenuList.map((or,index)=>{
                                                                if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                                    if(or.Price_Kind==0){
                                                                        _orNumbers = or.Eat_Number
                                                                    }
                                                                }
                                                            })
                                                            food.FoodModifys.map(item=>{
                                                                if(item.Price_Kind==0){
                                                                    item.Mas_Num =food.FoodSetmeal_Number
                                                                    this.orderMenuList.map(or=>{
                                                                        if(or.Eat_ConsumeID==food.Eat_ConsumeID && or?.is_taste==true){
                                                                            or.Eat_Number = item.Mas_Num
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                        }
                                                    })
                                                }
                                            }
                                        }
                                    })
                                }
                            }
                            this.posFoodNumbermethod(2)
                        }   
                    }
                }else{
                    this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
                }
            }
        },
        /**单选菜品口味返回 */
        confirm(optFlavors,disRadioOrder,temporderMenuList){
            this.demandShow =false;
            this.synOrderMethodsNum(1)
            /**把之前添加的口味先删除 */
            for(let i=0; i<this.orderMenuList.length; i++){
                if(this.orderMenuList[i].Food_Status==-1){
                    this.orderMenuList[i].Eat_MasName=null
                    this.orderMenuList[i].FoodModifys=[]
                    //如果套餐不为空
                    if(this.orderMenuList[i].FoodSetmeals){
                        for (let j = 0; j < this.orderMenuList[i].FoodSetmeals.length; j++) {
                            this.orderMenuList[i].FoodSetmeals[j].FoodModifys=[]
                            this.orderMenuList[i].FoodSetmeals[j].Eat_MasName=null
                        }
                    }
                    if(this.orderMenuList[i]?.is_taste){
                        this.orderMenuList.splice(i, 1);
                        i--;
                    }
                }
            }
            if(this.foodSetmealsclass!=-1){
                this.disRadioOrder = Object.assign(this.disRadioOrder,{foodSetmealsclass:this.foodSetmealsclass})
            }
            let _opt={disRadioOrder:this.disRadioOrder,optFlavors:optFlavors}
            if(this.aloneMethod.length>0){
                if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===this.disRadioOrder.Eat_ConsumeID)==-1){
                    this.aloneMethod.push(_opt)
                }
                this.aloneMethod.some(alone=>{
                    if(alone.disRadioOrder.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                        alone.optFlavors = optFlavors;
                        alone.disRadioOrder=this.disRadioOrder;
                    }
                })
            }else{
                this.aloneMethod.push(_opt)
            }
            /**有同步的菜品 */
            if(temporderMenuList){
                this.temporderMethod(temporderMenuList,optFlavors)
            }
            //单独口味添加方法
            this.aloneAddMethods()
            //再去执行全单口味
            this.mallMethodMethods()
            this.synOrderMethodsNum(2)
        },
        aloneAddMethods(){
            if(this.aloneMethod.length==0){
                return
            }
            this.aloneMethod.map(am=>{
                this.synchMethods(am.optFlavors,'',am.disRadioOrder)
                this.TemporderMenuList=[]
            })
        },
        /**同步菜品口味要求做法 */
        temporderMethod(temporderMenuList,optFlavors){
            temporderMenuList.forEach(tm=>{
                if(tm.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                    tm.is_type=0
                }
                //需要添加的菜
                if(tm.is_type==1){
                    let _opt={disRadioOrder:tm,optFlavors:optFlavors}
                    if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===tm.Eat_ConsumeID)==-1){
                        this.aloneMethod.push(_opt)
                    }else{
                        this.aloneMethod.forEach(alone=>{
                            if(alone.disRadioOrder.Eat_ConsumeID==tm.Eat_ConsumeID && tm.is_type==1){
                                optFlavors.forEach(fm=>{
                                    let optype=0
                                    alone.optFlavors.forEach(op=>{
                                        if(op.Mas_AutoID==fm.Mas_AutoID){
                                            optype=1
                                        }
                                    })
                                    if(optype==0){
                                        alone.optFlavors.push(fm)
                                    }
                                })
                            }
                        })
                    }
                }
            })
        },
        /**同步订单口味要求的数量 */
        synOrderMethodsNum(type){
            if(type==1){
                this.MethodNumList=[]
                this.orderMenuList.map(order=>{
                    if(!order?.is_taste){
                        if(order?.FoodModifys){
                            order.FoodModifys.map(food=>{
                                let _metNum={Eat_ConsumeID:order.Eat_ConsumeID,Mas_Num:food.Mas_Num,Mas_AutoID:food.Mas_AutoID,is_taste:false,type:1}
                                this.MethodNumList.push(_metNum)
                            })
                        }
                        //套餐
                        if(order?.FoodSetmeals){
                            order.FoodSetmeals.map(set=>{
                                if(set.FoodModifys){
                                    set.FoodModifys.map(food=>{
                                        let _metNum={Eat_ConsumeID:set.Eat_ConsumeID,Mas_Num:food.Mas_Num,Mas_AutoID:food.Mas_AutoID,is_taste:false,type:2}
                                        this.MethodNumList.push(_metNum)
                                    })
                                }
                            })
                        }
                    }else{
                        let _metNum={Eat_ConsumeID:order.Eat_ConsumeID,Mas_Num:order.Eat_Number,Mas_AutoID:order.Mas_AutoID,is_taste:true,type:1}
                        this.MethodNumList.push(_metNum)
                    }
                })
            }
            if(type==2){
                this.orderMenuList.map(order=>{
                    if(!order?.is_taste){
                        this.MethodNumList.map(met=>{
                            if(met.is_taste==false){
                                if(order?.FoodModifys){
                                    order.FoodModifys.map(food=>{
                                        if(order.Eat_ConsumeID==met.Eat_ConsumeID && met.type==1 && food.Mas_AutoID==met.Mas_AutoID){
                                            food.Mas_Num=met.Mas_Num
                                        }
                                    })
                                }
                                //套餐
                                if(order?.FoodSetmeals){
                                    order.FoodSetmeals.map(set=>{
                                        if(set.FoodModifys){
                                            set.FoodModifys.map(food=>{
                                                if(set.Eat_ConsumeID==met.Eat_ConsumeID && met.type==2 && food.Mas_AutoID==met.Mas_AutoID){
                                                    food.Mas_Num=met.Mas_Num
                                                }
                                            })
                                        }
                                    })
                                }
                            }else if(met.is_taste==true){
                                //订单里的口味要求
                                if(order.Eat_ConsumeID==met.Eat_ConsumeID && met.type==1 && order.Mas_AutoID==met.Mas_AutoID){
                                    order.Eat_Number=met.Mas_Num
                                }
                            }
                        })
                    }else{
                        this.MethodNumList.map(met=>{
                            if(met.is_taste==true){
                                //订单里的口味要求
                                if(order.Eat_ConsumeID==met.Eat_ConsumeID && met.type==1 && order.Mas_AutoID==met.Mas_AutoID){
                                    order.Eat_Number=met.Mas_Num
                                }
                            }
                        })
                    }
                })
            }
        },
        /**绑定菜品口味做法返回 */
        BindPosconfirm(optFlavors){
            this.BindPosFoods.Is_BindEatMas=false
            this.onPlaceFoods(this.BindPosFoods)
            this.BindPosFoods=''
            this.confirm(optFlavors,this.disRadioOrder,'')
        },
        /**提取暂存返回 */
        lockersConfirm(loakersDetailed){
            loakersDetailed.forEach(it=>{
                this.orderMenuList.push(it);
            })

            this.orderMenuList.forEach(order=>{
                loakersDetailed.forEach(loak=>{
                    if(order.Eat_ConsumeID==loak.Eat_ConsumeID){
                        let _price = Number(order.Eat_SalePrice).toFixed(2) - Number(order.Eat_CheckPrice).toFixed(2)
                        this.discountMoneuy +=_price  * order.Eat_Number
                    }
                })
            })
            this.$message.success("提取暂存成功");
            this.posFoodNumbermethod(5)
            this.lockersShow = false
        },
        synchMethods(optFlavors,temporderMenuList,disRadioOrder){
            /**同步菜品 */
            this.orderMenuList.map((order,index)=>{
                if(!order?.is_taste){
                    let data = this.optFlavorsMap(optFlavors,disRadioOrder)
                    disRadioOrder.Eat_MasName = data._MasName
                    disRadioOrder.FoodModifys = data._foodModifys
                    //disRadioOrder.FoodSetmeal_Price = data._Price
                    disRadioOrder.FoodModifys.map(mod=>{
                        if(mod.Price_Kind==0 && disRadioOrder?.FoodSetmeal_Number){
                            mod.Mas_Num = disRadioOrder.FoodSetmeal_Number
                        }
                    })
                    let tasteMenu = this.tasteMenu(optFlavors,order,disRadioOrder,0)
                    if(tasteMenu.length>0){
                        tasteMenu.forEach(item=>{
                            if(item.Eat_ConsumeID==order.Eat_ConsumeID && !order.is_taste){
                                index++
                                let data = this.optFlavorsMap(optFlavors,order)
                                order.Eat_MasName = data._MasName;
                                order.FoodModifys=data._foodModifys;
                                order.ModiPrice =  data._Price
                                this.orderMenuList.splice(index,0,item)
                            }else{
                                order.FoodSetmeals?.forEach(food=>{
                                    if(item.Eat_ConsumeID==food.Eat_ConsumeID && !order?.is_taste) {
                                        food.Eat_MasName = data._MasName;
                                        food.FoodModifys=data._foodModifys;
                                        food.ModiPrice =  data._Price
                                        index++
                                        this.orderMenuList.splice(index,0,item)
                                    }
                                }); 
                            }
                        })
                    }else{
                        if(disRadioOrder.Eat_ConsumeID==order.Eat_ConsumeID && !order.is_taste){
                            index++
                            let data = this.optFlavorsMap(optFlavors,order)
                            order.Eat_MasName = data._MasName;
                            order.FoodModifys=data._foodModifys;
                            order.ModiPrice =  data._Price
                        }
                        if(!order.is_taste){
                            order.FoodSetmeals?.forEach(food=>{
                                if(food.Eat_ConsumeID==disRadioOrder.Eat_ConsumeID){
                                    food.Eat_MasName = data._MasName;
                                    food.FoodModifys=data._foodModifys;
                                    food.ModiPrice =  data._Price
                                }
                            })  
                        }
                    }
                }
            })
            //}
        },
        /**下单前口味添加到菜单 */
        tasteMenu(optFlavors,item,disRadioOrder,IsAllRequired){
            let _item=[]
            if(optFlavors){
                optFlavors.forEach(num=>{
                    if(num.Is_AddMoney==true){
                        /**把口味添加到菜单 */
                        let param={
                            Eat_ConsumeID:item.Eat_ConsumeID,
                            Mas_AutoID:num.Mas_AutoID,
                            EAT_XFBMID:item.EAT_XFBMID,
                            EAT_XFCode:item.EAT_XFCode,
                            EAT_XFName:num.Mas_Name+'@'+num.Mas_Price,
                            Eat_Number:1,
                            Eat_CheckPrice:num.Mas_Price,
                            Eat_SalePrice:num.Mas_Price,
                            ModiPrice:0,
                            Food_Status:-1,
                            Is_Discounted:item.Is_Discounted,
                            Is_Seasonal:item.Is_Seasonal,
                            RemainNum:item.RemainNum,
                            Is_Limited:item.Is_Limited,
                            Is_ChangedNum:item.Is_ChangedNum,
                            Is_ChargeForService:item.Is_ChargeForService,
                            Is_SetMeal:item.Is_SetMeal,
                            is_taste:true,
                            IsAllRequired:IsAllRequired,
                            Price_Kind:num.Price_Kind
                        }
                        if(num.Price_Kind==0){
                            param.Eat_Number=item.Eat_Number
                        }
                        if(disRadioOrder!=''){
                            if(num.Price_Kind==0 && disRadioOrder?.FoodSetmeal_Number){
                                param.Eat_Number=disRadioOrder.FoodSetmeal_Number
                            }
                            param.Eat_ConsumeID = disRadioOrder.Eat_ConsumeID   
                        }
                        _item.push(param);
                    }
                })
            }
            return _item;
        },
        /**口味变动放到临时订单集合 */
        tempoptFlavorAdd(item,optFlavorsList,type,temptype){
            if(temptype==true){
                if(item){
                    this.TemporderMenuList.push(item);
                }
            }
            if(optFlavorsList.length>0 && optFlavorsList != undefined){
                optFlavorsList.map(op=>{
                    this.TemporderMenuList.push(op)
                })
            }
            if(type==true){
                this.orderMenuList=this.TemporderMenuList
            }
            // this.orderMenuList
            // this.radioClick(this.orderMenuList.length-1,item);
        },
        /**套餐口味变动 */
        optFlavorsMap(optFlavors,item){
            let data =''
            let _foodModifys=[]
            let _MasName ='';
            let _Price = 0
            if(optFlavors){
                optFlavors.map(num=>{
                    _MasName += num.Mas_Name+'/';
                    if(num.Is_AddMoney==true){
                        //按量加价
                        if(num.Price_Kind==0){
                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:item.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                        }
                        //按次加价
                        if(num.Price_Kind==1){
                            _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                        }
                        _Price = Number(_Price) + Number(0)
                    }else{
                        _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:0});
                    }
                    
                })
            }
            data ={_MasName:_MasName,_foodModifys:_foodModifys,_Price:_Price}
            return data
        },
        /**减法函数 */
        accSub(arg1,arg2){ 
             var r1,r2,m,n;
            try{r1=arg1.toString().split(".")[1].length}catch(e){r1=0}
            try{r2=arg2.toString().split(".")[1].length}catch(e){r2=0}
            m=Math.pow(10,Math.max(r1,r2));
            //动态控制精度长度
            n=(r1>=r2)?r1:r2;
            return Number(((arg2*m-arg1*m)/m).toFixed(n));
        },
        /**全选菜品口味 */
        confirmall(optFlavors){
            this.TemporderMenuList=[]
            this.demandFullShow=false;
            this.synOrderMethodsNum(1)
            /**把之前添加的口味先删除 */
            for(let i=0; i<this.orderMenuList.length; i++){
                if(this.orderMenuList[i].Food_Status==-1){
                    this.orderMenuList[i].Eat_MasName=null
                    this.orderMenuList[i].FoodModifys=[]
                    //如果套餐不为空
                    if(this.orderMenuList[i].FoodSetmeals){
                        for (let j = 0; j < this.orderMenuList[i].FoodSetmeals.length; j++) {
                            this.orderMenuList[i].FoodSetmeals[j].FoodModifys=[]
                            this.orderMenuList[i].FoodSetmeals[j].Eat_MasName=null
                        }
                    }
                    if(this.orderMenuList[i]?.is_taste){
                        this.orderMenuList.splice(i, 1);
                        i--;
                    }
                }
            }
            this.orderMenuList.map(order=>{
                let _order ={order:order,optFlavors:JSON.parse(JSON.stringify(optFlavors))} 
                this.mallMethod.push(_order)
            })
            //单独口味添加方法
            this.aloneAddMethods()
            //全单添加方法
            this.mallMethodMethods()
            this.synOrderMethodsNum(2)
        },
        //全单口味添加方法
        mallMethodMethods(){
            if(this.mallMethod.length==0){
                return
            }
            this.orderMenuList.map((item,index)=>{
                let _type=0
                let _optFlavors=[]
                /**全单口味里面已有的菜品做法才会同步 */
                this.mallMethod.map(mall=>{
                    if(mall.order.Eat_ConsumeID==item.Eat_ConsumeID){
                        _type=1
                        _optFlavors = mall.optFlavors
                    }
                })
                if(item.Food_Status==-1 && !item.is_taste){
                    let _foodModifys=[]
                    let _Eat_MasName =''
                    if(item.Eat_MasName){
                        _Eat_MasName = item.Eat_MasName
                    }
                    if(_type==1){
                       _optFlavors.map(num=>{
                            _Eat_MasName += num.Mas_Name+'/';
                            //加价的做法口味
                            if(num?.Is_AddMoney==true){
                                //按量加价
                                if(num.Price_Kind==0){
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:item.Eat_Number,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:1});
                                }
                                //按次加价
                                if(num.Price_Kind==1){
                                    _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:1});
                                }
                            }else{
                                //不加价的做法口味
                                _foodModifys.push({Mas_AutoID:num.Mas_AutoID,Mas_Code:num.Mas_Code,Mas_Name:num.Mas_Name,Mas_Num:1,Mas_Price:num.Mas_Price,Price_Kind:num.Price_Kind,Is_AddMoney:num.Is_AddMoney,IsAllRequired:1});
                            }
                        })
                        this.tempoptFlavorAdd(item,this.tasteMenu(_optFlavors,item,'',1),true,true)
                    }else{
                        this.tempoptFlavorAdd(item,'',true,true)
                    }
                    if(item.FoodSetmeals!=null){
                        item.FoodSetmeals.forEach(food=>{
                            if(!food.Eat_MasName){
                                food.Eat_MasName=''
                            }
                            food.Eat_MasName += _Eat_MasName
                            if(food.FoodModifys){
                                _foodModifys.forEach(fm=>{
                                    food.FoodModifys.push(Object.assign({},fm))
                                })
                            }else{
                                food.FoodModifys =JSON.parse(JSON.stringify(_foodModifys));
                            }
                            food.FoodModifys?.forEach(mod=>{
                                if(mod.Price_Kind==0 && food?.FoodSetmeal_Number){
                                    mod.Mas_Num = food.FoodSetmeal_Number
                                }
                            })

                            let _food = {
                                Eat_ConsumeID:food.Eat_ConsumeID,
                                SubCategory_ID:item.SubCategory_ID,
                                EAT_XFBMID:item.EAT_XFBMID,
                                EAT_XFCode:item.EAT_XFCode,
                                EAT_XFName:food.FoodSetmeal_Name,
                                Eat_Number:food.FoodSetmeal_Number,
                                Eat_CheckPrice:item.Eat_CheckPrice,
                                Eat_SalePrice:item.Eat_SalePrice,
                                Eat_MasName:item.Eat_MasName,
                                Food_Status:item.Food_Status,
                                Is_SetMeal:true,
                                FoodSetmeal_ID:food.FoodSetmeal_ID,
                                FoodSetmeal_Code:food.FoodSetmeal_Code,
                                FoodSetmeal_Number:food.FoodSetmeal_Number,
                                FoodSetmeal_Price:food.FoodSetmeal_Price,
                                FoodModifys:food.FoodModifys,
                                is_type:0,
                                Food_Speed:food.Food_Speed
                            }
                            this.tempoptFlavorAdd('',this.tasteMenu(_optFlavors,_food,'',1),true,true)
                        })
                    }

                    
                    item.Eat_MasName = _Eat_MasName;
                    if(item.FoodModifys){
                        _foodModifys.map(fm=>{
                            item.FoodModifys.push(fm)
                        })
                    }else{
                        item.FoodModifys =JSON.parse(JSON.stringify(_foodModifys));
                    }
                }
                else{
                    this.TemporderMenuList.push(item);
                }
            })
            this.TemporderMenuList=[]
        },
        /**数量*/
        clickQuantity(){
            if(this.foodtype==1){
                this.$alert('套餐菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioOrder?.Is_Combo){
                this.$alert('组合菜品下的菜品不允许单独改量', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioclass>-1){
                if(this.disRadioOrder?.Is_Gift==true){
                    this.$alert('赠送的菜品无法调整数量', '提示', {confirmButtonText: '确定',});
                }else{
                    this.adjusquantityShow = true;
                    
                }
            }
        },
        /**数量返回 */
        quantityReturn(number,assNumber){
            this.adjusquantityShow = false;
            /**选择的口味 */
            if(this.disRadioOrder?.is_taste){
                this.orderMenuList[this.disRadioclass].Eat_Number= number
                this.orderMenuList.map(order=>{
                    if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID && !order?.is_taste){
                        if(order.FoodModifys){
                            order.FoodModifys.map(item=>{
                                if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                    item.Mas_Num=number
                                }
                            })
                        }
                        /**有套餐 */
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.FoodModifys){
                                    food.FoodModifys.map(item=>{
                                        if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                            item.Mas_Num=number
                                        }
                                    })
                                }
                            })
                        }
                    }else{
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.map(food=>{
                                if(food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Mas_AutoID==this.disRadioOrder.Mas_AutoID){
                                                item.Mas_Num=number
                                            }
                                        })
                                    }
                                }
                            })
                        }
                    }
                })
            }else{
                /**普通菜品数量调整 */
                if(this.foodtype==0){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                            /**有套餐 */
                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    if(order.Eat_Number>1){
                                        food.FoodSetmeal_Number = food.FoodSetmeal_Number / order.Eat_Number
                                        food.FoodSetmeal_Number = Number(Number(Number(food.FoodSetmeal_Number) * number).toFixed(2))
                                    }else{
                                        food.FoodSetmeal_Number= Number(Number(Number(food.FoodSetmeal_Number) * number).toFixed(2))
                                    }
                                    // if(food.FoodSetmeal_Number>1){
                                    //     let num = food.FoodSetmeal_Number / number
                                    //     food.FoodSetmeal_Number = Number(food.FoodSetmeal_Number)+ Number(num)
                                    // }else{
                                    //     food.FoodSetmeal_Number=number
                                    // }
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=food.FoodSetmeal_Number
                                            }
                                        })
                                        this.orderMenuList.map(or=>{
                                            if(or.Eat_ConsumeID==food.Eat_ConsumeID){
                                                if(or.Price_Kind==0){
                                                    or.Eat_Number=food.FoodSetmeal_Number
                                                }
                                            }
                                        })
                                    }
                                })
                            }
                            if(order.Price_Kind!=1){
                                order.Eat_Number=number;
                            }
                            if(order.FoodModifys){
                                order.FoodModifys.map(item=>{
                                    if(item.Price_Kind==0){
                                        item.Mas_Num=number
                                    }
                                })
                            }
                        }
                    })
                }else if(this.foodtype==1){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.choicefoodOrder.Eat_ConsumeID){
                            if(order?.is_taste){
                                if(order.Price_Kind==0){
                                    order.Eat_Number++
                                    if(order.FoodModifys){
                                        order.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=number
                                            }
                                        })
                                    }
                                }
                            }else{
                                order.Eat_Number=number
                                if(order.FoodModifys){
                                    order.FoodModifys.map(item=>{
                                        if(item.Price_Kind==0){
                                            item.Mas_Num=number
                                        }
                                    })
                                }
                            }
                            if(order.FoodSetmeals && order?.Is_SetMealCountAvgPrice==false){
                                order.FoodSetmeals.map(food=>{
                                    food.FoodSetmeal_Number=number
                                    if(food.FoodModifys){
                                        food.FoodModifys.map(item=>{
                                            if(item.Price_Kind==0){
                                                item.Mas_Num=number
                                            }
                                        })
                                    }
                                })
                            }
                        }else{
                           if(order?.is_taste){
                                if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    if(order?.Price_Kind==0){
                                            order.Eat_Number = number
                                    }
                                }
                            }
                        }
                    })
                }
            }
            if(this.orderMenuList[this.disRadioclass].Food_AssSize){
                this.orderMenuList[this.disRadioclass].AssSizeNumber = Number(assNumber)
            }
            this.posFoodNumbermethod(5)
        },
        /**辅助单位选择返回 */
        assSizeConfirm(assSizeInfo,priceNum,assSizeNum){
            assSizeInfo = Object.assign(assSizeInfo, {Number:priceNum,AssSizeNumber:Number(assSizeNum)})
            this.onPlaceFoods(assSizeInfo)
            this.assSizeShow=false
            this.posFoodNumbermethod(5)
        },
        /**改价*/
        clickPriceChange(){
            if(this.disRadioclass>-1){
                if(this.disRadioOrder.Eat_CheckMoney<=0){
                    this.$message.warning("该菜品无法调整");
                    return;
                }
                if(this.disRadioOrder?.is_taste){
                    this.$message.warning("口味无法调整");
                    return;
                }
                if(this.disRadioOrder.Food_Type==4){
                    this.$message.warning("口味无法调整");
                    return;
                }
                if(this.disRadioOrder?.Eat_TcItemList){
                    this.$message.warning("套餐菜品无法进行改价操作");
                    return
                }
                if(this.disRadioOrder.Is_Seasonal==false){
                    this.$message.warning("该菜品不是时价菜,不能改价");
                    return
                }
                if(this.disRadioOrder?.ConsumeOperations){
                    let OperateType= this.disRadioOrder?.ConsumeOperations[0]?.OperateType
                    if(OperateType==1){
                        this.$message.warning('该菜品已进行过折扣,无法再进行改价')
                    }else if(OperateType==2){
                        this.$message.warning('该菜品已进行过赠送,无法再进行改价')
                    }else if(OperateType==4){
                        this.$message.warning('该菜品已进行过会员价,无法再进行改价')
                    }else if(OperateType==5){
                        this.$message.warning('该菜品已设置促销价,无法再进行改价')
                    }else if(OperateType==6){
                        this.$message.warning('该菜品已进行过第二份折扣,无法再进行改价')
                    }else{
                        this.changePriceShow=true 
                        
                    }
                }else{
                    this.changePriceShow=true 
                    
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {confirmButtonText: '确定',});
            }
        },
        /**改价返回 */
        changepriceClick(price,reasontype,reasontContent){
            if(this.disRadioOrder){
                this.changePriceShow = false;
                let order=this.disRadioOrder;
               
                let _Operations={
                    OperateType:3,
                    OperateReason:reasontContent,
                    Operator:this.userInfo?.Login_Name,
                    Project_AutoID:'',
                    Project_Name:'',
                    Project_Kind:0,
                    Project_Rate:0,
                    Old_Price:order.Eat_SalePrice
                }
                if(!Object.keys(this.checkUser).length==0){
                    _Operations.Operator=this.checkUser.Login_Name; //获取用户id
                }
                if(order.ConsumeOperations?.length>0 && order.ConsumeOperations[0].OperateType==3){//有修改过价格 延用之前的原价
                    _Operations.Old_Price=order.ConsumeOperations[0].Old_Price
                }
                order.ConsumeOperations=[_Operations];
                order.Eat_CheckPrice = Number(price);
                order.Eat_SalePrice=Number(price);
                this.checkUser={}
            }
        },
        /**菜品换单位 */
        unitClick(){
            if(this.disRadioOrder?.is_taste){
                this.$alert('口味无法调整', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.disRadioclass>-1){
                let _foodtype=0
                this.multipleSizeFoods.map(food=>{
                    if(food.Food_ID==this.disRadioOrder.EAT_XFBMID){
                        _foodtype=1
                        this.theMultipleSizeFoods = food
                        this.unitShow=true
                        
                        this.unitType=1
                        return true
                    }else{
                        food.MultipleSizeFoods.some(mu=>{
                            if(mu.Food_ID==this.disRadioOrder.EAT_XFBMID){
                                _foodtype=1
                                this.theMultipleSizeFoods = food
                                this.unitShow=true
                                
                                this.unitType=1
                                return true
                            }
                        })
                    }
                })
                if(_foodtype==0){
                    this.$alert('该菜品没有多单位可更换', '提示', {
                        confirmButtonText: '确定',
                    });
                }
            }else{
                this.$alert('请先选择左边的菜品', '提示', {
                    confirmButtonText: '确定',
                });
            }
        },
        /**套餐下菜品点击 */
        EatTcClick(food,index,item){
            this.disRadioOrder = food
            this.choicefoodOrder=item
            this.foodSetmealsclass=index
            this.foodtype=1
            this.orderMenuList.forEach((order,i)=>{
                if(order.FoodSetmeals){
                    order.FoodSetmeals.forEach(fd=>{
                        if(fd.Eat_ConsumeID==food.Eat_ConsumeID){
                            this.disRadioclass=i;
                        }
                    })
                }
            })
        },
        /**套餐返回 */
        setMealClick(setMealNum,posFoodSetMeal){
            /**1.套餐换菜返回,清理掉之前的 */
            if(this.tcChangeType==1){
                this.clickDelete(this.disRadioclass,this.disRadioOrder)
            }
            this.setMealShow = false;
            let _FoodSetmeals=[];
            let _setNumber= setMealNum
            this.orderNumber = setMealNum
            let SoldError=''

            let foodList=[];//记录套餐明细 口味做法
            /**添加到订单中 */
            posFoodSetMeal.SetMealInfo.SetMealKinds.forEach(item=>{
                item.SetMealDetails.forEach(food=>{
                    if(food.Is_Selected){
                        if(food.Is_SoldOut==true){
                            SoldError=food.Food_Name+'已沽清,无法加入订单中'
                        }
                        if(posFoodSetMeal?.Is_SetMealCountAvgPrice){
                            _setNumber = 1
                        }
                        let eatConsume={
                            SubCategory_ID:food.SubCategory_ID,
                            FoodSetmeal_ID:food.Food_ID,
                            FoodSetmeal_Code:food.Food_Code,
                            FoodSetmeal_Name:food.Food_Name,
                            FoodSetmeal_Number:food.Food_Number * _setNumber,
                            FoodSetmeal_Price:food.Food_TcPrice,
                            FoodSetmeal_Size:food.Food_Size,
                            FoodSaleMoney:0,
                            FoodModifys:null,
                            Eat_MasName:'',
                            ModiPrice:0,
                            Food_Status:-1,
                            Eat_ConsumeID:newGuid()
                        }
                        _FoodSetmeals.push(eatConsume)

                        if(food._FoodModifys){
                            foodList.push({
                                item:eatConsume,
                                FoodModifys:food._FoodModifys
                            });
                        }
                    }
                })
            })
            if(SoldError){
                this.$message.warning(SoldError);
                return
            }
            let _item={
                SubCategory_ID:posFoodSetMeal.SubCategory_ID,
                SubCategory_Code:posFoodSetMeal.SubCategory_Code,
                EAT_XFBMID:posFoodSetMeal.Food_ID,
                EAT_XFCode:posFoodSetMeal.Food_Code,
                EAT_XFName:posFoodSetMeal.Food_Name,
                Eat_Number:setMealNum,
                Eat_CheckPrice:posFoodSetMeal.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:posFoodSetMeal.Food_Price,
                Eat_VipPrice:posFoodSetMeal.Food_VipPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_Speed:'',
                Eat_XFSize:posFoodSetMeal.Food_Size,
                FoodModifys:null,
                FoodSetmeals:_FoodSetmeals,
                Is_Special:posFoodSetMeal.Is_Special,//是否是推荐菜
                Is_Discounted:posFoodSetMeal.Is_Discounted,
                Is_Seasonal:posFoodSetMeal.Is_Seasonal,
                RemainNum:posFoodSetMeal.RemainNum,
                Is_Limited:posFoodSetMeal.Is_Limited,
                Is_ChangedNum:posFoodSetMeal.Is_ChangedNum,
                Is_ChargeForService:posFoodSetMeal.Is_ChargeForService,
                Is_SetMeal:posFoodSetMeal.Is_SetMeal,
                FoodProperty_Dimension:'套',
                Is_SetMealCountAvgPrice:false, //是否是人均计价套餐
                Eat_ConsumeID:newGuid(),
                Exchanged_Number:0,
                ConsumeOperations:[]
            };
            if(posFoodSetMeal?.Is_SetMealCountAvgPrice){
                _item.Is_SetMealCountAvgPrice = posFoodSetMeal?.Is_SetMealCountAvgPrice
            }
            //促销价
            if(posFoodSetMeal?.Promotion_Price){
                _item.Eat_CheckPrice = posFoodSetMeal?.Promotion_Price
                _item.Promotion_Price = posFoodSetMeal?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            this.orderMenuList.push(_item);

            /**给套餐明细 添加口味做法 */
            foodList?.forEach(it=>{
                let _opt={disRadioOrder:it.item,optFlavors:it.FoodModifys}
                if(this.aloneMethod.length>0){
                    if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===_opt.disRadioOrder.Eat_ConsumeID)==-1){
                        this.aloneMethod.push(_opt)
                    }
                    this.aloneMethod.some(alone=>{
                        if(alone.disRadioOrder.Eat_ConsumeID==_opt.disRadioOrder.Eat_ConsumeID){
                            alone.optFlavors = it.FoodModifys;
                            alone.disRadioOrder=_opt.disRadioOrder;
                        }
                    })
                }else{
                    this.aloneMethod.push(_opt)
                }
                this.synchMethods(it.FoodModifys,'',it.item);
            })


            this.radioClick(this.orderMenuList.length-1,_item);
            this.posFoodNumbermethod(1)
            this.orderNumber=''
            this.orderNumberType=-1
        },
        /**组合菜品返回
         * @param comboFoodInfo 组合数据
         */
        comboConfirm(comboFoodInfo){
            /**1.组合菜品换菜返回,清理掉之前的 */
            if(this.editComboFoodInfo && this.editComboFoodInfo.Eat_ConsumeID){
                this.foodSetmealsclass=-1
                this.disRadioOrder =this.editComboFoodInfo;
                this.foodtype = 0
                this.choicefoodOrder=''
                this.clickDelete(this.disRadioclass,this.disRadioOrder);
            }
            this.isComboFoodShow = false;
            let _setNumber= comboFoodInfo.Food_Number;

            let Eat_TcAutoID_Batch=newGuid();
            let foodList=[];//记录组合明细 口味做法
            let tcIndex=1;
            let _item=this.getPlaceFood(comboFoodInfo);
            _item.Eat_Number=_setNumber;
            _item.Eat_TcIndex=tcIndex;
            _item.Eat_TcAutoID_Batch=Eat_TcAutoID_Batch;//组id
            
            if(comboFoodInfo._FoodModifys){
                foodList.push({
                    item:_item,
                    FoodModifys:comboFoodInfo._FoodModifys
                });
            }
            this.orderMenuList.push(_item);

            /**添加到订单中 */
            comboFoodInfo.ComboKinds.forEach(kind=>{
                kind.ComboKindFoods.forEach(food=>{
                    if(food.Is_Selected){
                        let eatConsume=this.getPlaceFood(food);
                        eatConsume.Kind_Name=kind.Kind_Name;
                        eatConsume.Eat_Number=food.Food_Number;
                        eatConsume.Eat_TcIndex=++tcIndex;
                        eatConsume.Eat_TcAutoID_Batch=Eat_TcAutoID_Batch;//组id
                        this.orderMenuList.push(eatConsume);
                        if(food._FoodModifys){
                            foodList.push({
                                item:eatConsume,
                                FoodModifys:food._FoodModifys
                            });
                        }
                    }
                })
            })
            
            /**给明细 添加口味做法 */
            foodList?.forEach(it=>{
                let _opt={disRadioOrder:it.item,optFlavors:it.FoodModifys}
                if(this.aloneMethod.length>0){
                    if(this.aloneMethod.findIndex(alone=>alone.disRadioOrder.Eat_ConsumeID===_opt.disRadioOrder.Eat_ConsumeID)==-1){
                        this.aloneMethod.push(_opt)
                    }
                    this.aloneMethod.some(alone=>{
                        if(alone.disRadioOrder.Eat_ConsumeID==_opt.disRadioOrder.Eat_ConsumeID){
                            alone.optFlavors = it.FoodModifys;
                            alone.disRadioOrder=_opt.disRadioOrder;
                        }
                    })
                }else{
                    this.aloneMethod.push(_opt)
                }
                this.synchMethods(it.FoodModifys,'',it.item);
            })


            this.radioClick(this.orderMenuList.length-1,_item);

            

            this.posFoodNumbermethod(1)
            this.orderNumber=''
            this.orderNumberType=-1
        },
        /**返回一个添加单品的数据 */
        getPlaceFood(item){
            let _item ={
                Eat_ConsumeID:newGuid(),
                Food_Type:1,
                SubCategory_ID:item.SubCategory_ID,
                SubCategory_Code:item.SubCategory_Code,
                EAT_XFBMID:item.Food_ID,
                EAT_XFCode:item.Food_Code,
                EAT_XFName:item.Food_Name,
                Eat_Number:1,
                Eat_CheckPrice:item.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:item.Food_Price,
                Eat_VipPrice:item.Food_VipPrice,
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:item.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:item.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:item.Is_Special,//是否是推荐菜
                Is_Discounted:item.Is_Discounted,
                Is_Seasonal:item.Is_Seasonal,
                RemainNum:item.RemainNum,
                Is_Limited:item.Is_Limited,
                Is_ChangedNum:item.Is_ChangedNum,
                Is_ChargeForService:item.Is_ChargeForService,
                Is_SetMeal:false,
                Promotion_Price:0,
                Exchanged_Number:0,
                ConsumeOperations:[]
            };
            //促销价
            if(item?.Promotion_Price){
                _item.Eat_CheckPrice = item?.Promotion_Price;
                _item.Promotion_Price = item?.Promotion_Price;
                _item.ConsumeOperations=[{OperateType:5}];
            }
            if(item.Food_AssSize){
                _item.Eat_Number= item.Number||0;
                _item.AssSizeNumber=item.AssSizeNumber||0;
            }
            return _item;
        },
        /**用餐渠道点击 */
        channeClick(item){
            this.Channellike = item.OrderChannel_AutoID;
            this.headingInfo.OrderChannel_AutoID= item.OrderChannel_AutoID;
            this.headingInfo.orderChannelName = item.OrderChannel_Name;
        },
        /**赠送 */
        clickGivereturn(type,item){
            if(this.disRadioclass>-1){
                if(type==1 && this.disRadioOrder.Is_Gift==true){
                    this.$confirm('是否取消'+this.disRadioOrder.EAT_XFName+'的赠送', '提示', {
                        confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'})
                    .then(() => {
                        if(this.foodOperationRecord.length>0){
                            this.foodOperationRecord.forEach((food,index)=>{
                                if(food.foodType==1 && food.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                                    this.foodOperationRecord.splice(index,1)
                                    index--
                                }
                            })
                        }
                        this.disRadioOrder.Is_Gift=false
                        this.disRadioOrder.Eat_CheckPrice = this.disRadioOrder.Eat_SalePrice
                        this.discountMoneuy = this.discountMoneuy - Number(Number(this.disRadioOrder.Eat_Number) * Number(this.disRadioOrder.Eat_SalePrice)).toFixed(2)
                        this.disRadioOrder.ConsumeOperations=[]
                    }).catch(() => {
                    });
                    return
                }
                if((this.disRadioOrder.Eat_CheckMoney<=0 && this.disRadioOrder.Eat_TcAutoID_Batch=='' && this.disRadioOrder.Eat_Number==0) || this.disRadioOrder.Is_Gift==true){
                    this.$message.warning('该菜品无法调整')
                    return;
                }
                if(this.disRadioOrder.Eat_Number<=0){
                    this.$message.warning('没有可操作剩余数量')
                    return;
                }
                if(this.disRadioOrder.FoodSetmeals!=null){
                    this.$message.warning('套餐菜品无法进行赠送调整')
                    return
                }
                if(this.foodtype==1 && !this.choicefoodOrder){
                    this.$message.warning('套餐菜品无法进行赠送调整')
                    return
                }
                this.recordFunctionCheck=item
                this.giveShow=true 
            }else{
                this.$alert('请先选择左边的菜品', '提示', {
                    confirmButtonText: '确定',
                });
            }
        },
        /**赠送返回 */
        GiveFoodFreeClick(orderNum,OperateAutoID,OperateContent,userInfo){
            let _type=0
            let _givingPrice = 0;//赠送金额
            this.orderMenuList.find(item=>{
                if(item.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                    if(!item?.is_taste){
                        item.is_discount =false
                        if(Number(Number(item.Eat_Number)-Number(orderNum))==0){//全赠送
                            _givingPrice = Number(orderNum) * item.Eat_CheckPrice
                            item.ConsumeOperations=[]
                            let _Operations={
                                OperateType:2,
                                OperateReason:OperateContent,
                                Operator:userInfo.Login_Name,
                                Project_AutoID:'',Project_Name:'',Project_Kind:0,Project_Rate:0,Old_Price:item.Eat_SalePrice
                            }
                            item.ConsumeOperations.push(_Operations)
                            this.discountMoneuy += _givingPrice
                            item.Eat_CheckPrice = 0
                            item.Is_Gift = true
                        }else{//部分赠送
                            _givingPrice = Number(orderNum) * item.Eat_CheckPrice
                            this.discountMoneuy += _givingPrice
                            item.Eat_Number = this.accSub(Number(orderNum),Number(item.Eat_Number))
                            _type=1
                        }
                        return true;
                    }
                }
            })
            let _newGuid=newGuid()
            if(_type==1){//赠送
                let _item ={
                    Eat_ConsumeID:_newGuid,
                    SubCategory_ID:this.disRadioOrder.SubCategory_ID,
                    SubCategory_Code:this.disRadioOrder.SubCategory_Code,
                    EAT_XFBMID:this.disRadioOrder.EAT_XFBMID,
                    EAT_XFCode:this.disRadioOrder.EAT_XFCode,
                    EAT_XFName:this.disRadioOrder.EAT_XFName,
                    Eat_Number: Number(orderNum),
                    Eat_CheckPrice:0,
                    ModiPrice:0,
                    Eat_SalePrice:this.disRadioOrder.Eat_SalePrice,
                    Eat_MasName:'',
                    Food_Status:-1,
                    Food_AssSize:this.disRadioOrder.Food_AssSize,
                    AssSizeNumber:'',
                    Eat_XFSize:this.disRadioOrder.Eat_XFSize,
                    FoodModifys:null,
                    FoodSetmeals:null,
                    Is_Special:this.disRadioOrder.Is_Special,//是否是推荐菜
                    Is_Discounted:this.disRadioOrder.Is_Discounted,
                    Is_Seasonal:this.disRadioOrder.Is_Seasonal,
                    RemainNum:this.disRadioOrder.RemainNum,
                    Is_Limited:this.disRadioOrder.Is_Limited,
                    Is_ChangedNum:this.disRadioOrder.Is_ChangedNum,
                    Is_ChargeForService:this.disRadioOrder.Is_ChargeForService,
                    Is_SetMeal:this.disRadioOrder.Is_SetMeal,
                    Is_Gift:true,
                    Exchanged_Number:0,
                    ConsumeOperations:[{
                        OperateType:2,
                        OperateReason:OperateContent,
                        Operator:userInfo.Login_Name,
                        Project_AutoID:'',Project_Name:'',Project_Kind:0,Project_Rate:0,Old_Price:this.disRadioOrder.Eat_SalePrice
                    }]
                };
                this.orderMenuList.push(_item);
                this.checkUser={}
            }
            /**查询菜品的赠送操作记录 */
            if(this.foodOperationRecord.length>0){
                let foodType=0
                this.foodOperationRecord.forEach(food=>{
                    if(food.foodType==1 && this.disRadioOrder.Eat_ConsumeID==food.Eat_ConsumeID){
                        food.givinPrice= food.givinPrice + _givingPrice
                        foodType=1
                    }
                })
                if(foodType==0){
                    this.addfoodOperaRe(_givingPrice,_newGuid,_type)
                }
            }else{
                this.addfoodOperaRe(_givingPrice,_newGuid,_type)
            }
            this.orderMenuList.forEach(item=>{
                if(item.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID){
                    if(!item?.is_taste){
                        this.disRadioOrder = item
                    }
                }
            })
            this.giveShow = false;
        },
        addfoodOperaRe(_givingPrice,_newGuid,_type){
            let _foodOperaRecord={
                foodType:1, /**赠送操作 */
                givinPrice:_givingPrice, /**赠送金额 */
                Eat_ConsumeID:this.disRadioOrder.Eat_ConsumeID, /**操作的菜品 */
            }
            if(_type==1){
                _foodOperaRecord.Eat_ConsumeID=_newGuid
            }
            this.foodOperationRecord.push(_foodOperaRecord)
        },
        /**CRM界面返回 */
        memberCloseModel(){
            this.carPriceType=false
            this.memberPayShow=false,this.wlivePayShow=false,this.newEnjoyShow=false,this.hdShow=false;
            if(this.IsMultipleCardPay==false && this.paymentHistory.length==0){
                this.orderMenuList = this.Temporaryorder
                this.choicePayType=[]
                this.orderMenuList.forEach(order=>{
                    if(order.Eat_SalePrice){
                        if(order?.ConsumeOperations?.length>0){
                            if(order?.ConsumeOperations[0].OperateType==4){//会员价
                                order.Eat_CheckPrice = order.Eat_SalePrice;
                                order.ConsumeOperations=[]
                            }else if(order?.ConsumeOperations[0].Is_MemberDiscount){//会员 折扣
                                order.Eat_CheckPrice = order.Eat_SalePrice;
                                order.ConsumeOperations=[]
                            }
                        }else{
                            order.Eat_CheckPrice = order.Eat_SalePrice;
                        }
                    }
                })
                this.QKOrderMain.map(item=>{
                    if(item.ProjectItem_Key=='MemberPrice'){
                        item.ProjectItem_Name='会员价'
                    }
                })
            }
        },
        /**扫码返回 */
        scanPaySuccessConfirm(data){
            this.scanCodePay=false;
            this.yinShiPayShow=false;
            this.yinShiYFPayShow=false;
            this.westStoredShow=false;
            if(data?.IsMultipleCardPay){//部分支付
                if(data.Payments.length>0){
                    this.paymentHistory=this.paymentHistory.filter(it=>it.Eat_PayDetailID);//排除没有提交的支付方式
                    data.Payments.forEach(pay=>{
                        if(!this.paymentHistory.some(it=>it.Eat_PayDetailID==pay.Eat_PayDetailID)){//判断不能有重复支付记录
                            let item={
                                Payment_ID:pay.Payment_ID, //支付方式ID
                                Payment_Code:pay.Payment_Code,  //支付方式编号
                                Payment_Name:pay.Payment_Name,  //支付方式名称
                                Payment_Type:pay.Payment_Type,  //支付方式类型
                                Payment_Money:Number(pay.Payment_Money), //支付方式金额
                                Payment_FactMoney:Number(pay.Payment_Money),  //支付实收金额
                                Qr_Code:"", //扫码付款码值
                                Card_No:"", //卡号
                                CouponInfo:null, //该支付金额核销的券信息
                                Time:this.Times(),
                                is_pay:true, /**付款记录是否写入POS */
                                Eat_PayDetailID:pay.Eat_PayDetailID, //支付明细的ID
                                Operator_Name:this.userInfo?.Login_Name /**操作员 */
                            }
                            this.paymentHistory.push(item);
                        }
                    })
                    this.EatAutoID = data.Eat_AutoID;
                    this.Timestamps = data.Timestamps;
                    this.IsMultipleCardPay = data.IsMultipleCardPay;
                }
            }else{
                this.$webBrowser.openAuxiliaryScreen({
                    PayStatus:true
                })
                this.emptydata()
            }
        },
        /**昆仑会员卡 券选中 返回不支付 */
        kunlunMultipleConfirm(data){
            console.log(data);
            if(data.Payments.length>0){
                this.paymentHistory=this.paymentHistory.filter(it=>it.Eat_PayDetailID);//排除没有提交的支付方式
                data.Payments.forEach(pay=>{
                    if(!this.paymentHistory.some(it=>it.Eat_PayDetailID==pay.Eat_PayDetailID)){//判断不能有重复支付记录
                        let item={
                            Payment_ID:pay.Payment_ID, //支付方式ID
                            Payment_Code:pay.Payment_Code,  //支付方式编号
                            Payment_Name:pay.Payment_Name,  //支付方式名称
                            Payment_Type:pay.Payment_Type,  //支付方式类型
                            Payment_Money:Number(pay.Payment_Money), //支付方式金额
                            Payment_FactMoney:Number(pay.Payment_Money),  //支付实收金额
                            Qr_Code:"", //扫码付款码值
                            Card_No:"", //卡号
                            CouponInfo:null, //该支付金额核销的券信息
                            Time:this.Times(),
                            is_pay:true, /**付款记录是否写入POS */
                            Eat_PayDetailID:pay.Eat_PayDetailID, //支付明细的ID
                            Operator_Name:this.userInfo?.Login_Name /**操作员 */
                        }
                        this.paymentHistory.push(item);
                    }
                })
            }
            this.kunlunPayShow=false
            this.EatAutoID = data.Eat_AutoID;
            this.Timestamps = data.Timestamps;
            this.IsMultipleCardPay = data.IsMultipleCardPay;
        },
        /**会员读卡返回 */
        memberCardBack(data){
            let _priceType=0
            this.orderMenuList.forEach(order=>{
                if(!order?.is_taste){
                    if(data?.EatConsumeList){
                        data?.EatConsumeList.forEach(eat=>{
                            if(order.Eat_ConsumeID==eat.Eat_ConsumeID && eat.Is_CanZk==1 && order.EAT_XFName==eat.Eat_XFBMName){
                                order.Eat_CheckPrice= Number(eat.Eat_ZkPrice);
                                if(eat.Remark&&eat.Remark!=''){
                                    order.Remark=eat.Remark;
                                }
                                if(eat.ConsumeOperations?.length>0){//菜品操作详情 //会员折扣
                                    let _Operations={
                                        OperateType:eat.ConsumeOperations[0].OperateType,
                                        Is_MemberDiscount:eat.ConsumeOperations[0].Is_MemberDiscount,
                                        OperateReason:'',
                                        Operator:this.userInfo?.Login_Name,
                                        Project_AutoID:'',
                                        Project_Name:eat.ConsumeOperations[0].Project_Name,
                                        Project_Kind:eat.ConsumeOperations[0].Project_Kind,
                                        Project_Rate:eat.ConsumeOperations[0].Project_Rate,
                                        Old_Price:order.Eat_SalePrice
                                    }
                                    if(!Object.keys(this.checkUser).length==0){
                                        _Operations.Operator=this.checkUser.Login_Name; //获取用户id
                                    }
                                    order.ConsumeOperations=[_Operations];//菜品操作详情
                                }
                            }else{
                                //执行会员价
                                if(data?.Is_Have_ParPrice==1 && order.Eat_ConsumeID==eat.Eat_ConsumeID && order?.ConsumeOperations.length==0){
                                    if(Number(order.Eat_CheckPrice)!= Number(order.Eat_VipPrice)){
                                        if(Number(eat.Eat_Price) == Number(eat.Eat_ZkPrice)){
                                            this.discountMoneuy += (order.Eat_CheckPrice - order.Eat_VipPrice) * order.Eat_Number;
                                            order.Eat_CheckPrice = Number(order.Eat_VipPrice);
                                            _priceType=1
                                        }
                                    }
                                    let _Operations={
                                        OperateType:4,
                                        OperateReason:'',
                                        Operator:this.userInfo?.Login_Name,
                                        Project_AutoID:'',
                                        Project_Name:'',
                                        Project_Kind:0,
                                        Project_Rate:0,
                                        Old_Price:order.Eat_SalePrice
                                    }
                                    if(!Object.keys(this.checkUser).length==0){
                                        _Operations.Operator=this.checkUser.Login_Name; //获取用户id
                                    }
                                    order.ConsumeOperations.push(_Operations);//菜品操作详情
                                    if(eat.Remark&&eat.Remark!=''){
                                        order.Remark=eat.Remark
                                    }
                                }
                            }
                        })
                    }
                }
            })
            
            if(_priceType==1){
                this.carPriceType=true
                this.QKOrderMain.map(item=>{
                    if(item.ProjectItem_Key=='MemberPrice'){
                        item.ProjectItem_Name='取消会员价'
                    }
                })
            }
            this.checkUser={}
        },
        /**折扣设置返回 */
        discountSchemeClic(data){
            this.orderMenuList.forEach(order=>{
                data.ResponseBody?.forEach(food=>{
                    if(order.Eat_ConsumeID==food.Eat_ConsumeID && !order?.is_taste){
                        order.Eat_CheckPrice = Number(Number(food.Food_CheckPrice))
                        if(food?.ConsumeOperations){
                            Object.assign(order,{is_discount:true})
                            let _price = Number(Number(Number(food.Food_Price) - Number(food.Food_CheckPrice)))
                            this.discountMoneuy += _price * order.Eat_Number;
                            order.ConsumeOperations = food?.ConsumeOperations;
                        }
                        if(order.FoodSetmeals){
                            order.FoodSetmeals.forEach(orfood=>{
                                orfood = Object.assign(orfood,{OldFoodSetmeal_Price:orfood.FoodSetmeal_Price})
                                if(food.FoodSetmeals){
                                    food.FoodSetmeals.forEach(datafood=>{
                                        if(orfood.FoodSetmeal_ID==datafood.FoodSetmeal_ID){
                                            orfood.FoodSetmeal_Price =datafood.FoodSetmeal_Price
                                        }   
                                    })
                                }
                            })
                        }
                    }
                })
            })
            this.discountShow=false
        },
        /**取消折扣 */
        CancelDiscountConfirm(){
            let issucc=false;
            this.orderMenuList.map(order=>{
                if(order?.ConsumeOperations){
                    if(order?.ConsumeOperations[0]?.OperateType==1){
                        issucc=true;
                        let CheckPrice = order.Eat_CheckPrice * order.Eat_Number
                        let SalePrice = order.Eat_SalePrice * order.Eat_Number
                        this.discountMoneuy = Number(Number(Number(this.discountMoneuy) - (Number(SalePrice) - Number(CheckPrice))).toFixed(2))
                        order.Eat_CheckPrice = order.Eat_SalePrice
                        order.is_discount=false
                        order.ConsumeOperations=[]
                    }
                }
                if(order.FoodSetmeals){
                    order.FoodSetmeals?.forEach(orfood=>{
                        orfood.FoodSetmeal_Price=orfood.OldFoodSetmeal_Price;
                    })
                }
            })
            if(!issucc)
            {
                this.$message.warning('没有折扣菜品可取消')
                return
            }
            else
            {
                this.$message.success("取消折扣成功");
                this.discountShow=false
                this.checkUser={}
            }
        },
        /**支付弹窗 */
        payClick(item){
            let proKey=this.checkoutPermission()
            if(proKey==false){
                this.money=''
                this.$alert('您没有结账权限，请联系后台开通结账权限！', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.orderMenuList.length>0){
                if(this.DeskName=='' && _data.RunFastFoodMustChooseDeskNo==true){
                    this.$message.warning('请先去选择桌台')
                    return
                }
                if(_data.RunFastFoodWithSignCard==true && this.headingInfo.brandNumber==''){
                    this.$message.warning('请先去填写对应牌号')
                    return
                }
                this.choicePayType = item
                if(item.INTERFACE_TYPE==5){/**Ar支付 */
                    if(this.paymentHistory.length==0){
                        this.arShow=true
                    }else{
                        let payType= this.paymentHistory.some(pay=>{
                            if(pay.Payment_Type!=5){
                                return true
                            }
                        })
                        if(payType==true){
                            this.$message.warning(item.PAY_NAME+'不能和其他支付方式混用')
                        }else{
                            this.arShow=true
                        }
                    }
                }else if(item.INTERFACE_TYPE==801010301){/**聚合支付 */
                    this.scanCodePay=true
                }else if(item.INTERFACE_TYPE==801010302){//银石预付卡支付
                     this.yinShiYFPayShow=true;
                }else if(item.INTERFACE_TYPE=="9046001"){//银石智能POS支付
                    this.yinShiPayShow=true;
                }else if(item.INTERFACE_TYPE==801010401){/**正品CRM支付 */
                    this.Temporaryorder = this.orderMenuList
                    this.memberPayShow = true
                }else if(item.INTERFACE_TYPE==9022001){
                    this.Temporaryorder = this.orderMenuList
                    this.newEnjoyShow = true
                }else if(item.INTERFACE_TYPE==801010501){
                    /**微生活CRM支付 */
                    this.Temporaryorder = this.orderMenuList
                    this.wlivePayShow=true
                }else if(item.INTERFACE_TYPE==9030001){ /**海鼎 */
                    this.Temporaryorder = this.orderMenuList
                    this.hdShow=true;
                }else if(item.INTERFACE_TYPE=='7' || item.INTERFACE_TYPE=='8'){
                    /**现金券or商品券 */
                    this.paymentCouponsShow=true;
                    this.payCouponsData=item;
                }else if(item.INTERFACE_TYPE==9033001){
                    this.zpCouponShow=true
                    this.Temporaryorder = this.orderMenuList;
                }else if(item.INTERFACE_TYPE==9024001){//翼码卡券核销
                    this.ymCouponShow=true
                    this.Temporaryorder = this.orderMenuList;
                }else if(item.INTERFACE_TYPE=='9036002'){/**昆仑会员支付 */
                    this.Temporaryorder = this.orderMenuList
                    this.kunlunPayShow = true;
                    
                }else if(item.INTERFACE_TYPE=='9044001'){/**大拈会员支付 */
                    this.Temporaryorder = this.orderMenuList
                    this.daNianPayShow = true;
                    
                }/*else if(item.INTERFACE_TYPE=='9026001'){//G9 会员支付
                    this.Temporaryorder = this.orderMenuList
                    this.gNinePayShow = true;
                    
                }*/
                else if(item.INTERFACE_TYPE=='9017002'){//西软XMS储值支付
                    this.westStoredShow=true;
                }else if(item.INTERFACE_TYPE=='9017004'){//西软券核销
                    this.westStoredCouponShow=true;
                }else{
                    /**现金支付 */
                    this.paymentShow=true
                    this.payMoneyKey=new Date().getTime()
                }
            }else{
                this.$message.warning('请先选择菜品!')
            }
        },
        //更新订单待支付金额
        updatePay(data,Timestamps){ 
            if(data){
                data.Payments.forEach(pay=>{
                    if(!this.paymentHistory.some(it=>it.Eat_PayDetailID==pay?.Eat_PayDetailID)){//判断不能有重复支付记录
                        let item={
                            Payment_ID:pay.Payment_ID, //支付方式ID
                            Payment_Code:pay.Payment_Code,  //支付方式编号
                            Payment_Name:pay.Payment_Name,  //支付方式名称
                            Payment_Type:pay.Payment_Type,  //支付方式类型
                            Payment_Money:Number(pay.Payment_Money), //支付方式金额
                            Payment_FactMoney:Number(pay.Payment_Money),  //支付实收金额
                            Qr_Code:"", //扫码付款码值
                            Card_No:"", //卡号
                            CouponInfo:null, //该支付金额核销的券信息
                            Time:this.Times(),
                            is_pay:true, /**付款记录是否写入POS */
                            Eat_PayDetailID:pay?.Eat_PayDetailID, //支付明细的ID
                            Operator_Name:this.userInfo?.Login_Name /**操作员 */
                        }
                        this.paymentHistory.push(item)
                    }
                })
                this.EatAutoID = data.Eat_AutoID
            }
            if(Timestamps){
                this.Timestamps = Timestamps
            }
        },
        /**会员多卡返回 
         * @param data 支付返回数据
         * @param isOnePay 是否是只提交了单个支付方式
        */
        payMultipleConfirm(data,isOnePay){
            if(data.Payments.length>0){
                if(!isOnePay){//会员支付 是提交了所有没有提交的支付方式 已及当前会员支付后的 
                    this.paymentHistory=this.paymentHistory.filter(it=>it.Eat_PayDetailID);//排除没有提交的支付方式
                }
                data.Payments.forEach(pay=>{
                    if(!this.paymentHistory.some(it=>it.Eat_PayDetailID==pay.Eat_PayDetailID)){//判断不能有重复支付记录
                        let item={
                            Payment_ID:pay.Payment_ID, //支付方式ID
                            Payment_Code:pay.Payment_Code,  //支付方式编号
                            Payment_Name:pay.Payment_Name,  //支付方式名称
                            Payment_Type:pay.Payment_Type,  //支付方式类型
                            Payment_Money:Number(pay.Payment_Money), //支付方式金额
                            Payment_FactMoney:Number(pay.Payment_Money),  //支付实收金额
                            Qr_Code:"", //扫码付款码值
                            Card_No:"", //卡号
                            CouponInfo:null, //该支付金额核销的券信息
                            Time:this.Times(),
                            is_pay:true, /**付款记录是否写入POS */
                            Eat_PayDetailID:pay.Eat_PayDetailID, //支付明细的ID
                            Operator_Name:this.userInfo?.Login_Name /**操作员 */
                        }
                        this.paymentHistory.push(item);
                    }
                })
            }
            this.memberPayShow=false;
            this.hdShow=false;
            this.westStoredShow=false;
            this.EatAutoID = data.Eat_AutoID
            this.Timestamps = data.Timestamps
            this.IsMultipleCardPay = data.IsMultipleCardPay
        },
        /**添加已付记录 */
        paymetClick(){
            let item={
                Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                Payment_Money:Number(this.money), //支付方式金额
                Payment_FactMoney:Number(this.money),  //支付实收金额
                Qr_Code:"", //扫码付款码值
                Card_No:"", //卡号
                CouponInfo:null, //该支付金额核销的券信息
                Time:this.Times(),
                is_pay:false, /**付款记录是否写入POS */
                Eat_PayDetailID:'',
                Operator_Name:this.userInfo?.Login_Name /**操作员 */
            }
            this.paymentHistory.push(item)
        },
        /**商品券和现金券返回 */
        payCouponsConfirm(selectTicketList){
            this.paymentCouponsShow=false
            if(selectTicketList){
                let counMoney = 0
                selectTicketList.forEach(tick=>{
                    let _choice={
                        Coupon_AutoId:tick.TK_AUTOID,
                        Coupon_Code:tick.TK_CODE,
                        Coupon_Name:tick.TK_NAME,
                        Coupon_Kind:tick.KIND,
                        Coupon_Num:tick.number,
                        Coupon_CostPrice:tick.EAT_SALEPRICE
                    }
                    let OverChargeMoney=0
                    if( Number(tick.EAT_PRICE) * tick.number >= Number(this.unPaidMoney)){
                        OverChargeMoney = Number(tick.EAT_PRICE) * tick.number - Number(this.unPaidMoney)
                    }
                    let item={
                        Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                        Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                        Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                        Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                        Payment_Money:Number(Number(Number(tick.EAT_PRICE) * tick.number).toFixed(2)), //支付方式金额
                        OverCharge_Money:Number(Number(OverChargeMoney).toFixed(2)),  //支付实收金额
                        Qr_Code:"", //扫码付款码值
                        Card_No:"", //卡号
                        CouponInfo:_choice, //该支付金额核销的券信息
                        Time:this.Times(),
                        is_pay:false, /**付款记录是否写入POS */
                        Operator_Name:this.userInfo?.Login_Name /**操作员 */
                    }
                    this.paymentHistory.push(item)
                    counMoney = counMoney + Number(tick.EAT_PRICE) * tick.number
                })
                this.money=0
                if(Number(this.unPaidMoney).toFixed(2)<=0){
                    this.invoicingConfirm()
                }
            }
        },
        /**退款冲销 */
        deleteClick(index,item){
            /**判断是否有结账的权限 */
            let proKey=this.checkoutPermission()
            if(proKey==false){
                this.money=''
                this.$alert('您没有退款权限，请联系后台开通对应权限！', '提示', {confirmButtonText: '确定',});
                return
            }
            if(item.Payment_Name=='新享会券'){
                this.$message.warning('已核销,无法退款冲销');
                return
            }
            if(item.is_pay==true || (item.Payment_Type==801010301 || item.Payment_Type==801010401 || item.Payment_Type==801010501 || item.Payment_Type==9022001)){
                this.$confirm('<div style="position:absolute;line-height:80px;"><i class="iconfont icon-jinggao" style="color:#faad14;font-size:30px;"></i></div><span style="color:#000;font-size:23px;padding-left:50px;display:inline-block;">您确定<span style="color:red;">￥</span><span style="color:red;font-size:30px;">'+item.Payment_Money+'</span>&nbsp;['+item.Payment_Name+']&nbsp;&nbsp;退款吗?</span><br/><span style="color: rgb(153, 153, 153);font-size: 19px;display:inline-block;padding-left:50px;">该操作涉及财务退款,请谨慎操作!</span>', 
                '谨慎操作', {
                dangerouslyUseHTMLString: true,
                confirmButtonText: '确定',cancelButtonText: '取消'
                }).then(() => {
                    let _Money=0
                    if(item.Is_Refund_YN==true){
                        _Money = item.Payment_Money-item.Refund_Money
                    }else{
                        _Money = item.Payment_Money
                    }
                    let param={
                        Body_ID:newGuid(),
                        User_ID:this.userInfo?.User_ID, //登陆用户ID
                        Operator_Name:this.userInfo?.Login_Name,//操作员
                        Eat_AutoID:this.EatAutoID,//结账单ID
                        Eat_CheckIndex:this.eatCheckIndex,//结账单号
                        Eat_PayDetailID:item.Eat_PayDetailID,//支付明细记录ID
                        Is_PartialRefund:false,//是否部分退款,不传默认false,代表支付金额全部退款
                        //Refund_Money:item.Payment_Money,//退款金额Is_PartialRefund为true时,传入退款金额
                        Refund_Reason:'',//退款原因
                        Timestamps:this.Timestamps.toString(),//时间戳
                    }
                    const loading = this.$loading({
                        text: "退款冲销中...",
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    this.$httpAES.post("Bestech.CloudPos.RefundPayment",param).then((data)=>{
                        loading.close();
                        if(data.ResponseHeader.ResultCode!=0){
                            this.$message.error(data.ResponseHeader.ResultDesc);
                        }else{
                            if(data.ResponseBody.Payments.length>0){
                                this.paymentHistory=this.paymentHistory.filter(it=>it.Eat_PayDetailID);//排除没有提交的支付方式
                                data.ResponseBody.Payments.forEach(pay=>{
                                    if(!this.paymentHistory.some(it=>it.Eat_PayDetailID==pay.Eat_PayDetailID)){//判断不能有重复支付记录
                                        let item={
                                            Payment_ID:pay.Payment_ID, //支付方式ID
                                            Payment_Code:pay.Payment_Code,  //支付方式编号
                                            Payment_Name:pay.Payment_Name,  //支付方式名称
                                            Payment_Type:pay.Payment_Type,  //支付方式类型
                                            Payment_Money:Number(pay.Payment_Money), //支付方式金额
                                            Payment_FactMoney:Number(pay.Payment_Money),  //支付实收金额
                                            Qr_Code:"", //扫码付款码值
                                            Card_No:"", //卡号
                                            CouponInfo:null, //该支付金额核销的券信息
                                            Time:this.Times(),
                                            is_pay:true, /**付款记录是否写入POS */
                                            Eat_PayDetailID:pay.Eat_PayDetailID, //支付明细的ID
                                            Operator_Name:this.userInfo?.Login_Name /**操作员 */
                                        }
                                        this.paymentHistory.push(item);
                                    }
                                })
                            }
                            this.money = item.Payment_Money
                            this.EatAutoID=data.ResponseBody.Eat_AutoID//结账单ID
                            this.Timestamps = data.ResponseBody.Timestamps.toString();
                            //如果冲销的是商品券则恢复菜品可使用的次数

                            this.merCouponFood.forEach((mer,i)=>{
                                if(mer.EatPayDetailID==item.Eat_PayDetailID){
                                    this.orderMenuList.forEach(order=>{
                                        if(mer.ConsumeID==order.Eat_ConsumeID){
                                            order.Exchanged_Number = this.accSub(1,order.Exchanged_Number)
                                        }
                                    })
                                    this.merCouponFood.splice(i,1)
                                    i--
                                }
                            })
                            this.$message.success("退款冲销成功");
                        }
                    })
                }).catch(() =>{});
            }else{
                this.paymentHistory.splice(index,1);
            }
        },
        cashConfirm(money){
            console.log("cashConfirm");
            this.money = money
            if(Number(this.money)> Number(this.unPaidMoney).toFixed(2)){
                this.onPayPreview()
            }else if(Number(this.money)== Number(this.unPaidMoney).toFixed(2)){
                this.paymetClick();//添加支付记录
                this.onPayPreview();
            }else{
                this.paymetClick()
            }
            this.money=0
            this.paymentShow = false
        },
        Times(){
            let date = new Date();
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');  // 月要+1
            let day = date.getDate().toString().padStart(2, '0');  // 获取天是getDate，而不是 getDay
            let hour = date.getHours().toString().padStart(2, '0');
            let minute = date.getMinutes().toString().padStart(2, '0');
            let second = date.getSeconds().toString().padStart(2, '0');
            let createTime = year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
            return createTime;
        },
        getCurrentDateTime(time){
            let date = new Date(time);
            let y = date.getFullYear();
            let MM = date.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = date.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = date.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = date.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = date.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;
        },
        /**选择桌号 */
        deskclose(desk){
            this.DeskName = desk.Desk_Name
            this.RoomAutoID = desk.Room_AutoID
            this.choiceDeskShow=false
        },
        /**快餐支付预览 */
        onPayPreview(){
            let proKey=this.checkoutPermission()
            if(proKey==false){
                this.money=''
                this.$alert('您没有结账权限，请联系后台开通结账权限！', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.choicePayType.PAY_ID==null && this.$route.params.data==''){
                this.$alert('请先选择支付类型', '提示', {confirmButtonText: '确定',});
                return
            }
            if(this.RoomAutoID==''){
                this.$alert('当前操作员可管理的餐厅区域为空', '提示', {confirmButtonText: '确定',});
                return
            }
            let FactMoney=[];
            if(this.paymentHistory.length>0){
                this.paymentHistory.forEach(pay=>{
                    if(pay.is_pay==false){
                        if(pay.Payment_Type==7 || pay.Payment_Type==8 ){
                            FactMoney.push({
                                Payment_ID:pay.Payment_ID, //支付方式ID
                                Payment_Code:pay.Payment_Code,  //支付方式编号
                                Payment_Name:pay.Payment_Name,  //支付方式名称
                                Payment_Type:pay.Payment_Type,  //支付方式类型
                                Payment_Money:pay.Payment_Money, //支付方式金额
                                OverCharge_Money:pay.OverCharge_Money,  //支付实收金额
                                Qr_Code:"", //扫码付款码值
                                Card_No:"", //卡号
                                CouponInfo:pay.CouponInfo, //该支付金额核销的券信息
                            });
                        }else{
                            FactMoney.push({
                                Payment_ID:pay.Payment_ID, //支付方式ID
                                Payment_Code:pay.Payment_Code,  //支付方式编号
                                Payment_Name:pay.Payment_Name,  //支付方式名称
                                Payment_Type:pay.Payment_Type,  //支付方式类型
                                Payment_Money:pay.Payment_Money, //支付方式金额
                                Payment_FactMoney:pay.Payment_FactMoney,  //支付实收金额
                                Qr_Code:"", //扫码付款码值
                                Card_No:"", //卡号
                                CouponInfo:pay.CouponInfo, //该支付金额核销的券信息
                            });
                        }
                    }
                })
            }
            //当实付金额大于剩余结账金额时
            if(this.unPaidMoney>0 && Number(this.money)>this.unPaidMoney){
                FactMoney.push({
                    Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                    Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                    Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                    Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                    Payment_Money:Number(this.money), //支付方式金额
                    Payment_FactMoney:Number(this.money),  //支付实收金额
                    Qr_Code:"", //扫码付款码值
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                });
                FactMoney.push({
                    Payment_ID:this.choicePayType.PAY_ID, //支付方式ID
                    Payment_Code:this.choicePayType.PAY_CODE,  //支付方式编号
                    Payment_Name:this.choicePayType.PAY_NAME,  //支付方式名称
                    Payment_Type:this.choicePayType.INTERFACE_TYPE,  //支付方式类型
                    Payment_Money:Number(this.unPaidMoney)-Number(this.money), //支付方式金额
                    Payment_FactMoney:Number(this.unPaidMoney)-Number(this.money),  //支付实收金额
                    Qr_Code:"", //扫码付款码值
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                });
            }
            this.userInfo=this.$auth.getUserInfo(); //获取用户id
            let _orderConsumes=this.$global.getFastOrderConsumes(this.orderMenuList);
            
            /**会员信息 */
            let _meberInfo=''
            if(this.meberInfo && this.meberInfo!=''){
                _meberInfo={
                    Member_Sys:this.meberInfo?.Member_Sys, //会员系统类型 2：正品CRM3.0，4：微生活
                    DynamicType:this.meberInfo.DynamicType, //查询码类型 5i会员类型：1手机会员、2卡会员
                    Card_AutoID:this.meberInfo.Bestech_Card_AutoID, //卡主键
                    Card_No:this.meberInfo.Card_No, //卡号
                    Member_Name:this.meberInfo.MemberName, //姓名
                    MemberCard_Kind:this.meberInfo.Bestech_Card_KindName, //会员等级
                    Mobile:this.meberInfo.Mobile,//会员手机号
                    NotJoinPointProgram:false,//不参与线上积分
                    Invoice_Money:0, //本次消费开票金额
                    Invoice_No:'',//发票号码
                }
            }
            //支付预览
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,  //用户id
                Eat_AutoID:this.EatAutoID, //订单ID，当异常单重新处理时需要传入
                Timestamps:this.Timestamps.toString(),//订单单时间戳，当异常单重新处理时需要传入
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Eat_PersonNum:this.headingInfo.DinersNum,//人数
                Channel_ID:this.Channellike,//订单渠道ID
                Room_ID:this.RoomAutoID,//餐厅ID
                Eat_DeskID:this.headingInfo?.DeskAutoID, //桌台ID
                Eat_DeskName:this.DeskName, //桌台名称
                Eat_TakeNo:this.headingInfo.brandNumber,//牌号
                Eat_AllRemark:'', //订单全单备注
                Order_Remark:this.headingInfo.orderRemark,//订单备注
                Order_BatchNo:newGuid(),//下单批次号，每次提交下单，调用方生成一个唯一批次号，接口判断防止重复下单
                NotSendToKitPrint:false,
                MantissaAdjustment_Money: Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                Member:_meberInfo,//会员信息
                OrderConsumes:_orderConsumes, //订单消费详细
                Payments:FactMoney,//支付方式
            }
            if(_meberInfo==''){
                param.Member=''
            }
            console.log("快餐支付预览 onPayPreview:",param);
            /**支付预览提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SnackPayPreview",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                        this.Timestamps=data.ResponseBody?.Timestamps.toString()
                    }
                }else{
                    
                    /**需要短信验证 */
                    if(data.ResponseBody.Is_NeedSMSCode==1){
                        console.log('短信验证')
                        return
                    }
                    /**是否需要密码验证 */
                    if(data.ResponseBody.Is_NeedPassWord==1){
                        console.log('密码验证')
                        return
                    }
                    if(data.ResponseBody?.VoiceNotify){//语音提醒内容，如果返回不为空，前端可以发起一个语音播报
                        this.$webBrowser.tts(data.ResponseBody.VoiceNotify);
                    }
                    /**是否需要支付提交 0：不需要（说明快餐下单结账成功）， 1：需要（对接类支付方式预览后调用提交接口） */
                    if(data.ResponseBody.Is_NeedSubmit==0){
                        if(data.ResponseBody?.OpenCashBox==true){
                            this.$webBrowser.openCashBoxBnt().then(d=>{
                                if(d?.state!=0){
                                    this.$message.error('钱箱打开失败:'+(d?.message||""));
                                }
                            });
                        }
                        if(data.ResponseBody?.PrintsInfo){
                            setTimeout(()=>{
                                this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                            },500);
                        }
                        ElMessage.success({ message: "支付完成"});
                        //支付成功
                        this.$webBrowser.openAuxiliaryScreen({
                            PayStatus:true
                        })
                        this.emptydata()
                    }else{
                        /**支付提交 */
                        this.PaySubmit(data)
                    }
                    
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**支付提交方法 */
        PaySubmit(data){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID, //登陆用户ID
                Eat_AutoID: data.ResponseBody.Eat_AutoID,//订单ID
                Member_Type:data.ResponseBody.Member_Type, //会员类型
                Biz_ID:data.ResponseBody.Biz_ID,//业务交易流ID
                MantissaAdjustMoney:Number(Number(this.MantissaMoney).toFixed(2)),//尾数调整金额
                Verify_Code:'',//验证码或密码
                Operator_Name:this.userInfo?.Login_Name, //操作员
                Timestamps:data.ResponseBody.Timestamps.toString(),
                Payments:data.ResponseBody.Payments //支付预览
            }
            /**支付提交 */
            const loading = this.$loading({
                text: "支付提交中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.SnackPaySubmit",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.UnPaidMoney=0
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody.Timestamps.toString()
                        this.Timestamps=data.ResponseBody?.Timestamps.toString()
                    }
                    this.$message.error(data.ResponseHeader.ResultDesc);
                }else{
                    
                    if(data.ResponseBody?.OpenCashBox==true){
                        this.$webBrowser.openCashBoxBnt().then(d=>{
                            if(d?.state!=0){
                                this.$message.error('钱箱打开失败:'+(d?.message||""));
                            }
                        });
                    }
                    if(data.ResponseBody?.PrintsInfo){
                        setTimeout(()=>{
                            this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                        },500);
                    }
                    ElMessage.success({ message: "支付完成"});
                    this.$webBrowser.openAuxiliaryScreen({
                        PayStatus:true
                    })
                    this.emptydata()
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**其他功能页面返回 */
        subItemReturn(item){
             this.MiddleMenuClick({
                Is_Show:item.Is_Show,
                Permission:item.Permission,
                ProjectItem_Key:item.ProjectSubItem_Key,
                ProjectItem_Name:item.ProjectSubItem_Name,
                ProjectItem_Type:item.ProjectSubItem_Type,
            });
            this.subShow = false;
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        showKeyBoard(input,type){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(input,{type:type,isKeyDown:true});
                input.focus();
            }
        
        },
        /**判断是否有结账的权限 */
        checkoutPermission(){
            let proKey=false
            this.userCheckOutMenu.map(it=>{
                if(it.ProjectItem_Key=='CheckOut' && it.Permission==true){
                    proKey=true
                }
            })
            return proKey
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
        /**查询支付结果返回 */
        ThePaymentResultClic(data,payCode){
            if(data.ResponseBody?.Payments){
                let item={
                    Payment_ID:data.ResponseBody.Payments[0].Payment_ID, //支付方式ID
                    Payment_Code:data.ResponseBody.Payments[0].Payment_Code,  //支付方式编号
                    Payment_Name:data.ResponseBody.Payments[0].Payment_Name,  //支付方式名称
                    Payment_Type:data.ResponseBody.Payments[0].Payment_Type,  //支付方式类型
                    Payment_Money:data.ResponseBody.Payments[0].Payment_Money, //支付方式金额
                    Payment_FactMoney:data.ResponseBody.Payments[0].Payment_FactMoney,  //支付实收金额
                    Qr_Code:payCode, //扫码付款码值
                    Card_No:"", //卡号
                    CouponInfo:null, //该支付金额核销的券信息
                    Time:this.Times(),
                    is_pay:true, /**付款记录是否写入POS */
                    Eat_PayDetailID:data.ResponseBody.Payments[0].Eat_PayDetailID,
                    Operator_Name:data.ResponseBody.Payments[0].Eat_Czy /**操作员 */
                }
                let _repay = false
                _repay = this.paymentHistory.some(pay=>{
                    if(pay.Eat_PayDetailID==data.ResponseBody.Payments[0].Eat_PayDetailID){
                        return true
                    }
                })
                if(_repay==false){
                    this.paymentHistory.push(item);
                    this.money=this.unPaidMoney;//剩余需要支付的金额
                    this.EatAutoID = data.ResponseBody.Eat_AutoID;
                    this.Timestamps = data.ResponseBody.Timestamps;
                    this.scanCodePay=false,this.memberPayShow=false,this.wlivePayShow=false,this.newEnjoyShow=false,this.hdShow=false;
                    this.yinShiPayShow=false;
                    this.yinShiYFPayShow=false;
                    this.daNianPayShow=false;
                    this.gNinePayShow=false;
                    this.kunlunPayShow=false;
                    this.westStoredShow=false;
                    if(this.unPaidMoney==0){//没有需要支付的才调用
                        this.onPayPreview();
                    }
                   
                }
            }
        },
        /**清除未记录到POS的支付方式 */
        paymentHistoryDelete(Payments){
            this.paymentHistory=this.paymentHistory.filter(it=>it.Eat_PayDetailID);//排除没有提交的支付方式
            
            Payments.forEach(item=>{
                if(!this.paymentHistory.some(pay=>pay.Eat_PayDetailID==item.Eat_PayDetailID)){
                    let param={
                        Payment_ID:item.Payment_ID, //支付方式ID
                        Payment_Code:item.Payment_Code,  //支付方式编号
                        Payment_Name:item.Payment_Name,  //支付方式名称
                        Payment_Type:item.Payment_Type,  //支付方式类型
                        Payment_Money:item.Payment_Money, //支付方式金额
                        Payment_FactMoney:item.Payment_FactMoney,  //支付实收金额
                        Qr_Code:'', //扫码付款码值
                        Card_No:"", //卡号
                        CouponInfo:null, //该支付金额核销的券信息
                        Time:this.Times(),
                        is_pay:true, /**付款记录是否写入POS */
                        Eat_PayDetailID:item.Eat_PayDetailID,
                        Operator_Name:item.Eat_Czy /**操作员 */
                    }
                    this.paymentHistory.push(param);
                }
            })
            
        },
        /**聚合支付保存历史时间戳 */
        ThePaymentResultTime(time){
            this.Timestamps = time
        },
        /**用餐渠道更新 */
        OrderChannelUpdate(){
            if(!this.Channellike){
                this.ChanneList.some((item)=>{
                    if(item.OrderChannel_AutoID==this.DefaultChannelID){
                        this.headingInfo.orderChannelName = item.OrderChannel_Name;
                        this.Channellike=item.OrderChannel_AutoID;
                    } 
                })
            }
            if(!this.orderChannel.some(it=>it.OrderChannel_AutoID==this.Channellike)){
                let orderChannel=[];
                if(this.ChanneList?.length>0){
                    let index=this.ChanneList.findIndex(it=>it.OrderChannel_AutoID==this.Channellike);
                    if(index>=0){
                        if(index==0 && index+1==this.ChanneList.length){
                            orderChannel.push(this.ChanneList[index]);
                        }else if(index+1!=this.ChanneList.length){
                            orderChannel.push(this.ChanneList[index]);
                            orderChannel.push(this.ChanneList[index+1]);
                        }else{
                            orderChannel.push(this.ChanneList[index-1]);
                            orderChannel.push(this.ChanneList[index]);
                        }
                    }
                }
                this.orderChannel=orderChannel;
            }
        },
        /**控制菜品红点数量 */
        posFoodNumbermethod(type,operate){
            if(type==4){//全删
                //如果有大类直接大类删除
                if(this.foodMainCategory){
                    this.foodMainCategory.map(main=>{
                        main.FoodSubCategorys.map(food=>{
                            food.PosFoods.map(pos=>{
                                if(pos.foodNumber>=0){
                                    pos.foodNumber=0
                                }
                            })
                        })
                    })
                }else{
                //小类删除
                    this.foodSubCategorys.map(food=>{
                        food.PosFoods.map(pos=>{
                            if(pos.foodNumber>=0){
                                pos.foodNumber=0
                            }
                        })
                    })
                }
            }else if(type==5){
                this.foodSubCategorys.map(food=>{
                    food.PosFoods.map(pos=>{
                        pos.foodNumber=0
                        this.orderMenuList.map(order=>{
                            if(order.EAT_XFBMID==pos.Food_ID && !order?.is_taste){
                                pos.foodNumber += order.Eat_Number
                            }
                        })
                    })
                })
            }else{
                if(this.orderMenuList){
                    this.orderMenuList.map(order=>{
                        if(order.Eat_ConsumeID==this.disRadioOrder.Eat_ConsumeID && !order?.is_taste){
                            this.disRadioOrder.Eat_Number = order.Eat_Number
                        }
                    })
                }
                this.foodSubCategorys.map(food=>{
                    food.PosFoods.map(pos=>{
                        if(pos.Food_ID==this.disRadioOrder.EAT_XFBMID && !this.disRadioOrder?.is_taste){
                            if(type==1){//+
                                if(Number(this.orderNumber>0) && !operate){
                                    pos.foodNumber=this.addNum(Number(this.orderNumber),pos.foodNumber)
                                }else if(this.isUnitWeight(pos.Food_Size) && Number(this.wgNum)>0){
                                    pos.foodNumber=this.addNum(Number(this.wgNum),pos.foodNumber)
                                }else{
                                    pos.foodNumber=this.addNum(1,pos.foodNumber)
                                }
                            }else if(type==2){//-
                                pos.foodNumber= this.accSub(1,pos.foodNumber);
                            }else if(type==3){//单删
                                pos.foodNumber = this.accSub(this.disRadioOrder.Eat_Number,pos.foodNumber);
                                if(pos.foodNumber<0){
                                    pos.foodNumber=0
                                }
                            }else if(type==5){
                                pos.foodNumber = this.disRadioOrder.Eat_Number
                            }
                        }else{
                            //多规格菜品
                            if(pos?.MultipleSizeFoods){
                                pos?.MultipleSizeFoods.map(mu=>{
                                    if(mu.Food_ID==this.disRadioOrder.EAT_XFBMID && !this.disRadioOrder?.is_taste){
                                        if(type==1){//+
                                            if(Number(this.orderNumber>0) && !operate){
                                                pos.foodNumber=this.addNum(Number(this.orderNumber),pos.foodNumber)
                                            }else if(this.isUnitWeight(mu.Food_Size) && Number(this.wgNum)>0){
                                                pos.foodNumber=this.addNum(Number(this.wgNum),pos.foodNumber)
                                            }else{
                                                pos.foodNumber=this.addNum(1,pos.foodNumber)
                                            }
                                        }else if(type==2){//-
                                            pos.foodNumber= this.accSub(1,pos.foodNumber);
                                        }else if(type==3){//单删
                                            pos.foodNumber = this.accSub(this.disRadioOrder.Eat_Number,pos.foodNumber);
                                            if(pos.foodNumber<0){
                                                pos.foodNumber=0
                                            }
                                        }else if(type==5){
                                            pos.foodNumber = this.disRadioOrder.Eat_Number
                                        }
                                    }
                                })
                            }
                        }
                    })
                })
            }
        },
        addNum(arg1, arg2) {//保留4个小数位 计算
            return Number((Number(arg1||0) +Number(arg2||0)).toFixed(4));
        },
        /**结账等金额更新 */
        /*moneyUpdate(){
            this.totalMoney=0,this.countNumber=0;
            let sumMoney=0;//总金额
            if(this.orderMenuList){
                this.orderMenuList.forEach(order=>{
                    if(order.isPromotion2){//第二份折扣
                        this.totalMoney += Number(Number(order.zkPromotionPrice * Number(order.Eat_Number)))
                    }else{
                        this.totalMoney += Number(Number(order.Eat_CheckPrice * Number(order.Eat_Number)))
                    }
                    
                    sumMoney+=Number(order.Eat_SalePrice||0) * Number(order.Eat_Number||0);
                    this.countNumber = this.addNum(Number(order.Eat_Number),this.countNumber)
                    if(order?.FoodSetmeals){
                        order.FoodSetmeals.forEach(food=>{
                            this.totalMoney += Number(Number(Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number)));
                            sumMoney+=Number(Number(Number(food.FoodSetmeal_Price) * Number(food.FoodSetmeal_Number)));
                            //this.countNumber += Number(food.FoodSetmeal_Number)
                        })
                    }
                    
                })
                
                this.totalMoney = Number(this.totalMoney.toFixed(2))
                if(this.$refs.orderMenu?.fullReductionMoney>0){//计算满减
                    this.totalMoney=Number((this.totalMoney-this.$refs.orderMenu?.fullReductionMoney).toFixed(2));
                }
                sumMoney=Number(sumMoney.toFixed(2));
                this.discountMoneuy= Number((sumMoney-this.totalMoney).toFixed(2));//优惠金额
            }
            //计算尾数调整
            this.unPaidMoney=this.$global.mantissaAdjust(_data.FastFoodMantissaAdjustKind,this.totalMoney);
            //尾数调整金额 应收 - 合计金额
            this.MantissaMoney=Number((this.unPaidMoney-this.totalMoney).toFixed(4));

            
            if(this.paymentHistory){
                this.paymentHistory.forEach(pay=>{
                    this.unPaidMoney = this.unPaidMoney-pay.Payment_Money
                })
            }
            this.unPaidMoney=Number(this.unPaidMoney.toFixed(2));

            if(this.unPaidMoney<=0){
                this.unPaidMoney=0
            }
            this.money = this.unPaidMoney;
            //副屏显示
            // this.$webBrowser.openAuxiliaryScreen({
            //     bill:this.orderlistcomputed,
            //     total:{
            //         totalMoney:Number(Number(this.totalMoney) + Number(this.discountMoneuy)).toFixed(2),//合计金额
            //         number:this.countNumber,//多少份
            //         discountMoney:Number(Number(this.discountMoneuy)+Number(this.MantissaMoney * -1)).toFixed(2),//优惠
            //         money:this.unPaidMoney.toFixed(2)//应收
            //     }
            // });
        },*/
        /**默认获取第一个餐厅ID */
        thefirstcanteenID(){
            this.PosRooms.some((pos,i)=>{
                if(i==0){
                    this.RoomAutoID = pos.Room_AutoID
                }
            })
        },
        /**昆仑卡券 或 正品卡券 核销返回 */
        couponsReturn(data){
            this.$message.success("卡券核销完成");
            this.$webBrowser.openAuxiliaryScreen({
                PayStatus:true
            })
            this.klCouponShow = false;
            this.couponVerifyShow = false;
            if(data?.ResponseBody){//接口返回成功后，相当于正常的结账流程，会返回单据打印信息，前端注意发起结账打印。
                if(data.ResponseBody.PrintsInfo){
                    this.$webBrowser.posPrint(data.ResponseBody.PrintsInfo);
                }
                if(data.ResponseBody.OpenCashBox){
                    this.$webBrowser.openCashBoxBnt().then(d=>{
                        if(d?.state!=0){
                            this.$message.error('钱箱打开失败:'+(d?.message||""));
                        }
                    });
                }
                if(data.ResponseBody.VoiceNotify){//语音提醒内容，如果返回不为空，前端可以发起一个语音播报
                    this.$webBrowser.tts(data.ResponseBody.VoiceNotify);
                }
            }
            this.emptydata()
        },
        submiConfirm(){
            this.arShow=false;
            this.zpCouponShow=false;
            this.ymCouponShow=false;
            this.westStoredCouponShow=false;
            this.$webBrowser.openAuxiliaryScreen({
                PayStatus:true
            })
            this.emptydata()
        },
        /**ar账/正品卡券核销支付记录返回保存*/
        subMultipleConfirm(data,selectCoupon){
            if(data.IsMultipleCardPay){//多支付
                if(data.Payments.length>0){
                    //ar挂账级 券核销 只能单支付, 不清除没有提交的支付方式
                    //this.paymentHistory=this.paymentHistory.filter(it=>it.Eat_PayDetailID);//排除没有提交的支付方式
                    data.Payments.forEach(pay=>{
                        if(!this.paymentHistory.some(it=>it.Eat_PayDetailID==pay.Eat_PayDetailID)){//判断不能有重复支付记录
                            let item={
                                Payment_ID:pay.Payment_ID, //支付方式ID
                                Payment_Code:pay.Payment_Code,  //支付方式编号
                                Payment_Name:pay.Payment_Name,  //支付方式名称
                                Payment_Type:pay.Payment_Type,  //支付方式类型
                                Payment_Money:Number(pay.Payment_Money), //支付方式金额
                                Payment_FactMoney:Number(pay.Payment_Money),  //支付实收金额
                                Qr_Code:"", //扫码付款码值
                                Card_No:"", //卡号
                                CouponInfo:null, //该支付金额核销的券信息
                                Time:this.Times(),
                                is_pay:true, /**付款记录是否写入POS */
                                Eat_PayDetailID:pay.Eat_PayDetailID, //支付明细的ID
                                Operator_Name:this.userInfo?.Login_Name /**操作员 */
                            }
                            this.paymentHistory.push(item);
                        }
                    });
                }
                this.arShow=false;
                this.zpCouponShow=false;
                this.ymCouponShow=false;
                this.westStoredCouponShow=false;
                this.EatAutoID = data.Eat_AutoID
                this.Timestamps = data.Timestamps
                this.IsMultipleCardPay = data.IsMultipleCardPay
                if(selectCoupon){
                    selectCoupon.forEach(se=>{
                        this.orderMenuList.forEach(order=>{
                            if(se.ConsumeID==order.Eat_ConsumeID){
                                order.Exchanged_Number = this.addNum(1,order.Exchanged_Number)
                                //记录菜品和消费过的券对应关系,退款冲销清掉
                                data.Payments.forEach(pay=>{
                                    let coufood={
                                        ConsumeID:se.ConsumeID,
                                        EatPayDetailID:pay.Eat_PayDetailID
                                    }
                                    this.merCouponFood.push(coufood)
                                }) 
                            }
                        })
                    })
                }
            }else{//支付完成
                this.submiConfirm();
            }
        },
        /**计算会员价 */
        FoodVipMoney(item){
            if(this.carPriceType==false){
                let _vipType=0
                this.orderMenuList.forEach(order=>{
                    if(order.Eat_VipPrice && order.ConsumeOperations.length==0){
                        if(order?.ConsumeOperations.length==0){
                            if(order.Eat_CheckPrice==order.Eat_SalePrice){
                                this.discountMoneuy += (order.Eat_CheckPrice - order.Eat_VipPrice) * order.Eat_Number
                                order.Eat_CheckPrice = order.Eat_VipPrice
                                order.ConsumeOperations=[]
                                let _Operations={
                                    OperateType:4,
                                    OperateReason:'',
                                    Operator:this.userInfo?.Login_Name,
                                    Project_AutoID:'',Project_Name:'',Project_Kind:0,Project_Rate:0,Old_Price:order.Eat_SalePrice
                                }
                                if(!Object.keys(this.checkUser).length==0){
                                    _Operations.Operator=this.checkUser.Login_Name; //获取用户id
                                }
                                order.ConsumeOperations.push(_Operations)
                                _vipType=1
                            }
                        }
                    }
                })
                if(_vipType==1){
                    this.$message.success('会员价调整完成')
                    this.carPriceType=true
                    item.ProjectItem_Name='取消会员价'
                }else{
                    this.$message.warning('没有需要计算会员价的菜品')
                }
            }else if(this.carPriceType==true){
                item.ProjectItem_Name='会员价'
                this.carPriceType=false 
                this.orderMenuList.forEach(order=>{
                    if(order.Eat_SalePrice){
                        if(order?.ConsumeOperations[0].OperateType==4){
                            order.Eat_CheckPrice = order.Eat_SalePrice
                            this.discountMoneuy -= (order.Eat_CheckPrice - order.Eat_VipPrice) * order.Eat_Number
                            order.ConsumeOperations=[]
                        }
                    }

                }) 
            }
            this.checkUser={}
        },
        /**点单界面横排数量按钮 */
        keyNumber(number,type){
            if(this.orderNumber.indexOf('.')!=-1 && number=='.'){
                return 
            }
            if(type || type==0){
                this.orderNumberType=type
            }
            if(number>=0 || number=='.'){
                if(this.orderNumber.length>0 && number=='.'){
                    this.orderNumber =  this.orderNumber+number
                }else if(number!='.'){
                    this.orderNumber =  this.orderNumber+number
                }
            }else if(number=='-1' && this.orderNumber.length>0){
                this.orderNumber= this.orderNumber.substr(0,this.orderNumber.length-1);
            }
            let index=this.orderNumber?.indexOf(".");
            if(index>=0){//保留小数位4位
                this.orderNumber= this.orderNumber.substring(0,index+5);
            }
        },
        paySuccessConfirm(){
            this.memberPayShow=false;
            this.wlivePayShow=false;
            this.newEnjoyShow=false;
            this.kunlunPayShow=false;
            this.daNianPayShow=false;
            this.gNinePayShow=false;
            this.hdShow=false;
            this.westStoredShow=false;
            this.$webBrowser.openAuxiliaryScreen({
                PayStatus:true
            })
            this.emptydata()
        },
        /**清空页面数据 */
        emptydata(){
            this.orderMenuList=[],this.TemporderMenuList=[],this.masKinds=[],this.Temporaryorder=[],
            this.mallMethod=[], //全单口味做法
            this.aloneMethod=[], //单独口味做法
            this.choicePayType=[],this.prepaidshow=false,
            this.orderChannel=[],this.Channellike='',this.paymentHistory=[],this.assistSizeInfo={},
           
            this.carPriceType=false,this.disRadioOrder={},this.choicePosFoods='',
            this.disRadioclass=-1,this.foodSetmealsclass=-1,this.foodtype=0,this.paymentShow=false,
            this.DeskName='',this.meberInfo=null,this.EatAutoID='',this.Timestamps='',this.payCouponsData={},
            this.foodOperationRecord=[],this.$route.params.data='',this.IsMultipleCardPay=false,
            this.checkBtnlist='',this.checkUser={}
            this.DefaultChannelID = this.userInfo?.Default_ChannelID
            this.eatCheckIndex=''
            this.merCouponFood=[]
            this.headingInfo={
                DeskName:'',
                DeskAutoID:'',
                RoomAutoID:'',
                DinersNum:1,
                orderChannelName:'',
                OrderChannel_AutoID:'',
                brandNumber:'',
                orderRemark:''
            }
            this.posFoodNumbermethod(4)
            this.OrderChannelUpdate()
            this.thefirstcanteenID()
            if(_data.RunFastFoodMustChooseDeskNo==true){
                this.choiceDeskShow=true
            }
            this.QKOrderMain.map(QK=>{
                if(QK.ProjectItem_Key=='MemberPrice'){
                    QK.ProjectItem_Name='会员价'
                }
            })
            setTimeout(() => {
                 this.$refs.searchBox.focus();
                 console.log("invoicingConfirm")
            },100);
        },
        invoicingConfirm(){
            this.onPayPreview()
        },
        //刷新菜品数据
        RefreshData(){
            const loading = this.$loading({
                text: "信息加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$cacheData.RefreshSoldInfo().then(()=>{
                loading.close();
            }).catch((e)=>{ 
                loading.close();
                this.$message.error("刷新失败:"+e.message);
            });
        },
        /**权限控制 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.checkBtnlist.Permission = true;
            this.checkUser = date
            this.MiddleMenuClick(this.checkBtnlist)
        },
        /**重印上一单 */
        printPreViewBill(){
            let param={
                Body_ID:newGuid(),
                User_ID:this.userInfo?.User_ID,//用户id
                Operator_Name:this.userInfo?.Login_Name /**操作员 */
            }
            const loading = this.$loading({
                text: "重印上一单中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.ReprintPreviousOrder",param).then((data)=>{
                loading.close();
                if(data.ResponseHeader.ResultCode!=0){
                    this.$message.error(data.ResponseHeader.ResultDesc)
                    if(data.ResponseBody?.Timestamps){
                        this.orderInfo.Timestamps = data.ResponseBody?.Timestamps.toString()
                    }
                }else{
                    setTimeout(()=>{
                        this.$webBrowser.posPrint(data.ResponseBody);
                    },100);
                    this.$message.success("已发送打印指令");
                }
            }).catch((e)=>{
                loading.close();
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**读卡识别菜品 
         * @param callback Function  返回 菜品id 数组
        */
        readCardFood(callback){
            if(!_data.Running_DtFoodCard_YN){
                this.$message.warning("该门店未注册读卡点菜功能!");
                return ;
            }
            const loading = this.$loading({
                text: "读卡中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.readCardOrder().then(ids=>{
                loading.setText("查找对应菜品中");
                console.log("卡数据：",ids)
                this.$httpAES.post("Bestech.CloudPos.GetDtCardsMappingFoods", {Card_NOList:ids}).then((d)=>{
                    loading.close();
                    if(d.ResponseHeader.ResultCode!=0){
                        this.$message.error(d.ResponseHeader.ResultDesc)
                        return;
                    }else{
                        typeof(callback)=="function" && callback(d.ResponseBody);
                    }
                }).catch((e)=>{
                    loading.close();
                    this.$alert("卡号绑定的菜品数据加载失败"+e.message, "提示", {confirmButtonText: "确定"});
                });
            }).catch(d=>{
                loading.close();
                this.$message.warning(d.message);
            })
        },
        /**读卡 返回多个卡数据 */
        readCardOrder(){
            return new Promise((resolve,reject) => {
                this.$webBrowser.getReadCardOrder().then(d=>{
                    if(d){
                        if(d.isEnableReadCard){
                            this.$webBrowser.readCardOrder(d.brand,d.port,d.baud).then(d=>{//读卡器开启成功
                                if(d && d.state==0){
                                    if(d.data?.length>0){
                                        resolve(d.data);
                                    }else{
                                        reject({message:'未读到卡数据!'})
                                    }
                                }else{
                                    console.log("读卡失败",d);
                                    reject({message:'未读到卡片'})
                                }
                            });
                        }else{
                            reject({message:'未启用读卡点菜功能!'})
                        }
                    }else{
                        reject({message:'读卡点菜未配置!'})
                    }
                });
            });
        },
        /**读卡点菜添加到数组中 */
        readCardAddFood(food){
            let SoloOutfood='',limitedProject='',cardNO=''
            food=food.filter(it=>{
                if(!it.EAT_XFBMID){
                    cardNO+=it.Card_NO+','
                    return false;
                }
                return true;
            })
            this.foodAll.forEach(pos=>{
                food.forEach(food=>{
                    if(food.EAT_XFBMID==pos.Food_ID){
                        //已被沽清的菜品
                        if(pos.Is_SoldOut){
                            SoloOutfood+=pos.Food_Name+','
                            return true
                        }
                        //是否限量销售
                        if(pos.Is_Limited){
                            if(pos.RemainNum==0){
                                //是否有限量销售方案
                                if(pos.Is_LimitedProject){
                                    limitedProject+=pos.Food_Name+','
                                    return true
                                }
                            }
                        }
                        //点单时,相同菜品自动合并一行
                        if(_data.MergeSameFoodInOrder){
                            let order = this.orderMenuList.find(order=>!order.Is_Combo && order.EAT_XFBMID==pos.Food_ID && order.Food_Status==-1 && order.Eat_MasName=='' )
                            if(order){
                                order.Eat_Number++
                                if(pos.Food_AssSize){
                                    order.AssSizeNumber ++
                                }
                            }else{
                                this.addFood(pos)
                            }
                        }else{
                            this.addFood(pos)
                        }
                    }
                })
            })
            if(cardNO){
                cardNO = cardNO.substring(0,cardNO.lastIndexOf(','))
                this.$message({
                    dangerouslyUseHTMLString:true,
                    message:'<div style="word-wrap:break-word;max-width:600px;win-width:100px;">'+cardNO+'</div>卡号下没有菜品信息',
                    type:'warning'
                })
            }
            if(SoloOutfood){
                SoloOutfood = SoloOutfood.substring(0,SoloOutfood.lastIndexOf(','))
                this.$message.warning(SoloOutfood+'是沽清的菜品,无法添加到订单中');
            }
            if(limitedProject){
                limitedProject = limitedProject.substring(0,limitedProject.lastIndexOf(','))
                this.$message.warning(SoloOutfood+'菜品已售完,无法添加到订单中');
            }
        },
        addFood(pos){
            //添加到订单
            let _item ={
                Eat_ConsumeID:newGuid(),
                SubCategory_ID:pos.SubCategory_ID,
                EAT_XFBMID:pos.Food_ID,
                EAT_XFCode:pos.Food_Code,
                EAT_XFName:pos.Food_Name,
                Eat_Number:1,
                Eat_CheckPrice:pos.Food_Price,
                ModiPrice:0,
                Eat_SalePrice:pos.Food_Price,
                Food_CostPrice:pos.Food_CostPrice,//成本金额
                Eat_MasName:'',
                Food_Status:-1,
                Food_AssSize:pos.Food_AssSize,
                AssSizeNumber:'',
                Eat_XFSize:pos.Food_Size,
                FoodModifys:null,
                FoodSetmeals:null,
                Is_Special:pos.Is_Special,//是否是特色菜
                Is_Discounted:pos.Is_Discounted,
                Is_Seasonal:pos.Is_Seasonal,
                RemainNum:pos.RemainNum,
                Is_Limited:pos.Is_Limited,
                Is_ChangedNum:pos.Is_ChangedNum,
                Is_ChargeForService:pos.Is_ChargeForService,
                Is_SetMeal:pos.Is_SetMeal,
                Promotion_Price:0,
                Exchanged_Number:0,
                ConsumeOperations:[]};
            //促销价
            if(pos?.Promotion_Price || pos?.Promotion_Price==0){
                _item.Eat_CheckPrice = pos?.Promotion_Price
                _item.Promotion_Price = pos?.Promotion_Price
                _item.ConsumeOperations=[{OperateType:5}]
            }
            //有辅助单位
            if(pos.Food_AssSize){
                _item.AssSizeNumber=1
            }
            this.orderMenuList.push(_item);
            this.radioClick(this.orderMenuList.length-1,_item);
            this.posFoodNumbermethod(1)
        },
        /**当有弹层显示出来时 */
        modalShow(){
            this.$refs.searchBox?.close(true);//关闭键盘并标记
        },
        /**当所有弹层关闭时 */
        modalClose(){
            this.$refs.searchBox.focus();//获取焦点
            //判断键盘 未标记前是否是显示的
            if(this.$refs.searchBox?.oldIsShow){
                this.$refs.searchBox?.showKeyBoard();//显示键盘
            }
        },
        /**是否标准称重单位 */
        isUnitWeight(unit){
            if(unit=="斤" || unit=="公斤" || unit=="克" || unit=="市斤" || (unit||"").toUpperCase() =="KG" || (unit||"").toUpperCase()=="G" ){
                return true;
            }
            return false;
        }
    },
    //被卸载时
    unmounted(){
        this.$webBrowser.off("posOffLine");
        this.$webBrowser.off("openYoyoAi");
        this.$shortcutKey.setFun();
        this.$webBrowser.openAuxiliaryScreen({bill:[]});//显示副屏
    }
}
</script>
<style lang="scss">
@import "./fastFoodDetail.scss";
</style>