<template>
    <div class="diningTable">
        <div class="divisionlin"></div>
        <div class="filter-box clearfix">
            <div class="filter-bnt" :class="{selected:DeskType==-1}" @click="DeskTypeClick(-1)">全部({{RoomDeskType.sum}})</div>
            <div class="filter-bnt bnt-inUseNum" :class="{selected:DeskType==-2}" @click="DeskTypeClick(-2)">在用({{RoomDeskType.inUseNum}})</div>
            <div class="filter-bnt bnt-empty" v-if="isRendering" :class="{ selected: DeskType == 0 }" @click="DeskTypeClick(0)"><b class="bnt-bg"></b>空台(<span class="num">{{RoomDeskType.emptyNum}}</span>)</div>
            <div class="filter-bnt bnt-opening" v-if="isRendering" :class="{ selected: DeskType == 1 }" @click="DeskTypeClick(1)"><b class="bnt-bg"></b>开台(<span class="num">{{RoomDeskType.openingNum}}</span>)</div>
            <div class="filter-bnt bnt-reserve" v-if="isRendering" :class="{ selected: DeskType == 2 }" @click="DeskTypeClick(2)"><b class="bnt-bg"></b>预定(<span class="num">{{RoomDeskType.reserveNum}}</span>)</div>
            <div class="filter-bnt bnt-preKnot"  v-if="isRendering" :class="{ selected: DeskType == 3 }" @click="DeskTypeClick(3)"><b class="bnt-bg"></b>预结(<span class="num">{{RoomDeskType.preKnotNum}}</span>)</div>
            <div class="filter-bnt bnt-overtime" v-if="isRendering" :class="{ selected: DeskType == 4 }" @click="DeskTypeClick(4)"><b class="bnt-bg"></b>超时(<span class="num">{{RoomDeskType.overtimeNum}}</span>)</div>
            <div class="filter-bnt bnt-clean" v-if="isRendering" :class="{ selected: DeskType == 5 }" @click="DeskTypeClick(5)"><b class="bnt-bg"></b>清台(<span class="num">{{RoomDeskType.cleanNum}}</span>)</div>
        </div>
        <div class="divisionlin"></div>
        <div class="filter-nav clearfix" v-if="isRendering">
            <div class="nav-box" style="position:absolute;overflow: hidden;" >
                    <div class="nav-li" :class="{ selected: filternav == -1 }" @click="NavigationClick(-1)">全部</div>
            </div>
            <div class="nav-box" style="margin-left: 68px;position: relative;" v-mouse-scroll v-scroll-anime="{up:'nav-up',down:'nav-down',fx:'x',liClass:'.nav-li',page:'DeskNextPage'}">
                <div class="nav-li" :class="{ selected: filternav == index }" 
                    v-for="(item,index) in RoomDeskList.PosRooms" :key="item"  
                    @click="NavigationClick(index)" >
                {{item.Room_Name}}
                </div>
            </div>
            <div class="float-right page-box">
                <span class="last-page iconfont icon-fangxiangquan-left" ref="nav-up" ></span>
                <span class="page-number">{{DeskNextPage.pageIndex}}/{{DeskNextPage.pageSize}} 页</span>
                <span class="next-page iconfont icon-fangxiangquan-right" ref="nav-down" ></span>
            </div>
        </div>
        <div class="desk content clearfix"  >
            <div class="verticalCenter" v-if="!isRendering" style="height:100%;text-align: center; ">
                <div style="display: inline-block;vertical-align: middle;text-align: left;">
                <el-empty description="无数据,点击重新加载" @click="ShowRoomDeskList()">
                </el-empty>
                </div>
            </div>
            <div class="scrollBox" v-layout-rc="arg" v-if="isRendering">
                <!--0：空台 1：开台 2：预定 3：预结 4：超时 5：清台-->
                <div class="desk-box" rc-item @click="DeskClick(PosDesks)" 
                v-for="(PosDesks,index) in getPosDesks()" :key="index" 
                :class="{'empty': PosDesks.Desk_Status == 0,'opening':PosDesks.Desk_Status==1,'reserve':PosDesks.Desk_Status==2,'preKnot':PosDesks.Desk_Status==3,'overtime':PosDesks.Desk_Status==4,'clean':PosDesks.Desk_Status==5}">
                    <div class="top" v-if="PosDesks.OpenDeskInfo" >
                        <span v-if="PosDesks.Desk_Status!=2 && PosDesks.Desk_Status!=5">{{PosDesks.OpenDeskInfo.Person_Num}}人</span> <!-- 人数-->
                        <span v-if="PosDesks.Desk_Status==2">订</span>
                        <span class="remark" v-if="PosDesks.OpenDeskInfo.ShowRemarkFlag">备注</span>
                        <span v-if="PosDesks.OpenDeskInfo.Eat_FoodCounts>=0 && PosDesks.Desk_Status!=2  && PosDesks.Desk_Status!=5" class="float-right" >{{Conversion(PosDesks.OpenDeskInfo.Crossed_FoodCounts)}}/{{Conversion(PosDesks.OpenDeskInfo.Eat_FoodCounts)}}</span>  <!--已划菜数量/点菜总数量-->
                        <span v-if="PosDesks.ReserveDeskInfo && PosDesks.Desk_Status==2 && MapReserveOperator==false" class="float-right" >{{PosDesks.ReserveDeskInfo.Cstm_Name}}</span> <!--预定客户-->
                        <!-- 厨房桌台显示状态 -->
                        <div v-if="globalSysSetting?.RunKDSMonitorScreenShowFromDeskStatus">{{({3:"等叫",4:"起菜",5:"催菜",6:"上齐"})[PosDesks.KitDesk_Status]||""}}</div>
                    </div>
                    <div class="top" v-else-if="PosDesks.Desk_Status==2" >
                        <span>订</span>
                        <span v-if="PosDesks.ReserveDeskInfo?.Cstm_Name && MapReserveOperator==false" class="float-right" >{{PosDesks.ReserveDeskInfo?.Cstm_Name}}</span>
                        <!-- <span v-if="PosDesks.ReserveDeskInfo?.Mobile" class="float-right" >{{PosDesks.ReserveDeskInfo?.Mobile}}</span> -->
                    </div>
                    <p class="title">
                        {{PosDesks.Desk_Name}}
                        <span class="munion"  v-if="PosDesks.OpenDeskInfo?.Eat_UnionID">
                            <template v-if="PosDesks.OpenDeskInfo.Eat_UnionID==PosDesks.OpenDeskInfo.Eat_AutoID">
                                联{{unionOrder[PosDesks.OpenDeskInfo.Eat_AutoID]}}
                            </template>
                            <template v-else>
                                联{{unionOrder[PosDesks.OpenDeskInfo.Eat_UnionID]}}
                            </template>
                        </span>
                    </p>
                    <div class="bottom" v-if="PosDesks.OpenDeskInfo" >
                        <div>{{PosDesks.OpenDeskInfo?.Eat_Saler}}</div>
                        <span v-if="PosDesks.Desk_Status!=2 && PosDesks.Desk_Status!=5" >{{DifferenceTime(PosDesks.OpenDeskInfo.OpenDesk_Time)}}</span> <!--开台时间-->
                        <span v-if="PosDesks.ReserveDeskInfo && PosDesks.Desk_Status==2 && MapReserveMobile==false" >{{PosDesks.ReserveDeskInfo.Mobile}}</span> <!--预定手机号-->
                        <span v-if="PosDesks.OpenDeskInfo.Eat_CheckMoney && PosDesks.Desk_Status!=2  && PosDesks.Desk_Status!=5 && MapConsumeMoney==false" class="float-right">￥{{PosDesks.OpenDeskInfo.Eat_CheckMoney}}</span> <!--点餐总金额-->
                        <span v-else-if="PosDesks.ReserveDeskInfo && PosDesks.Desk_Status==2" class="float-right" >{{PosDesks.ReserveDeskInfo.Reserve_PersonNum}}人</span>
                    </div>
                    <div class="bottom" v-if="PosDesks.Desk_Status==2" >
                        <span v-if="PosDesks.ReserveDeskInfo?.Mobile && MapReserveMobile==false" >{{PosDesks.ReserveDeskInfo?.Mobile.replace(/^(.{3}).*(.{4})$/,'$1****$2')}}</span>
                        <span class="float-right" v-if="PosDesks.ReserveDeskInfo">{{PosDesks.ReserveDeskInfo.Reserve_PersonNum}}人</span>
                    </div>
                    <div class="prerange" v-html="CreateRange(PosDesks)" v-if="PosDesks.OpenDeskInfo" ></div>
                    <div class="posTop">
                        <!-- 开台超时 闹钟图标 -->
                        <div class="alarmClock" v-if="PosDesks.Desk_Status==4" @click.stop="">
                            <el-tooltip effect="dark" trigger="click" :content="PosDesks.OpenDeskInfo?.timeoutTip" 
                                placement="bottom" :auto-close="10000">
                                <img src="/images/alarmClock.png" />
                                
                            </el-tooltip>
                        </div>
                        <i class="iconfont icon-suo" v-if="PosDesks.Is_Desk_Locked"></i>
                    </div>
                    
                </div>
            </div>
        </div>
        <div class="footer clearfix">
            <div class="deskbottom" >
                <div class="float-left search" @click="DeskTextClick()" >
                    <div class="DeskAuto" @click="showKeyBoard()" @mousedown="(e)=>e.preventDefault()">
                        <span class="iconfont icon-jianpan"></span>
                    </div>
                    <input type="text" placeholder="桌号检索" v-model="DeskAutoSelce" ref="searchBox" @blur="searchInputBlur"/>
                </div>
                <div class="float-right">
                    <div class="bnt shuaxin-bnt" @click="RefreshData()"><i class="iconfont icon-a-shuaxin3x"></i>刷新</div>
                    <div class="page-box-bottom"><span class="last-page iconfont icon-fangxiangquan-left" @click="PreviPageClick()"></span>
                        <span class="page-number">{{ NextIndex }}/{{ Totalpages }}页</span>
                        <span class="next-page iconfont icon-fangxiangquan-right" @click="NextPageClick()" ></span>
                    </div>
                </div>
                
            </div>
            <div class="divisionlin-bottom"></div>
            <div class="bnt-box">
                <div class="bnt" :class="{bntMore:Menu.ProjectItem_Key=='OtherFunc'}" v-for="(Menu,ProjectItem_Index) in userNavMenu" :key="ProjectItem_Index" v-show="Menu.Is_Show" @click="BottomClick(Menu)">
                {{Menu.ProjectItem_Name}}
                <span class="shortKey" v-if="Menu.ProjectItem_ShortcutKey">{{Menu.ProjectItem_ShortcutKey}}</span>
                </div>
            </div>
        </div>
        <!--开台弹出层-->
        <modal-load :isShow="isShow" isUnload>
            <opening :posDeskInfo="choicePosDesks" :openDeskName="DeskName" :opentype="1" :openDeskCode="DeskCode" :openisShow="isShow" :openDeskAuto="DeskAutoID" :Reserve_Operator="Reserve_Operator" @CloseClick="CloseClick" @SetOpenDesk="SetOpenDesk"></opening>
        </modal-load>
        <!--换台弹出层-->
        <changeing :openischangeShow="ischangeShow" :posRooms="RoomDeskList.PosRooms" @CloseChangeingClick="CloseChangeingClick" @SetChangeDesk="SetChangeDesk" v-if="RoomDeskList.PosRooms"></changeing>
        <!--并台弹出层-->
        <merging :mergeisShow="isMergeShow" :posRooms="RoomDeskList.PosRooms" @CloseMergingClick="CloseMergingClick" @setMergeDesk="setMergeDesk" v-if="RoomDeskList.PosRooms"></merging>
        <!--联台结账弹出层-->
        <union-Bill-model :posRooms="RoomDeskList.PosRooms" :unionisShow="unionisShow" v-on:closeModel="unionisShow=false"  @CloseunionClick="CloseunionClick" ></union-Bill-model>
        <!--预定开台操作-->
        <modal-load :isShow="isMakeShow" :isUnload="true">
            <make-Table-Model :isShow="isMakeShow" v-on:closeModel="isMakeShow=false" :choicePosDesks="choicePosDesks" :openDeskName="DeskName" :Reserve_Operator="Reserve_Operator" @UnscheduledClick="UnscheduledClick" @deleteCloseClick="deleteCloseClick" @SetOpenDesk="SetOpenDesk"  ></make-Table-Model>
        </modal-load>
        <!--锁台操作-->
        <modal-load :isShow="isLockDesk">
            <lock-desk-model :isShow="isLockDesk" v-on:closeModel="isLockDesk=false"></lock-desk-model>
        </modal-load>
        <!--解锁操作-->
        <modal-load :isShow="isUnLockDesk">
            <lock-desk-model :isShow="isUnLockDesk" :isUnLock="true" v-on:closeModel="isUnLockDesk=false"></lock-desk-model>
        </modal-load>

        <!--拆虚拟台-->
        <modal-load :isShow="isdismantleDesk">
            <dismantle-desk-model :isShow="isdismantleDesk" v-on:closeModel="isdismantleDesk=false" :type='1' v-on:updateDesk="updateDeskMaxTimestamps"></dismantle-desk-model>
        </modal-load>

        <!--撤销拆台-->
        <modal-load :isShow="isCancelDismantleDesk">
            <dismantle-desk-model :isShow="isCancelDismantleDesk" v-on:closeModel="isCancelDismantleDesk=false" :type='2' v-on:updateDesk="updateDeskMaxTimestamps"></dismantle-desk-model>
        </modal-load>

        <!--撤销开台-->
        <modal-load :isShow="isCancelDesk">
            <dismantle-desk-model :isShow="isCancelDesk" v-on:closeModel="isCancelDesk=false" :type='3' v-on:updateDesk="updateDeskMaxTimestamps"></dismantle-desk-model>
        </modal-load>
        <!--其他功能-->
        <modal-load :isShow="subShow">
            <sub-Item-Model :isShow="subShow" v-on:closeModel="subShow=false" :otherFuncList="otherFuncList" @subItemReturn="subItemReturn" ></sub-Item-Model>
        </modal-load>
        <!--权限控制-->
        <modal-load :isShow="checkShow">
            <check-user-button-model :isShow="checkShow" v-on:closeModel="checkShow=false" :checkBtnlist="checkBtnlist" @checkbtnReturn="checkbtnReturn" ></check-user-button-model>
        </modal-load>
        <!-- 补打厨房单 -->
        <modal-load :isShow="kitOrderPrintShow" isUnload>
            <kit-order-print-model :isShow="kitOrderPrintShow" @close="kitOrderPrintShow=false"/>
        </modal-load>
        <analog-key-board ref="keyBoard"></analog-key-board>
    </div>
</template>


<script>
import { reactive } from 'vue'
import opening from "../../home/diningTable/model/opening";
import changeing from "../../home/diningTable/changeing/changeing";
import merging from "../../home/diningTable/merging/merging";
import unionBillModel from "../diningTable/unionBillModel/unionBillModel.vue"
import lockDeskModel from "./lockDeskModel/lockDeskModel.vue"
import subItemModel from './diningDetail/subItemModel/subItemModel.vue'
import makeTableModel from './model/makeTableModel/makeTableModel.vue'
import { httpAES,auth,cacheData } from '/src/services'
import { differenceTime,newGuid,NumberConversion,getDiffMinutes } from '/src/common';
import dismantleDeskModel from './dismantleDeskModel/dismantleDeskModel.vue'
import ModalLoad from '../../template/modal/modalLoad.vue';
import checkUserButtonModel from '../checkUserRightModel/checkUserButtonModel.vue'
import kitOrderPrintModel from './diningDetail/kitOrderPrintModel/kitOrderPrintModel.vue'

/**全局初始化数据 */
let _data={
    arg:{row:4,col:6,marginW:'10',marginH:'10'},
    /**一页多少条桌台数据 */
    deskPageSize:4*6,
    RunCheckOutToCleanDesk:false,/**启用买单后桌号进入清台状态 */
    Running_ServingOrder_YN:false,/**是否启用上菜顺序功能 */
    RunCloudPosHideDeskMapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
    RunCloudPosHideDeskMapReserveOperator:false, /**启用触摸屏隐藏桌态图【预订人】信息，true: 隐藏，false:不隐藏 */
    RunCloudPosHideDeskMapReserveMobile:false,/**启用触摸屏隐藏桌态图【预订电话】信息，true: 隐藏，false:不隐藏 */
};

export default {
    name: "diningTable",
    components: {
        opening,
        changeing,
        merging,
        unionBillModel,
        lockDeskModel,
        subItemModel,
        dismantleDeskModel,
        makeTableModel,
        ModalLoad,
        checkUserButtonModel,
        kitOrderPrintModel
    },
    data() {
        let globalSysSetting= cacheData?.globalVariable?.GlobalSysSetting;
        if(globalSysSetting && globalSysSetting.DeskMap_X && globalSysSetting.DeskMap_Y){
            _data.deskPageSize = globalSysSetting.DeskMap_X * globalSysSetting.DeskMap_Y;
            Object.assign(_data.arg,{row:globalSysSetting.DeskMap_X,col:globalSysSetting.DeskMap_Y});
            _data.RunCheckOutToCleanDesk = globalSysSetting.RunCheckOutToCleanDesk
            _data.Running_ServingOrder_YN = globalSysSetting.Running_ServingOrder_YN
            _data.RunCloudPosHideDeskMapConsumeMoney = globalSysSetting.RunCloudPosHideDeskMapConsumeMoney
            _data.RunCloudPosHideDeskMapReserveOperator = globalSysSetting.RunCloudPosHideDeskMapReserveOperator
            _data.RunCloudPosHideDeskMapReserveMobile=globalSysSetting.RunCloudPosHideDeskMapReserveMobile
        }
        return {
            /**锁定桌台 弹层*/
            isLockDesk:false,
            /**解锁桌台 弹层*/
            isUnLockDesk:false,
            /**拆虚拟台 弹层*/
            isdismantleDesk:false,
            /**撤销拆台 */
            isCancelDismantleDesk:false,
            /**撤销开台 */
            isCancelDesk:false,
            /**其他功能 弹层 */
            subShow:false,
            /*是否被卸载 */
            isUnmounted:false,
            /**是否被缓存 */
            isDeactivated:false,
            /**预定桌台 弹层 */
            isMakeShow:false,
            /**设置 固定行数 与 固定列数  */
            arg:_data.arg,
            /*是否显示筛选条件 */
            isRendering:false,
            RoomDeskSum:0, //桌台数量
            /**当前桌台状态类型 筛选 */
            DeskType:-1,
            
            /*当前选中的 餐厅分类 */
            filternav: -1,
            /**餐厅数据 */
            //PosRooms:[],
            /**餐厅页码 */
            DeskNextPage:{
                pageIndex:1,
                pageSize:1
            },
            /**桌台数据*/
            posDesks:[],
            /**桌台信息 */
            DeskMap:null,
            RoomDeskList:{},
            selectIndex: { login: 0 }[this.$route.params.pathname] || 0,
            DeskAutoSelce:'',
            NextIndex: 1, //桌台当前页码
            Totalpages:0, //桌台总页数
            deskPageSize:_data.deskPageSize,//一页多少条桌台数据
            DeskTotalpage:0, //餐厅总页数
            /**菜单按钮 */
            userNavMenu:[],
            DeskAutoID:'',//点击开台所选 桌台id
            DeskCode:'',//点击开台所选 桌台code
            DeskName:'',//点击开台所选 桌台名称
            Reserve_Operator:'',//点击开台所选 销售员
            //开台弹出层变量
            isShow:false,  //是否显示开台弹出层
            ischangeShow:false, //是否显示换台弹出层
            unionisShow:false, //联台结账弹出层
            isMergeShow:false,//是否显示并台
            /**是否从点菜界面返回的 */
            PosDesksRetrun:'',
            /**联台展示编号 */
            MuList:[],
            /**选择的桌台信息 */
            choicePosDesks:'',
            Muindex:0,
            checkShow:false,
            checkBtnlist:'',
            checkBtnType:0,
            BusinessAuditsType:1, /**营业稽核信息 1.警告不影响程序使用, 2.有错误点单收银功能不可用 */
            //上次刷新时间
            RefreshTime:0,
            MapConsumeMoney:false, /**启用触摸屏隐藏桌态图【消费金额】信息，true: 隐藏，false:不隐藏 */
            MapReserveOperator:false,/**启用触摸屏隐藏桌态图【预订人】信息，true: 隐藏，false:不隐藏 */
            MapReserveMobile:false,/**启用触摸屏隐藏桌态图【预订电话】信息，true: 隐藏，false:不隐藏 */
            ConsumePersonAvgRange:null,
            /**补打厨房单 弹层 */
            kitOrderPrintShow:false,
        };
    },
    /**以及每次从缓存中被重新插入的时候调用 */
    activated(){
        //默认焦点
        setTimeout(() => {
            this.$refs.searchBox.focus();
        },500);
        this.$emit("navIndex",0);
        this.isDeactivated=false;
    },
    mounted(){
        console.log("diningTable mounted")
        if(this.$route.params.PosDesksRetrun!=''&& this.$route.params.PosDesksRetrun!=null){
            this.PosDesksRetrun = JSON.parse(this.$route.params.PosDesksRetrun);
        }
        this.isUnmounted=false;
        this.$emit("navIndex",0);
        
        this.ShowRoomDeskList();  //展示桌台数据

        let keyBoardSubItem={};
        let keyBoardOtherFunc={};

        //获取最下方按钮的数据
        let SysProjects=this.$cacheData.SysProjects.get();
        if(SysProjects && SysProjects.length>0){
            SysProjects.forEach(element=> {
            if(element.Project_Key=='Order'){
                element.SysProjectGroups.forEach(project=>{
                if(project.Group_Key=='DeskMap-Bottom'){
                    project.SysProjectItems.forEach(item=>{
                        let Menu=this.userNavMenu[item.ProjectItem_Key];
                        if(Menu==null ||Menu=='undefined' ){
                            this.userNavMenu.push(item);
                        }
                        if(item.ProjectItem_ShortcutKey){
                            keyBoardSubItem[item.ProjectItem_ShortcutKey]=item;
                        }
                        if(item.ProjectItem_Key=='OtherFunc'){
                            item.SysProjectSubItems.forEach(it=>{
                            if(it.ProjectSubItem_ShortcutKey){
                                keyBoardOtherFunc[it.ProjectSubItem_ShortcutKey]=it;
                            }
                            });
                        }
                    })
                }
                })
            }
            });
        }
        //注册快捷键
        this.$shortcutKey.setFun((e,KeyBoard)=>{
            if(this.isDeactivated || this.$global.isLockScreen || this.isModalShow) return;
            if(keyBoardSubItem[KeyBoard]){
                this.subShow=false;
                this.BottomClick(keyBoardSubItem[KeyBoard]);
            }else if(keyBoardOtherFunc[KeyBoard]){
                this.subItemReturn(keyBoardOtherFunc[KeyBoard])
            }
        })
        /**判断是否是点单界面返回 */
        if(sessionStorage.getItem("nextPosDesks")){
            let _desk = sessionStorage.getItem("nextPosDesks")
            let posDesk = this.getDesk(_desk);
        if(posDesk!=undefined){
            posDesk.Is_Desk_Locked = false
        }
            sessionStorage.removeItem("nextPosDesks")
        }
        this.$cacheData.ConsumePersonAvgRange().then((d)=>{
            //console.log(d);
            this.ConsumePersonAvgRange=d.PersonAvgRangeConsumes;
        }).catch((e)=>{
            //console.log("获取人均消费区间失败",e);//
        })

    },
    watch:{
        PosDesksRetrun(){
            if(sessionStorage.getItem('diningDetail')){
                //读取缓存里面的数据
                this.filternav = Number(sessionStorage.getItem("filternav"));
                this.DeskType = Number(sessionStorage.getItem("DeskType"));
                this.NavigationClick(this.filternav)
            }else{
                sessionStorage.removeItem("filternav"); 
                sessionStorage.removeItem('DeskType');
            }
            let posDesk = this.getDesk(this.PosDesksRetrun.Desk_AutoID);
            if(posDesk!=undefined){
                if(this.$route.params.type==1){
                    if(this.PosDesksRetrun?.DeskTimestamps){
                        posDesk.Timestamps = this.PosDesksRetrun.DeskTimestamps.toString()
                        if(this.PosDesksRetrun.unionDeskTime){
                            this.PosDesksRetrun.unionDeskTime.map(un=>{
                                let  unposDesk = this.getDesk(un.Desk_ID);
                                unposDesk.Timestamps = un.DeskTimestamps.toString()
                            })
                        }
                    
                    }
                    if(_data.RunCheckOutToCleanDesk){
                        posDesk.Desk_Status= 5;  
                    }else{
                        posDesk.Desk_Status= 0;//状态调整成空台
                    }
                    let _RoomsList=this.$cacheData.servingRoomsList.getRoomsList();
                    // console.log('_RoomsList:'+JSON.stringify(_RoomsList))
                    // console.log('posDesk:'+JSON.stringify(posDesk))
                    _RoomsList.map(room=>{
                        if(room.Desk_AutoID==posDesk.Desk_AutoID){
                            this.$cacheData.servingRoomsList.delete(room.Desk_AutoID)
                        }
                    })
                    if(this.PosDesksRetrun.unionList){
                        this.PosDesksRetrun.unionList.map(union=>{
                            let  unposDesk = this.getDesk(union.Eat_DeskID);
                            if(_data.RunCheckOutToCleanDesk){
                                unposDesk.Desk_Status =5;  
                            }else{
                                unposDesk.Desk_Status =0//状态调整成空台
                            }
                            
                        })
                    }
                }else{
                    if(posDesk){
                        if(posDesk.Desk_AutoID==this.PosDesksRetrun.Desk_AutoID){
                            posDesk.OpenDeskInfo.Eat_CheckMoney =this.PosDesksRetrun.Eat_CheckMoney
                            posDesk.OpenDeskInfo.Eat_FoodCounts = this.Conversion(Number(this.PosDesksRetrun.deskCount).toFixed(2))
                            posDesk.OpenDeskInfo.Crossed_FoodCounts=this.PosDesksRetrun.Eat_Number 
                        }
                    }
                }
                if(this.$route.params.isPlace==2){
                    this.$emit("lockScreen")
                    return
                }
            }
        },
        /**桌台信息 */
        DeskMap:{
            handler(){
                console.log("watch DeskMap.data:",this.DeskMap?.data)
                this.RoomDeskList=this.DeskMap?.data;
                if(this.DeskMap?.data){
                    this.isRendering = true;//有数据
                }else{
                    this.isRendering = false;//无数据 需要点击重新加载数据
                }
            },
            deep:true
        },
        PosRooms(){
            if(!this.$global.PosRooms || this.$global.PosRooms==this.PosRooms){
                this.$global.PosRooms=this.PosRooms;
            }else{//当对象 不相同时 更新 $global.PosRooms 对象数据
                if(!this.$global.PosRooms){this.$global.PosRooms=[];}
                else{
                    this.$global.PosRooms.splice(0,this.$global.PosRooms.length);//清空数据
                }
                Object.assign(this.$global.PosRooms,this.PosRooms);//全局变量 赋值数据
            }
        }
    },
    computed:{
        //判断是否有弹出层出现
        isModalShow(){
            if(this.isShow || this.ischangeShow || this.isMergeShow || this.unionisShow 
                || this.isMakeShow || this.isLockDesk || this.isUnLockDesk || this.isdismantleDesk 
                || this.isCancelDismantleDesk || this.isCancelDesk || this.checkShow || this.kitOrderPrintShow
            ){
                return true;
            }
            return false;
        },
        //餐厅数据
        PosRooms(){
            let PosRooms=this.DeskMap?.data?.PosRooms||[];
            let _allDesk=[];
            (PosRooms||[]).forEach((room)=>{
                _allDesk=_allDesk.concat(room.PosDesks);
            })
            if(PosRooms._allDesk){
                PosRooms._allDesk.splice(0,PosRooms._allDesk.length);//清空数据
                Object.assign(PosRooms._allDesk,_allDesk);//全局变量 赋值数据
            }else{
                PosRooms._allDesk=reactive(_allDesk);//所有桌台
            }
            return PosRooms;
        },
        /**获取全部桌台数量,并根据数量展示分页，总页数,桌台数等  */
        RoomDeskType(){
            /**桌台各种类型数量 */
            let RoomDeskType={
                /**空台数 */
                emptyNum:0,
                /**开台 */
                openingNum:0,
                /**预定 */
                reserveNum:0,
                /**预结 */
                preKnotNum:0,
                /**超时 */
                overtimeNum:0,
                /**清台 */
                cleanNum:0,
                //在用中
                inUseNum:0,
                //总数
                sum:(this.posDesks||[]).length,
            };
            (this.posDesks||[]).forEach((desk)=>{
                if(desk.Desk_Status==0){
                    RoomDeskType.emptyNum++;
                }else if(desk.Desk_Status==1){
                    RoomDeskType.openingNum++;
                }else if(desk.Desk_Status==2){
                    RoomDeskType.reserveNum++;
                }else if(desk.Desk_Status==3){
                    RoomDeskType.preKnotNum++;
                }else if(desk.Desk_Status==4){
                    RoomDeskType.overtimeNum++;
                }else if(desk.Desk_Status==5){
                    RoomDeskType.cleanNum++;
                }
            });
            //在用中 括开台和预结和超时
            RoomDeskType.inUseNum=RoomDeskType.openingNum+RoomDeskType.preKnotNum+RoomDeskType.overtimeNum;
            return RoomDeskType;
        },
        /**公共配置信息 */
        globalSysSetting(){
            return this.$cacheData?.globalVariable?.GlobalSysSetting;
        },
        /**联台 分组 顺序 */
        unionOrder(){
            let unionList=[];
            this.PosRooms?._allDesk?.forEach(it=>{
                if(it.OpenDeskInfo?.Eat_UnionID && it.OpenDeskInfo.Eat_AutoID==it.OpenDeskInfo.Eat_UnionID){//联台主桌信息
                    unionList.push({Eat_AutoID:it.OpenDeskInfo.Eat_AutoID,Timestamps:it.Timestamps});
                }
            });
            
            unionList.order("Timestamps","ASC");//时间戳顺序
            let unionKey={};
            unionList.forEach((it,index)=>{
                unionKey[it.Eat_AutoID]=index+1;
            })
            return unionKey;
        }
    },
    methods: {
        logout() {
            auth.logout();
        },
        
        //刷新数据
        RefreshData(){
            let dateTime=(new Date()).getTime();
            if(this.RefreshTime>dateTime-(1000*5)){
                this.$message.warning('操作太频繁,请稍后在刷新')
                return;
            }
            this.RefreshTime=dateTime;
            this.ShowRoomDeskList(true);
        },
        ShowRoomDeskList(isRefresh){
            //参数
            let userInfo=this.$auth.getUserInfo(); //获取用户id
            userInfo.BusinessAudits?.map(bus=>{
                if(bus.Audit_Type==2){
                    this.BusinessAuditsType = bus.Audit_Type
                }
            })
            const loading = this.$loading({
                text: "信息加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let dataNum=0;
            let hideLoaing=()=>{dataNum++;if(dataNum>=2){loading.close();}}
            /**基础配置 */
            this.$cacheData.DeskPosconfigure().then((d)=>{
                hideLoaing();
            // this.arg={row:d.DeskMap_X,col:d.DeskMap_Y,marginW:'10',marginH:'10'};
                Object.assign(_data.arg,{row:d.DeskMap_X,col:d.DeskMap_Y,marginW:'10',marginH:'10'});
                this.deskPageSize=_data.deskPageSize = d.DeskMap_X * d.DeskMap_Y
                if(d?.RunCloudPosHideDeskMapConsumeMoney){
                    this.MapConsumeMoney = d.RunCloudPosHideDeskMapConsumeMoney
                }
                if(d?.RunCloudPosHideDeskMapReserveOperator){
                    this.MapReserveOperator = d?.RunCloudPosHideDeskMapReserveOperator
                }
                if(d?.RunCloudPosHideDeskMapReserveMobile){
                    this.MapReserveMobile = d.RunCloudPosHideDeskMapReserveMobile
                }
                _data.Running_ServingOrder_YN = d.Running_ServingOrder_YN
            }).catch(()=>{
                hideLoaing();
            })

            this.$cacheData.DeskActiveMap(isRefresh).then(d=>{
                hideLoaing();
                this.DeskMap=d;
                this.$nextTick(()=>{
                    this.NavigationClick(-1);//全部 
                    if(this.RoomDeskList) this.isRendering = true;
                })

            }).catch((e)=>{ 
                hideLoaing();
                //判断当前页码是否被 卸载 卸载就不用弹出提示
                if(this.isUnmounted)return;
                this.$alert(e.message, "提示", {confirmButtonText: "确定"});
            })
        },
        /**更新桌台数据 */
        updateDeskMaxTimestamps(Timestamps){
            if(this.RoomDeskList.DeskMaxTimestamps<Timestamps){
                this.RoomDeskList.DeskMaxTimestamps=Timestamps
            }
        },
        //桌台分页-上一页
        PreviPageClick() {
            if(this.NextIndex==1){
                this.$message.warning('已到第一页')
                return;
            }
            if (this.NextIndex > 1) {
                this.NextIndex = this.NextIndex - 1;
            }
        },
        //桌台分页-下一页
        NextPageClick() {
            if(this.NextIndex == this.Totalpages){
                this.$message.warning('已到最后一页')
                return;
            }
            if (this.NextIndex < this.Totalpages) {
                this.NextIndex = this.NextIndex + 1;
            }
        },
        //桌台类型检索
        DeskTypeClick(index) {
            this.DeskType = index;
            this.DeskAutoSelce='';
            //初始之前选择桌台的页数
            this.NextIndex=1;
        },
        //餐厅名称导航条切换
        NavigationClick(index) {
            //清空查询条件栏
            this.DeskAutoSelce=''
            this.filternav = index;
            //初始之前选择桌台的页数
            this.NextIndex=1;
            if(index>=0){
                this.posDesks=this.PosRooms[index]?.PosDesks;
            }else{
                this.posDesks=this.PosRooms._allDesk;
            }
            //默认焦点
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },500);
        },
        //桌号检索
        DeskTextClick() {
            if(this.BusinessAuditsType==2){
                this.$message.warning('店面配置出错,暂时无法使用该功能')
                return;
            }
            this.filternav=-1;
            this.DeskType=-1;
            this.posDesks=this.PosRooms._allDesk;
        },
        //获取开台时间和当前时间的时间差
        DifferenceTime(beginTime) {
            let _time =differenceTime(beginTime);
            if(_time=='-1:-1'){
                _time='0:0'
            }
            return _time;
        },
        /**根据桌台id 查找桌台数据 */
        getDesk(Desk_AutoID){
            return this.PosRooms?._allDesk?.find((d)=>{
                return d.Desk_AutoID==Desk_AutoID;
            })
        },
        /**显示桌台数据 */
        getPosDesks(){
            //搜索框是否为空
            let data=[];
            if(this.DeskAutoSelce!=''){
                let searchTxt=this.DeskAutoSelce.toLowerCase();
                //检索
                data=this.posDesks.filter((item)=>{
                    return (item.Desk_Code||"").toLowerCase().indexOf(searchTxt)>=0 || (item.Desk_Name||"").toLowerCase().indexOf(searchTxt)>=0;
                });
                this.Totalpages=1;
                this.NextIndex =1; //当前页
                if(data.length==1){
                    this.DeskClick(data[0])
                    this.DeskAutoSelce=''
                }
                return data;
            }else{
                //桌台类型 DeskType
                if(this.DeskType!=-1){
                    if(this.DeskType==-2){//在用中 括开台和预结和超时
                        data=this.posDesks.filter((item)=>
                            item.Desk_Status==1|| item.Desk_Status==3 || item.Desk_Status==4
                        )
                    }else{
                        data=this.posDesks.filter((item)=>{
                            return item.Desk_Status==this.DeskType;
                        })
                    }
                    
                }else{
                    data=this.posDesks;
                }
            }
            this.Totalpages=Math.ceil(data.length/this.deskPageSize)||1;
            //分页
            if (this.NextIndex == 1) {
                if(data.length<this.deskPageSize){
                    return data;
                }else{
                    return data.slice(this.NextIndex-1, this.NextIndex * this.deskPageSize);
                }
            } else {
                return data.slice((this.NextIndex-1) * this.deskPageSize, this.NextIndex * this.deskPageSize);
            }
        },
        //开台层弹出
        DeskClick(PosDesks){
            if(this.BusinessAuditsType==2){
                this.$message.warning('店面配置出错,暂时无法使用该功能')
                return;
            }
            this.Session()
            if(PosDesks.Desk_Status==0){
                this.isShow = true;
                this.DeskAutoID=PosDesks.Desk_AutoID;
                this.DeskCode=PosDesks.Desk_Code;
                this.DeskName=PosDesks.Desk_Name;
                this.Reserve_Operator=PosDesks.ReserveDeskInfo?.Reserve_Operator;
                this.choicePosDesks = PosDesks;
            }else if(PosDesks.Desk_Status==2){
                /**预约开台 */
                this.choicePosDesks = PosDesks;
                this.Reserve_Operator=PosDesks.ReserveDeskInfo?.Reserve_Operator;
                this.isMakeShow=true;
            }else if(PosDesks.Desk_Status==5){
                this.$confirm('是否将'+PosDesks.Desk_Name+'清台', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'})
                .then(() => {
                    const loading = this.$loading({
                        text: '清台中',
                        spinner: "el-icon-loading",
                        background: "rgba(0, 0, 0, 0.7)",
                    });
                    let userInfo=this.$auth.getUserInfo(); //获取用户id
                    let param={
                        Body_ID:newGuid(),
                        User_ID:userInfo?.User_ID,
                        Desk_AutoID:PosDesks.Desk_AutoID,
                        Operator_Name:userInfo?.Login_Name,
                        Timestamps:PosDesks.Timestamps.toString()
                    }
                    httpAES.post("Bestech.CloudPos.CleanDesk", param).then((d)=>{
                        loading.close()
                        if(d.ResponseHeader.ResultCode!=0){
                            this.$message.error(d.ResponseHeader.ResultDesc)
                        }else{
                            this.$message.success({ message:"清台完成"});
                            PosDesks.Desk_Status=0
                        }
                    }).catch((e)=>{
                        this.$alert(e.message, "提示", {confirmButtonText: "确定"});
                    });
                }).catch(() => {});
            }else{
                this.$router.push({
                    path: 'diningDetail',name:'diningDetail',
                    params:{
                        PosDesks:JSON.stringify(PosDesks),
                        Desk_AutoID:PosDesks.Desk_AutoID,
                        Eat_AutoID:PosDesks.OpenDeskInfo.Eat_AutoID,
                        Desk_Locked:PosDesks.Is_Desk_Locked,
                        urlType:0
                    }
                });
            }
        },
        /**
         * 本地调整开台数据
         * @isMenuOrder 是否开启菜单点菜
         */
        SetOpenDesk(Desk_AutoID,Desk_Status,OpenDeskInfo,OrderConsumes,isMenuOrder){
            if(this.isMakeShow==true){
                this.isMakeShow=false
            }
            let posDesk = this.getDesk(Desk_AutoID);
            if(posDesk){
                posDesk.Desk_Status= Desk_Status;  //状态调整成开台
                posDesk.OpenDeskInfo =OpenDeskInfo;

                if(isMenuOrder){//菜单点菜
                    this.$router.push({
                        name:'ipdUserOrderMode',
                        params:{
                            Desk_AutoID:posDesk.Desk_AutoID,
                            Eat_AutoID:posDesk.OpenDeskInfo.Eat_AutoID,
                            OrderConsumes:JSON.stringify(OrderConsumes)
                        }
                    });
                }else{
                    this.$router.push({
                        path: 'diningDetail',name:'diningDetail',
                        params:{
                            PosDesks:JSON.stringify(posDesk),
                            Desk_AutoID:posDesk.Desk_AutoID,
                            Eat_AutoID:posDesk.OpenDeskInfo.Eat_AutoID,
                            Desk_Locked:posDesk.Is_Desk_Locked,
                            urlType:0,
                            OrderConsumes:JSON.stringify(OrderConsumes)
                        }
                    });
                }
            }
        },
        //换台数据更新调整
        SetChangeDesk(setAutoID,setStatus,TempList,emptyAutoID,empStatus){
            let desk=this.getDesk(setAutoID);//当前要换 的桌台
            let emptyDesk=this.getDesk(emptyAutoID);//换过去的桌台
            if(desk && emptyDesk){
                desk.Desk_Status= setStatus;  
                desk.Desk_Speed=0;
                desk.OpenDeskInfo =null;
                emptyDesk.Desk_Status = empStatus;  
                emptyDesk.OpenDeskInfo = TempList;
                if(empStatus==2){
                emptyDesk = Object.assign(emptyDesk,{ReserveDeskInfo:desk.ReserveDeskInfo})
                }
            }
        },
        //并台数据更新调整
        setMergeDesk(posDeskList){
            let deskmergeList =''; //合并后的桌台
            posDeskList.map((item,i)=>{
                if(i==0){
                    deskmergeList = item.OpenDeskInfo;
                }else{
                    deskmergeList.Eat_CheckMoney = parseFloat((Number(deskmergeList.Eat_CheckMoney) + Number(item.OpenDeskInfo.Eat_CheckMoney)).toFixed(4)); //累加点餐总金额
                    deskmergeList.Eat_FoodCounts = parseFloat((Number(deskmergeList.Eat_FoodCounts) + Number(item.OpenDeskInfo.Eat_FoodCounts)).toFixed(4)); //累加点菜总数量
                    deskmergeList.Crossed_FoodCounts = parseFloat((Number(deskmergeList.Crossed_FoodCounts) + Number(item.OpenDeskInfo.Crossed_FoodCounts)).toFixed(4)); //累加已划菜数量
                    deskmergeList.Person_Num = parseFloat((Number(deskmergeList.Person_Num) + Number(item.OpenDeskInfo.Person_Num)).toFixed(4)); //累加就餐人数
                    item.Desk_Status = 0;
                    item.Desk_Speed=0;
                    item.OpenDeskInfo = null;
                }
            });
        },
        BottomClick(type){
            if(this.BusinessAuditsType==2){
                this.$message.warning('店面配置出错,暂时无法使用该功能')
                return;
            }
            if(type.Permission==false){
                this.checkBtnlist = JSON.parse(JSON.stringify(type))
                this.$confirm('该账户权限不足,不支持'+type.ProjectItem_Name+'功能', '提示', {
                    confirmButtonText: '去验证',
                    cancelButtonText: '我知道了',
                    type: 'warning'})
                .then(() => {
                    this.checkShow = true
                }).catch(()=>{});
                return;
            }
            //页面下方换台,并台,联台结账等按钮判断
            if(type.ProjectItem_Key=='ChangeDesk'){
                //换台
                this.ischangeShow = true;
            }
            else if(type.ProjectItem_Key=='MergeDesk'){
                //并台
                this.isMergeShow= true;
            }else if(type.ProjectItem_Key=='UnionDesk'){
                this.unionisShow = true;
            }else if(type.ProjectItem_Key=='LockSceen'){//锁屏
                this.$emit("lockScreen")
            }else if(type.ProjectItem_Key=='OtherFunc'){//其他
                this.otherFuncList = type.SysProjectSubItems;
                this.subShow = true
            }else if(type.ProjectItem_Key=='DeskLock'){//锁台
                this.isLockDesk=true;
            }else if(type.ProjectItem_Key=='DeskUnLock'){//解锁
                this.isUnLockDesk=true;
            }else if(type.ProjectItem_Key=='SplitVisualDesk'){//拆虚拟台
                this.isdismantleDesk=true;
            }else if(type.ProjectItem_Key=='CancelVisualDesk'){//撤销拆台
                this.isCancelDismantleDesk=true;
            }else if(type.ProjectItem_Key=='CancelOpenDesk'){//撤销开台
                this.isCancelDesk=true;
            }else if(type.ProjectItem_Key=='CloudPosEatserve'){ //上菜顺序
                if(_data.Running_ServingOrder_YN==true){
                    this.cleanDeskEatserve()
                    this.$router.push({
                        path: 'servingOrder',name:'servingOrder'
                    });
                }else{
                    this.$message.warning('后台没有启用上菜顺序功能.暂时无法使用.')
                }
            }else if(type.ProjectItem_Key=='KitPrint'){//补打厨房单
                this.kitOrderPrintShow=true;
            }
        },
        /**点击其他按钮 */
        subItemReturn(item){
            this.BottomClick({
                Is_Show:item.Is_Show, 
                Permission:item.Permission,
                ProjectItem_Key:item.ProjectSubItem_Key, 
                ProjectItem_Name:item.ProjectSubItem_Name,
                ProjectItem_Type:item.ProjectSubItem_Type,
            });
            this.subShow = false
        },
        //关闭开台弹出框
        CloseClick(){
            this.isShow = false;
            //默认焦点
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },500);
        },
        //关闭换台弹出框
        CloseChangeingClick(){
            this.ischangeShow =false;
            //默认焦点
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },500);
        },
        //关闭并台弹出框
        CloseMergingClick(){
            this.isMergeShow =false;
            //默认焦点
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },500);
        },
        //关闭联台
        CloseunionClick(d){
            this.unionisShow = false
            //默认焦点
            setTimeout(() => {
                this.$refs.searchBox.focus();
            },500);
            //this.Muindex=0
        },
        //数据缓存
        Session(){
            sessionStorage.setItem('filternav',this.filternav)
            sessionStorage.setItem('DeskType',this.DeskType)
        },
        /**数字转换 */
        Conversion(num){
            return NumberConversion(num)
        },
        showKeyBoard(){
            if(this.$refs.keyBoard.isOpen){
                this.$refs.keyBoard.close();
            }else{
                this.$refs.keyBoard.show(this.$refs.searchBox);
            }
        },
        searchInputBlur(){
            this.$refs.keyBoard.close();
        },
        /**非预定开台 */
        UnscheduledClick(PosDesks){
            this.isMakeShow=false
            this.DeskAutoID=PosDesks.Desk_AutoID;
            this.DeskCode=PosDesks.Desk_Code;
            this.DeskName=PosDesks.Desk_Name;
            this.Reserve_Operator=PosDesks.ReserveDeskInfo?.Reserve_Operator
            this.isShow = true;
        },
        deleteCloseClick(DeskAutoID){
            this.isMakeShow=false
            this.PosRooms?._allDesk?.find((d)=>{
                if(d.Desk_AutoID==DeskAutoID){
                    d.Desk_Status = 0;
                }
                return true;
            })
        },
        /**清理上桌顺序缓存的桌台数据 */
        cleanDeskEatserve(){
            this.$global.servingTheFood=Object;
            this.$global.servingPosRooms = this.RoomDeskList.PosRooms
            let _RoomsList=this.$cacheData.servingRoomsList.getRoomsList();
            if(_RoomsList){
                this.RoomDeskList.PosRooms.map(pos=>{
                    if(pos.PosDesks){
                        pos.PosDesks.map(desk=>{
                            _RoomsList.map((ser,index)=>{
                                if(desk.Desk_AutoID==ser.Desk_AutoID){
                                    ser.Desk_Status = desk.Desk_Status
                                }
                            })
                        })
                    }
                })
                this.$cacheData.servingRoomsList.set(_RoomsList)
            }
        },
        /**权限控制 */
        checkbtnReturn(date){
            this.checkShow = false;
            this.checkBtnlist.Permission = true;
            sessionStorage.setItem("tempUserID",date.User_ID)
            sessionStorage.setItem("tempName",date.Login_Name)
            this.BottomClick(this.checkBtnlist)
        },
        /** 人均消费区间 */
        CreateRange(PosDesks){
            let html;
            let imgurl;
            let presonmoney=0;
            if(PosDesks.OpenDeskInfo.Eat_SaleMoney>0&&PosDesks.OpenDeskInfo.Person_Num>0){
                presonmoney=parseFloat((PosDesks.OpenDeskInfo.Eat_SaleMoney / PosDesks.OpenDeskInfo.Person_Num).toFixed(2));
            }
            if(presonmoney<=0){return;}
            if(this.ConsumePersonAvgRange==null){return;}
            this.ConsumePersonAvgRange.some((info)=>{
                if(presonmoney>info.MinAvgMoney&&presonmoney<=info.MaxAvgMoney){
                    if(info.Kind==1){
                        html='<span>'+info.KindDesc.trim()+'</span>';
                    }else if(info.Kind==2){
                        let islocal=info.KindDesc?.indexOf("DefaultImg");
                        if(islocal!=-1)
                        {
                            let num=info.KindDesc?.substring(info.KindDesc?.lastIndexOf("_")+1)
                            if(num=='1')
                            {
                                imgurl="/images/v1.png";
                            }else if(num=='2'){
                                imgurl="/images/v2.png";
                            }else if(num=='3'){
                                imgurl="/images/v3.png";
                            }else if(num=='4'){
                                imgurl="/images/v4.png";
                            }else if(num=='5'){
                                imgurl="/images/v5.png";
                            }
                        }else{
                            imgurl=info.KindDesc;
                        }
                        html='<img src="'+imgurl+'" />';
                    }
                    return true;
                }
                return false;
            })
            return html;
        }
    },
    deactivated(){//被缓存时
        //是否被缓存
        this.isDeactivated=true;
    },
    unmounted(){//被移除时
        //是否被卸载
        this.isUnmounted=true;
        this.$shortcutKey.setFun();
    },
};
</script>

<style lang="scss">
@import "./diningTable.scss";
</style>
